import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedActivitesKeyState, selectedActivityKeyState } from "state/learningStructureState";
import { theoryState } from "state/theoryState";
import { ReferenceKey, TreeNode } from "types/cds";

interface useChapterActivitiesResponse {
  setChapterActivities: (activity: TreeNode, isSection?: boolean) => void;
}

/**
 * Custom hook for setting selected Activity/Activities to LearningStructureState on event handler.
 * @returns {object} an object containing a callback function setChapterActivities(activity: TreeNode, isSection?: boolean)
 */
const useChapterActivities = (): useChapterActivitiesResponse => {
  const setSelectedActivity = useSetRecoilState(selectedActivityKeyState);
  const setSelectedActivities = useSetRecoilState(selectedActivitesKeyState);
  const theory = useRecoilValue(theoryState);

  const setChapterActivities = useCallback(
    (activity: TreeNode, isSection?: boolean) => {
      setSelectedActivity(activity.activityKey);
      const chapterActivityKeys = theory
        .filter((t) => t.parentId === (isSection ? activity.parentId : activity.id))
        .map((s) => s.activityKey)
        .filter((key): key is ReferenceKey => key !== undefined);

      setSelectedActivities(chapterActivityKeys);
    },
    [setSelectedActivities, setSelectedActivity, theory]
  );

  return { setChapterActivities };
};

export default useChapterActivities;
