import { useEffect, useState } from "react";
import { Grid, Stack, Button, Box, useTheme } from "@mui/material";
import { DropdownMenu, DropdownMenuItems } from "utils/helpers/dropdown/DropdownMenu";
import { Icon, iconCaretDown, iconCircleExclamation } from "theme/icons";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { testId } from "tests/testIdStrings";
import ButtonBack from "utils/helpers/ButtonBack";
import DropDownChipItems from "utils/helpers/dropdown/ChipList";
import { IMenuItem } from "utils/helpers/dropdown/MenuItems";
import { useRecoilValue } from "recoil";
import { educationContentTypeState, learningStructureState } from "state/learningStructureState";
import { educationStateReferenceKey } from "state/educationState";
import { AssessmentType, useAssessment } from "hooks/useAssessment";
import { TestSettings, useTestSettings } from "hooks/useTestSettings";
import { useAppIntl } from "services/useAppIntl";
import { ItemAssessment, ItemAssessmentGroup, ItemQuestion } from "@strmediaochitab/optima-component-library";
import { Test, TestLabel } from "./test";
import { XapiKey } from "services/lrsService";
import { InfoMessage } from "utils/helpers/InfoMessage";
import { NotFound } from "layout/notFound";
import { userStateAccount } from "state/userState";

export const TestCustom = () => {
  const intl = useAppIntl();
  const account = useRecoilValue(userStateAccount);
  const theme = useTheme();
  const { defaultTest, numOfQuestions, questionTypes, duration } = useTestSettings();
  const [test, setTest] = useState<ItemAssessment | undefined>();
  const [subjectsWarning, setWarning] = useState<boolean>(false);
  const [testSettings, setTestSettings] = useState<TestSettings>(defaultTest);
  const { subjectCategories, assessment } = useAssessment({
    type: testSettings.questionType,
    key: useRecoilValue(educationStateReferenceKey)!,
  });

  const learningStructure = useRecoilValue(learningStructureState);
  const learningStructureRoot = learningStructure?.filter((node: any) => node.parentId === 0)[0];

  const xapiKey: XapiKey = {
    userId: account!.actorId,
    contentId: learningStructureRoot?.activityKey?.contentId!,
    versionId: learningStructureRoot?.activityKey?.versionId!,
    stateId: useRecoilValue(educationContentTypeState).get(testSettings.questionType)!,
  };

  useEffect(() => {
    if (!assessment) return;
    checkAvailableQuestions(testSettings.subjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment, testSettings.subjects, testSettings.questions]);

  const handleSelectQuestions = (id?: string) => {
    if (!id) return;

    setTestSettings({ ...testSettings, questions: parseInt(id) });
  };

  const handleSelectDuration = (id?: string) => {
    if (!id) return;

    setTestSettings({ ...testSettings, duration: parseInt(id) });
  };

  const handleSelectQuestionType = (id?: string) => {
    if (!id) return;

    const questionType = id as AssessmentType;
    setTestSettings({ ...testSettings, questionType });
  };

  const handleSelectSubject = (item: IMenuItem) => {
    if (!item.id) return;

    let subjects = new Set(testSettings.subjects);
    const exists = testSettings.subjects.has(item.id);

    if (exists) subjects.delete(item.id);
    else subjects.add(item.id);

    setTestSettings({ ...testSettings, subjects });
  };

  const handleSelectAllSubjects = (items: IMenuItem[]) => {
    let subjects = new Set<string>();
    items.forEach((item) => subjects.add(item.id!));

    setTestSettings({ ...testSettings, subjects });
  };

  const checkAvailableQuestions = (subjects: Set<string>) => {
    const selectedGroups = assessment!.items.filter((group: ItemAssessmentGroup) => subjects.has(group.id!));
    let questions: ItemQuestion[] = [];
    selectedGroups.forEach((group) => (questions = questions.concat(group.items)));

    console.log("valda", questions.length);

    setWarning(questions.length < testSettings.questions);
  };

  const handleStartTest = () => {
    if (!assessment) throw new Error("No assessment");

    const test = {
      ...assessment,
      configuration: {
        ...assessment.configuration,
        duration: testSettings.duration === 0 ? undefined : testSettings.duration,
        quantity: testSettings.questions,
      },
      items: assessment.items.filter((group: ItemAssessmentGroup) => testSettings.subjects.has(group.id!)),
    };

    console.log("startar test med följande params", testSettings, test);
    setTest(test);
  };

  if (!account) return null;
  if (assessment === null) return <NotFound />;

  // Start the test
  if (test)
    return (
      <Test
        label={testSettings.questionType === "roadsigns" ? TestLabel.RoadsignCustom : TestLabel.StudyCustom}
        assessment={test}
        xapiKey={xapiKey}
        userId={account.actorId}
      />
    );

  return (
    <>
      <FormattedTypography id="common.title.test.custom" variant="h1" mb={4} />
      <Stack spacing={4}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormattedTypography variant="subtitle1" id="test.create.custom.label.number-of-questions" mb={2} />
            <DropdownMenu
              id="ddNumOfQuestions"
              label={testSettings.questions}
              variant="outlined"
              endIcon={<Icon name={iconCaretDown} />}
              fullWidth
              onClick={(e) => handleSelectQuestions(e.id)}
            >
              <DropdownMenuItems
                items={numOfQuestions()}
                dataTestIdString={testId.listItem.test.custom.numOfQuestions}
              />
            </DropdownMenu>
          </Grid>
          <Grid item xs={6}>
            <FormattedTypography variant="subtitle1" id="test.create.custom.label.time" mb={2} />
            <DropdownMenu
              id="ddTime"
              label={testSettings.duration}
              variant="outlined"
              endIcon={<Icon name={iconCaretDown} />}
              fullWidth
              onClick={(e) => handleSelectDuration(e.id)}
            >
              <DropdownMenuItems items={duration()} dataTestIdString={testId.listItem.test.custom.duration} />
            </DropdownMenu>
          </Grid>
        </Grid>

        <Stack spacing={2}>
          <FormattedTypography variant="subtitle1" id="test.create.custom.label.question-type" />
          <DropdownMenu
            id="ddTypeOfQuestion"
            label={intl.formatMessage({ id: "common.title.study" })}
            variant="outlined"
            endIcon={<Icon name={iconCaretDown} />}
            onClick={(e) => handleSelectQuestionType(e.id)}
          >
            <DropdownMenuItems items={questionTypes()} dataTestIdString={testId.listItem.test.custom.questionTypes} />
          </DropdownMenu>
        </Stack>

        <Stack spacing={2}>
          <FormattedTypography variant="subtitle1" id="common.title.subject" />
          <DropdownMenu
            id="ddSubject"
            label={""}
            variant="outlined"
            noWrap
            endIcon={<Icon name={iconCaretDown} />}
            onClick={handleSelectSubject}
          >
            <DropDownChipItems items={subjectCategories} selectMultiple onSelectAll={handleSelectAllSubjects}>
              {subjectsWarning && (
                <Box p={2}>
                  <InfoMessage
                    message={
                      !testSettings.subjects.size
                        ? "test.create.custom.subjects.none-selected"
                        : "test.create.custom.subjects.alert"
                    }
                    messageValue={{ value: testSettings.questions }}
                    type="warning"
                  />
                </Box>
              )}
            </DropDownChipItems>
          </DropdownMenu>
          {subjectsWarning && (
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon name={iconCircleExclamation} htmlColor={theme.palette.warning.main} />
              <FormattedTypography
                id={
                  !testSettings.subjects.size
                    ? "test.create.custom.subjects.none-selected"
                    : "test.create.custom.subjects.info"
                }
                values={{ value: testSettings.questions }}
                variant="captionImage"
              />
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack spacing={8} mt={4}>
        <Button variant="contained" fullWidth onClick={handleStartTest} disabled={!testSettings.subjects.size}>
          <FormattedTypography id="test.create.custom.start" variant="inherit" />
        </Button>

        <ButtonBack dataTestIdString={testId.listItem.test.custom.back} />
      </Stack>
    </>
  );
};
