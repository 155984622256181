import { Box } from "@mui/material";

function PdfViewer({ pdf }: { pdf: string }) {
  return (
    <Box>
      <iframe title="Pdf" src={pdf} style={{ width: "100%", height: "80vh" }} />
    </Box>
  );
}

export default PdfViewer;
