import { AtomEffect, DefaultValue } from "recoil";
import { RequestError } from "types/request";

export const storageEffect: <T>(type: "local" | "session", key: string) => AtomEffect<T> =
  (type: string, key: string) =>
  ({ setSelf, onSet }) => {
    const storage = type === "local" ? localStorage : sessionStorage;

    const savedValue = storage.getItem(key);

    // eslint-disable-next-line eqeqeq
    if (savedValue != null && savedValue != "undefined") {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const copyObject = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Check if fetch call has errors
 * @param response
 * @returns
 */
export const instanceOfRequestError = (object: any): object is RequestError => !!object?.errors;
