import { Control, Controller } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";

type FormSwitchProps = {
  checked: boolean;
  noLabel?: boolean; // TODO: Merge with label prop
  label?: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
};
const FormSwitch = (props: FormSwitchProps) => {
  function handleChange(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (props.onChange) props.onChange(checked);
  }

  return (
    <FormControlLabel
      control={<Switch onChange={handleChange} color={"success"} checked={props.checked} />}
      label={props.noLabel ? "" : props.label ? props.label : "Klar"}
      labelPlacement={props.labelPlacement ?? "start"}
      slotProps={{ typography: { fontSize: "12px" } }}
      disabled={props.disabled}
      sx={{ maxHeight: "1.5rem", margin: "0" }}
    />
  );
};

export default FormSwitch;

export const FormSwitchController = ({ name, control }: { control: Control<any, any>; name: string }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => <FormSwitch checked={value} onChange={onChange} />}
    />
  );
};
