// flex styles
export const styleFlex = { display: "flex" };
export const styleFlexInline = { display: "inline-flex" };

export const styleFlexRow = { flexDirection: "row" };
export const styleFlexColumn = { flexDirection: "column" };

export const styleFlexJustifyContentStart = { justifyContent: "flex-start" };
export const styleFlexJustifyContentEnd = { justifyContent: "flex-end" };
export const styleFlexJustifyContentCenter = { justifyContent: "center" };
export const styleFlexJustifyContentBetween = { justifyContent: "space-between" };
export const styleFlexJustifyContentAround = { justifyContent: "space-around" };
export const styleFlexJustifyContentEvenly = { justifyContent: "space-evenly" };

export const styleFlexAlignItemsStart = { alignItems: "flex-start" };
export const styleFlexAlignItemsEnd = { alignItems: "flex-end" };
export const styleFlexAlignItemsCenter = { alignItems: "center" };
export const styleRoundedBottom = { borderBottomLeftRadius: "25px", borderBottomRightRadius: "25px" };
export const midGrey = "rgba(0, 0, 0, 0.6)";

export const cardContent = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
};
export const cardContentList = {
  boxShadow: "none",
  padding: "1rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
export const cardContentAlignLeft = {
  flexGrow: "3",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
};
export const cardContentAlignRight = {
  flexGrow: "3",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
};
export const alignCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const cardWrapper = {
  marginBottom: "1rem",
  border: "solid 0.5px #E9F1F2",
  width: "100%",
  height: "4rem",
  padding: "1rem",
};

export const buttonContainerBottom = { position: "fixed", bottom: 24, left: 12, right: 12 };
export const buttonContainerBottomWrapper = {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  p: 2,
  backgroundColor: "background.paper",
  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
};

export const styleQuestionNumber = {
  width: "1.5rem",
  height: "1.5rem",
  backgroundColor: "#F7F7F7",
  color: "text.primary",
  fontSize: "0.875rem",
  fontWeight: 500,
};

/** style for overriding cursor on question result */
export const styleQuestionAnswered = {
  ".optima-question label": {
    "&.success, &.error": {
      cursor: "default",
    },
  },
};
