import { Box, ListItemText, List, ListItem, Typography, IconButton, Link } from "@mui/material";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Icon, SizeProp, iconArrowLeft } from "theme/icons";
import { styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentBetween } from "theme/styles";
import { testId } from "tests/testIdStrings";
import useMediaQueries from "hooks/useMediaQueries";

interface IHeaderNavigationProps {
  iconSize: SizeProp;
  topTitle: string;
  title: string;
  children?: ReactNode;
  onNavigate?: () => void;
}
export const HeaderNavigation: React.FC<IHeaderNavigationProps> = (props) => {
  const { isTablet } = useMediaQueries();
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (props.onNavigate) return props.onNavigate();

    navigate(-1);
  };

  return (
    <List sx={{ width: isTablet ? "100%" : "30%" }} disablePadding>
      <ListItem sx={[styleFlexJustifyContentBetween, { pl: 0, pr: 1 }]}>
        <Box sx={[styleFlex, styleFlexAlignItemsCenter, { height: "2rem", width: "100%" }]}>
          <IconButton
            component={Link}
            onClick={handleNavigate}
            data-testid={testId.menu.header.backButton}
            sx={{ lineHeight: 1 }}
          >
            <Icon name={iconArrowLeft} size={props.iconSize} color="inherit" />
          </IconButton>
          <ListItemText
            disableTypography
            primary={<Typography variant="caption">{props.topTitle}</Typography>}
            secondary={
              <Typography
                variant="h4"
                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                data-testid={testId.menu.header.pageTitle}
              >
                {props.title}
              </Typography>
            }
            sx={{ margin: "0 1rem", lineHeight: "1rem" }}
          />
        </Box>
        {/* Högerställda icons */}
        {props.children}
      </ListItem>
    </List>
  );
};
