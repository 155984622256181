import { useParams } from "react-router-dom";
import { Title } from "utils/helpers/TitleDivider";
import StudentForm from "./Form";
import { Stack } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { API_URL_LES } from "utils/constants";
import { LearningEventActor } from "types/learningEvent";

const StudentEdit = () => {
  const { studentId, groupId } = useParams();

  const { json: actor } = useFetch<LearningEventActor>(`${API_URL_LES}${groupId}/actors/${studentId}`);

  if (!groupId) return null;
  if (!actor) return null;

  return (
    <>
      <Title title={`${actor.extensions?.firstName} ${actor.extensions?.lastName}`} />

      <Stack spacing={4}>{actor && <StudentForm student={actor} learningEventId={groupId} type="edit" />}</Stack>
    </>
  );
};

export default StudentEdit;
