import { useAppIntl } from "services/useAppIntl";
import { iconCommentsQuestionActive } from "theme/icons";
import { IMenuItem } from "utils/helpers/dropdown/MenuItems";
import { AssessmentType } from "./useAssessment";

interface IMenuItemQuestionType extends IMenuItem {
  id: AssessmentType;
}
export type TestSettings = {
  questions: number;
  duration?: number;
  questionType: AssessmentType;
  subjects: Set<string>;
  ratio?: number;
};

export const useTestSettings = () => {
  const intl = useAppIntl();

  const defaultTest: TestSettings = {
    questions: 10,
    duration: 10,
    questionType: "study",
    subjects: new Set(),
  };

  const finalTest: TestSettings = {
    questions: 20,
    duration: 50,
    questionType: "final",
    subjects: new Set(),
    ratio: 20,
  };

  const quickTest: TestSettings = {
    questions: 10,
    questionType: "study",
    subjects: new Set(),
  };

  const quickTest2: TestSettings = {
    questions: 70,
    questionType: "study",
    subjects: new Set(),
    duration: 50,
  };

  const roadsignsTest: TestSettings = {
    questions: 20,
    questionType: "roadsigns",
    subjects: new Set(),
  };

  const numOfQuestions = () => {
    const alternatives: IMenuItem[] = [];
    alternatives.push({ id: "10", title: "10 st" });
    alternatives.push({ id: "20", title: "20 st" });
    alternatives.push({ id: "40", title: "40 st" });
    alternatives.push({ id: "70", title: "70 st" });
    return alternatives;
  };

  const duration = () => {
    const alternatives: IMenuItem[] = [];
    alternatives.push({ id: "0", title: intl.formatMessage({ id: "test.create.custom.dropdown.duration" }) });
    alternatives.push({ id: "10", title: "10 min" });
    alternatives.push({ id: "20", title: "20 min" });
    alternatives.push({ id: "30", title: "30 min" });
    alternatives.push({ id: "40", title: "40 min" });
    alternatives.push({ id: "50", title: "50 min" });
    alternatives.push({ id: "60", title: "60 min" });
    alternatives.push({ id: "70", title: "70 min" });
    alternatives.push({ id: "80", title: "80 min" });
    return alternatives;
  };

  const questionTypes = () => {
    const alternatives: IMenuItemQuestionType[] = [];
    alternatives.push({
      id: "study",
      icon: iconCommentsQuestionActive,
      title: intl.formatMessage({ id: "common.title.study" }),
    });
    // alternatives.push({
    //   id: "roadsigns",
    //   icon: iconTriangleSolid,
    //   title: intl.formatMessage({ id: "common.roadsigns" }),
    // });
    return alternatives;
  };

  return { defaultTest, finalTest, roadsignsTest, quickTest, quickTest2, numOfQuestions, duration, questionTypes };
};
