import { Control } from "react-hook-form";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputPhoneNumberProps {
  control: Control<any, any> | undefined;
  disabled?: boolean;
  namePrefix?: string;
}
export const FormInputPhoneNumber = ({ control, disabled, namePrefix }: IFormInputPhoneNumberProps) => {
  const intl = useAppIntl();

  return (
    <FormInput
      name={namePrefix ? namePrefix + ".phoneNumber" : "phoneNumber"}
      label={intl.formatMessage({ id: "profile.label.phone" })}
      helperText={intl.formatMessage({ id: "profile.label.phone.helper-text" })}
      dataTestIdString={`${testId.input.profile}-phone`}
      control={control}
      disabled={disabled}
    />
  );
};
