import React, { useEffect } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { iconChevronDown, iconChevronRightSolid, iconChevronUp } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { TreeNode } from "types/cds";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { theoryChapterState, theoryAreaState, theoryStateChildrenByParentId } from "state/theoryState";
import { useNavigate, useLocation } from "react-router-dom";
import { TheorySections } from "./sections";
import { testId } from "tests/testIdStrings";
import useChapterActivities from "hooks/useChapterActivities";
import { activityProgressState } from "state/progresState";

interface TheoryChaptersProps {}

export const TheoryChapters: React.FC<TheoryChaptersProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const area = useRecoilValue(theoryAreaState);
  const chapters = useRecoilValue(theoryStateChildrenByParentId(area ? area.id : -1));
  const setChapterState = useSetRecoilState(theoryChapterState);
  const { setChapterActivities } = useChapterActivities();
  const progress = useRecoilValue(activityProgressState);

  // Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSelectedItem = (chapter: TreeNode) => {
    setChapterState(chapter);
    // För learning state
    setChapterActivities(chapter);
    navigate(`${chapter.id}/read`);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Stack spacing={2} maxWidth={theme.custom.desktopWidth640} marginLeft="auto" marginRight="auto">
        {chapters &&
          chapters.map((chapter: TreeNode, index: number) => (
            <NavigationItem
              size="large"
              key={chapter.id}
              text={
                <Typography variant="subtitle2" noWrap maxWidth="85%">
                  {chapter.title}
                </Typography>
              }
              icon={[iconChevronDown, iconChevronUp]}
              navigationIcon={iconChevronRightSolid}
              onClick={() => handleSelectedItem(chapter)}
              progressValue={parseInt(progress?.find((p) => p.title === chapter.activityKey?.versionId)?.value || "0")}
              dataTestIdString={`${testId.listItem.theory.chapters}-${index + 1}`}
            >
              <TheorySections chapterId={chapter.id} />
            </NavigationItem>
          ))}
      </Stack>
    </Box>
  );
};
