import * as Yup from "yup";
import { isSwedishSocialSecurityNumber, isSwedishPhoneNumber } from "./validationService";

/**
 * A Yup string schema that validates a password.
 *
 * This validation checks if the password meets the following criteria:
 * - At least 8 characters long
 * - Contains at least one numeric digit
 * - Contains at least one lowercase letter
 * - Contains at least one uppercase letter
 *
 * If the password is empty or not provided, the validation will pass.
 *
 * @param password - The password string to validate.
 * @returns `true` if the password is valid or not provided, otherwise `false`.
 */
const validatePassword: Yup.StringSchema = Yup.string().test("isValidPassword", (password?: string) => {
  if (!password || !password.length) return true;

  // Non alphanumeric (special) (?=.*[^a-zA-Z0-9])
  return password.length >= 8 && /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/.test(password);
});

const baseUserSchema = {
  firstName: Yup.string(),
  lastName: Yup.string().trim().max(255),
  identityNumber: Yup.string()
    .required()
    .test("isValidIdentityNumber", (identityNumber?: string) => {
      if (!identityNumber || !identityNumber.length) return false;

      return isSwedishSocialSecurityNumber(identityNumber);
    }),
  email: Yup.string().email(),
  preferredCommunication: Yup.object().shape(
    {
      phone: Yup.boolean().when("email", {
        is: (email: boolean) => !email,
        then: Yup.bool().oneOf([true], "At least one needs to be checked"),
      }),
      email: Yup.boolean().when("phone", {
        is: (phone: boolean) => !phone,
        then: Yup.bool().oneOf([true], "At least one needs to be checked"),
      }),
    },
    [
      ["email", "phone"],
      ["phone", "email"],
    ]
  ),
  phoneNumber: Yup.string()
    .when("preferredCommunication.phone", {
      is: true,
      then: Yup.string().required(),
    })
    .test("isValidNumber", "Must be valid phonenumber", (phone?: string) => {
      if (!phone) return true;

      const isValid = isSwedishPhoneNumber(phone);
      return isValid;
    }),
};

const profileSchema = {
  ...baseUserSchema,
  avatarId: Yup.string(),
};

const newAccountSchema = {
  ...baseUserSchema,
  hasAcceptedAgreements: Yup.boolean().test("hasAcceptedAgreements", (hasAccepted?: boolean) => {
    if (hasAccepted === undefined) return false;
    return hasAccepted;
  }),
};

const actorSchemaCreate = {
  role: Yup.string().required(),
  extensions: Yup.object().shape({
    identityNumber: Yup.string()
      .required()
      .test("isValidIdentityNumber", (identityNumber?: string) => {
        if (!identityNumber || !identityNumber.length) return false;

        return isSwedishSocialSecurityNumber(identityNumber);
      }),
    firstName: Yup.string().required(),
    lastName: Yup.string().trim().max(255).required(),
    userName: Yup.string().required(),
    password: validatePassword,
    email: Yup.string().email(),
    phoneNumber: Yup.string().test("isValidNumber", "Must be valid phonenumber", (phone?: string) => {
      if (!phone) return true;

      const isValid = isSwedishPhoneNumber(phone);
      return isValid;
    }),
  }),
};

const actorSchemaUpdate = {
  role: Yup.string().required(),
  extensions: Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().trim().max(255).required(),
    email: Yup.string().email(),
    phoneNumber: Yup.string().test("isValidNumber", "Must be valid phonenumber", (phone?: string) => {
      if (!phone) return true;

      const isValid = isSwedishPhoneNumber(phone);
      return isValid;
    }),
  }),
};

const actorSchemaUpdatePassword = {
  password: validatePassword,
};

export const validationSchemaUserProfile: Yup.ObjectSchema<any> = Yup.object().shape(profileSchema);
export const validationSchemaUserNewUser: Yup.ObjectSchema<any> = Yup.object().shape(newAccountSchema);
export const validationSchemaActorCreate: Yup.ObjectSchema<any> = Yup.object().shape(actorSchemaCreate);
export const validationSchemaActorUpdate: Yup.ObjectSchema<any> = Yup.object().shape(actorSchemaUpdate);
export const validationSchemaActorUpdatePassword: Yup.ObjectSchema<any> = Yup.object().shape(actorSchemaUpdatePassword);
