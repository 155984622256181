import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import FormSwitch from "utils/helpers/FormSwitch";
import { LoadingStatus } from "types/request";
import { getLrsState, saveLrsState, XapiKey } from "services/lrsService";
import { ItemAssessment, LRSProviderState } from "@strmediaochitab/optima-component-library";
import { educationStateReferenceKey } from "state/educationState";
import { educationContentTypeState } from "state/learningStructureState";
import { useTestSettings } from "hooks/useTestSettings";
import { useAssessment } from "hooks/useAssessment";
import { v4 as uuidv4 } from "uuid";
import { ReferenceKey } from "types/cds";

/**
 * UnlockTestSwitch component is responsible for rendering a switch that allows
 * unlocking or locking the final test for a list of users. It uses Recoil for state
 * management and interacts with an LRS (Learning Record Store) to fetch and save
 * the state of the final test lock for each user.
 *
 * @param {Object} props - The props object.
 * @param {string[]} props.userIds - An array of user IDs for whom the final test lock state is managed.
 * @param {string} [props.label] - An optional label for the switch.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @throws {Error} If no education state reference key or assessment is found.
 *
 * @example
 * <UnlockTestSwitch userIds={['user1', 'user2']} label="Unlock Final Test" />
 */
const UnlockTestSwitch = ({ userIds, label }: { userIds: string[]; label?: string }) => {
  const educationKey = useRecoilValue(educationStateReferenceKey);
  if (!educationKey) throw new Error("No education state reference key found");

  const { finalTest } = useTestSettings();
  const stateId = useRecoilValue(educationContentTypeState).get(finalTest.questionType)!;
  const { assessment } = useAssessment({
    type: finalTest.questionType,
    key: educationKey,
  });
  const [status, setStatus] = useState<LoadingStatus>("none");
  const [finalTestUnlocked, setFinalTestUnlocked] = useState<boolean>(false);

  useEffect(() => {
    if (!assessment) return;

    async function getAllStates(
      userIds: string[],
      educationKey: ReferenceKey,
      stateId: string,
      assessment: ItemAssessment
    ) {
      let unlocked: boolean[] = [];

      await Promise.all(
        userIds.map(async (userId) => {
          const xapiKey: XapiKey = {
            userId: userId,
            contentId: educationKey.contentId,
            versionId: educationKey.versionId!,
            stateId: stateId,
          };

          return await getLrsState(xapiKey);
        })
      )
        .then((responses) => {
          responses.forEach((lrsState) => {
            let assessmentState = lrsState.get(assessment.referenceKey.versionId);
            unlocked.push(assessmentState?.finalTestLocked === false);
          });
        })
        .finally(() => {
          setFinalTestUnlocked(unlocked.every((u) => u));
        });
    }

    getAllStates(userIds, educationKey, stateId, assessment);
  }, [userIds, assessment, educationKey, stateId]);

  async function handleUnlock(checked: boolean) {
    if (!educationKey || !assessment) throw new Error("No education state reference key OR assessment found");

    setStatus("pending");

    await Promise.all(
      userIds.map(async (userId) => {
        const xapiKey: XapiKey = {
          userId: userId,
          contentId: educationKey.contentId,
          versionId: educationKey.versionId!,
          stateId: stateId,
        };

        const newState: LRSProviderState = {
          id: uuidv4(),
          user: { id: userId },
          referenceKey: assessment.referenceKey,
          finalTestLocked: !checked,
        };

        // console.log("switching test lock to", !checked, "for user", userId, newState);
        await saveLrsState(xapiKey, newState);
      })
    )
      .then((responses) => {
        setFinalTestUnlocked(checked);
      })
      .finally(() => {
        setStatus("none");
      });
  }

  return (
    <FormSwitch
      checked={finalTestUnlocked}
      label={label}
      noLabel={label === undefined}
      onChange={(checked) => handleUnlock(checked)}
      disabled={status === "pending"}
    />
  );
};

export default UnlockTestSwitch;
