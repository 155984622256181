import { useState } from "react";
import { useParams } from "react-router-dom";
import { MemberRole } from "types/user-account";
import { Title } from "utils/helpers/TitleDivider";
import StudentForm from "./Form";
import { Button, Stack, Typography } from "@mui/material";
import { Icon, iconPlus } from "theme/icons";
// import { useAppIntl } from "services/useAppIntl";
import StudentSearch from "./StudentSearch";
import { LearningEventActor } from "types/learningEvent";

const initialStudent: LearningEventActor = {
  id: "",
  role: MemberRole.Student,
  extensions: {
    identityNumber: "",
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
};

const StudentAdd = () => {
  // const intl = useAppIntl();
  const { groupId } = useParams();

  const [createNew, setCreateNew] = useState(false);
  const [actor, setActor] = useState<LearningEventActor | null | undefined>(undefined);
  const [identityNumber, setIdentityNumber] = useState<string | null>(null);

  if (!groupId) return null;

  console.log("StudentAdd actor", actor);

  return (
    <>
      <Title title={"Lägg till förare"} />

      <Stack spacing={4}>
        <StudentSearch setActor={setActor} setIdentityNumber={setIdentityNumber} />

        {/* Initial state */}
        {actor === undefined && (
          <Typography variant="body1" textAlign={"center"}>
            Fyll i fullständigt personnummer på föraren du vill lägga till.
          </Typography>
        )}

        {/* User not found and we have not clicked on this button yet */}
        {actor === null && !createNew && (
          <Stack spacing={4} alignItems="center">
            <Typography variant="body1" textAlign="center">
              Det finns ingen förare med detta personnummer. Vill du skapa en ny?
            </Typography>

            <Button
              variant="outlined"
              size="small"
              color="info"
              startIcon={<Icon name={iconPlus} fontSize="24px !important" />}
              onClick={() => setCreateNew(true)}
              sx={{ maxWidth: "200px" }}
            >
              <Typography variant="button">Skapa ny</Typography>
            </Button>
          </Stack>
        )}

        {!actor && createNew && (
          <StudentForm
            student={{ ...initialStudent, extensions: { ...initialStudent.extensions, identityNumber } }}
            learningEventId={groupId}
            type="create"
          />
        )}
        {actor && <StudentForm student={actor} learningEventId={groupId} type="add" />}
      </Stack>
    </>
  );
};

export default StudentAdd;
