import { Icon, iconCircleXmark } from "theme/icons";
import { alpha, createTheme, Direction, PaletteOptions, SimplePaletteColorOptions } from "@mui/material";
import { ShapeOptions } from "@mui/system";
import { common } from "@mui/material/colors";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import shadows from "@mui/material/styles/shadows";
import { fontFamily, optimaColors, fontSize } from "./settings";

const typography: TypographyOptions = {
  fontFamily: fontFamily,
  // fontSize: (14 / 16) * 18, // Defaults to 18px
  h1: {
    fontSize: fontSize.h1,
    lineHeight: fontSize.h1,
    fontWeight: 600,
  },
  h2: {
    fontSize: fontSize.h2,
    lineHeight: fontSize.h1,
    fontWeight: 600,
    letterSpacing: "0.5px",
  },
  h3: {
    fontSize: fontSize.h3,
    lineHeight: fontSize.h1,
    fontWeight: 500,
  },
  h4: {
    fontSize: fontSize.h4,
    lineHeight: fontSize.h1,
    fontWeight: 700,
    letterSpacing: "0.1px",
  },
  body1: {
    fontSize: fontSize.body1,
  },
  body2: {
    fontSize: fontSize.body2,
    lineHeight: 1.225,
  },
  bodyStrong: {
    fontSize: fontSize.bodyStrong,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  subtitle1: {
    fontSize: fontSize.subtitle1,
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: "0.5px",
  },
  subtitle2: {
    fontSize: fontSize.subtitle2,
    fontWeight: 500,
    lineHeight: "17px",
    letterSpacing: "0.5px",
  },
  caption: {
    fontSize: fontSize.caption,
    fontWeight: 400,
    lineHeight: 1.225,
    letterSpacing: "0.004em",
    // display: "inline-flex",
  },
  captionImage: {
    fontSize: fontSize.imageText,
    lineHeight: 1.0625,
    letterSpacing: "0.4px",
  },
  breadcrumbs: {
    fontSize: fontSize.breadcrumb,
    fontWeight: 500,
    // display: "inline-flex",
  },
  note: {
    fontSize: fontSize.note,
    fontStyle: "italic",
    lineHeight: 1.0625,
    letterSpacing: "0.25px",
  },

  button: {
    fontSize: fontSize.button,
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: 1.5,
    textTransform: "uppercase",
  },
};

const palette: PaletteOptions = {
  mode: "light",
  background: {
    default: "#FFFFFF",
    desktop: "#FFFFFF",
  },
  primary: {
    main: optimaColors.primary,
    light: optimaColors.primaryLight,
    dark: optimaColors.primaryDark,
    contrastText: common.white,
  },
  secondary: {
    main: optimaColors.secondary,
    light: optimaColors.secondaryLight,
    dark: optimaColors.secondaryDark,
    contrastText: common.white,
  },
  error: {
    main: optimaColors.error,
    light: optimaColors.errorLight,
    dark: optimaColors.errorDark,
    contrastText: common.white,
  },
  success: {
    main: optimaColors.success,
    light: optimaColors.successLight,
    dark: optimaColors.successDark,
    contrastText: common.white,
  },
  info: {
    main: optimaColors.info,
    light: optimaColors.infoLight,
    dark: optimaColors.infoDark,
    contrastText: common.black,
  },
  warning: {
    main: optimaColors.warning,
    light: optimaColors.warningLight,
    dark: optimaColors.warningDark,
  },
  text: {
    primary: "#393E40",
    secondary: "rgba(0,0,0,0.6)",
  },
};

const shape: ShapeOptions = {
  borderRadius: 8,
};

//--- Theme settings overrides for LearningProvider ---

const typographyLearning: TypographyOptions = {
  fontFamily: fontFamily,
};

//--- Theme settings for LearningProvider ---

const customProps = {
  borderColor: alpha(common.black, 0.12),
  desktopWidth420: "420px",
  desktopWidth640: "640px",
  typographyLearning: typographyLearning,
};

export const theme = (direction: Direction) =>
  createTheme({
    direction: direction,
    header: {
      height: 13,
    },
    custom: customProps,
    shape: shape,
    palette: palette,
    typography: typography,
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: "64px",
            paddingLeft: "8px",
            paddingRight: "8px",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
          fontSize: fontSize.body2,
        },
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // Denna funkar konstigt nog inte under typography/button så måste ligga här
            borderRadius: shape.borderRadius,
            padding: "0.75rem 1.5rem",
          },
          sizeSmall: {
            padding: "0.5rem 1rem",
          },
          contained: {
            boxShadow: "none",
            ":hover, :active": {
              boxShadow: "none",
            },
            "&.Mui-disabled": {
              color: "#FFF",
              backgroundColor: (palette.primary! as SimplePaletteColorOptions).light,
            },
          },
          outlined: {
            // backgroundColor: common.white,
            borderColor: customProps.borderColor,
          },
        },
      },
      MuiChip: {
        defaultProps: {
          deleteIcon: <Icon name={iconCircleXmark} />,
          // variant: "outlined",
        },
        styleOverrides: {
          root: {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: customProps.borderColor,
            fontSize: "12px",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            ".MuiChip-label": {
              padding: 0,
            },
            "&.MuiChip-colorSuccess": {
              backgroundColor: "#DFF9ED",
              color: palette.text!.primary,
            },
            "&.MuiChip-colorWarning": {
              backgroundColor: "#FDFACC",
              color: palette.text!.primary,
            },
            ".MuiSvgIcon-root": {
              fontSize: "20px",
              ":first-child": {
                marginRight: "0.25rem",
                marginLeft: "-0.2rem",
              },
              ":last-child": {
                marginLeft: "0.25rem",
                marginRight: "-0.2rem",
              },
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            // ".MuiSwitch-switchBase.Mui-checked": {
            //   color: optimaColors.primary06,
            //   "+.MuiSwitch-track": {
            //     backgroundColor: optimaColors.primary02,
            //   },
            // },
            ".MuiSwitch-track": {
              opacity: 0.14,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: fontSize.input,
            backgroundColor: common.white,
            "&:not(.MuiInputBase-multiline)": {
              height: "3rem",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: fontSize.input,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: alpha(common.black, 0.6),
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: fontSize.input,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            ".MuiPaper-root": {
              boxShadow: shadows[4],
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: fontSize.subtitle2,
            paddingTop: 0,
            paddingBottom: 0,
            color: common.white,
            lineHeight: 1.5,
            ".MuiAlert-icon": { color: common.white, opacity: 1, alignSelf: "center" },
            "&.MuiAlert-standardSuccess": { backgroundColor: (palette.success! as SimplePaletteColorOptions).main },
            "&.MuiAlert-standardError": { backgroundColor: (palette.error! as SimplePaletteColorOptions).main },
            "&.MuiAlert-standardWarning": {
              backgroundColor: (palette.warning! as SimplePaletteColorOptions).main,
              color: common.black,
              // border: `1px solid ${optimaColors.neutralWarning}`,
              // ".MuiSvgIcon-root": { color: optimaColors.neutralWarning },
            },
            "&.MuiAlert-standardInfo": { backgroundColor: (palette.info! as SimplePaletteColorOptions).main },
            ".MuiAlert-message": { width: "100%" },
          },
        },
      },
      MuiBottomNavigation: {
        defaultProps: {
          sx: {
            height: "70px",
            // backgroundColor: (palette.primary! as SimplePaletteColorOptions).main,
          },
        },
      },
      MuiBottomNavigationAction: {
        defaultProps: {
          sx: {
            // color: alpha("#FFFFFF", 0.74),
            minWidth: "auto",
            padding: "0 5px",
            ".MuiBottomNavigationAction-label": {
              fontSize: "12px",
              "&.Mui-selected": {
                fontSize: "12px",
              },
            },
            "&:hover": {
              backgroundColor: alpha((palette.primary! as SimplePaletteColorOptions).main, 0.12),
            },
            "&.Mui-selected": {
              // color: common.white,
              fontWeight: 600,
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: shape.borderRadius,
            backgroundColor: common.white,
            boxShadow: shadows[0],
            border: `1px solid ${customProps.borderColor}`,
            // boxShadow:
            //   "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);",
            // ".MuiCardActionArea-root:hover": {
            //   backgroundColor: optimaColors.primary01,
            // },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            ".MuiMenu-paper": {
              boxShadow: shadows[4],
              borderRadius: shape.borderRadius,
              ".MuiMenu-list": {
                padding: 0,
                ".MuiMenuItem-root:hover": {
                  backgroundColor: alpha((palette.primary! as SimplePaletteColorOptions).main, 0.12),
                },
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: fontSize.body2,
            padding: "1rem",
            borderBottom: `1px solid ${customProps.borderColor}`,
          },
        },
      },
      MuiListItem: {
        defaultProps: {
          // color: "inherit",
        },
        styleOverrides: {
          root: {
            overflow: "hidden",
            borderRadius: shape.borderRadius,
            ".MuiListItemButton-root": {
              backgroundColor: common.white,
              "&:hover": {
                backgroundColor: alpha((palette.primary! as SimplePaletteColorOptions).main, 0.12),
              },
              ".MuiListItemIcon-root": {
                color: customProps.borderColor,
              },
            },
            ".MuiListItemButton-root.Mui-selected .MuiListItemIcon-root": {
              color: (palette.success! as SimplePaletteColorOptions).main,
            },
            h6: {
              lineHeight: "1.25",
            },
            ".MuiListItemSecondaryAction-disableGutters button": {
              marginRight: "10px",
            },
          },
        },
      },
      MuiDialog: {
        // MUI Bugfix: Prevent event bubbling from dialog
        defaultProps: {
          PaperProps: { onClick: (e: any) => e.stopPropagation() },
          onBackdropClick: (e) => e.stopPropagation(),
        },
        styleOverrides: {
          // Alert message in dialog
          root: {
            ".MuiDialog-container .MuiAlert-root.MuiPaper-root": {
              borderRadius: "4px",
              position: "relative",
              boxShadow: "none",
              marginBottom: "1.5rem",
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            ".MuiListItemButton-root:hover": {
              backgroundColor: (palette.primary! as SimplePaletteColorOptions).light,
            },
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          color: "transparent",
          elevation: 8,
        },
        styleOverrides: {
          root: {
            // Header
            "&.AppBar-top": {
              backgroundColor: common.white,
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.06);",
              ".MuiLink-root": {
                color: (palette.primary! as SimplePaletteColorOptions).main,
              },
            },
            // Footer
            "&.AppBar-bottom": {
              top: "auto",
              bottom: 0,
              left: 0,
              right: 0,
              width: "auto",
              overflow: "hidden",
            },
            // Topnav tabs
            ".MuiTabs-root": {
              marginTop: "-8px",
              ".MuiTab-root": {
                letterSpacing: "0.045em",
                fontWeight: "400",
                padding: "12px 24px",
                textAlign: "center",
                color: (palette.primary! as SimplePaletteColorOptions).main,
                "&.Mui-selected": {
                  fontWeight: 600,
                  // color: "red",
                },
                label: {
                  paddingLeft: "2px",
                  cursor: "pointer",
                },
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            // ".MuiTabs-indicator": { height: "1px" },
            ".MuiTab-root": {
              color: (palette.primary! as SimplePaletteColorOptions).light,
              minHeight: "auto",
              padding: "6px 24px",
              ".MuiSvgIcon-root": { marginRight: "0.5rem" },
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            margin: "1.5rem 0 2rem 0",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: shape.borderRadius,
            boxShadow: shadows[0],
            border: `1px solid ${customProps.borderColor}`,
            "&::before": {
              display: "none",
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            "&.MuiAvatar-rounded": {
              borderRadius: "16px",
            },
          },
        },
      },
    },
  });
