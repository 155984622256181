import { Stack, Card, CardContent, Grid } from "@mui/material";
import EducationItem from "./EducationItem";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { educationsSelector } from "state/educatorState";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

const Educations = () => {
  const { groupId } = useParams();
  const educations = useRecoilValue(educationsSelector(groupId));

  console.log("material educations", educations);

  return (
    <Card sx={{ border: "none" }}>
      <CardContent sx={{ px: 3 }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <FormattedTypography id="common.title.educator.educations" variant="subtitle1" />
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1.5}>
              {educations?.map((event) => (
                <EducationItem key={event.versionId} item={event} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Educations;
