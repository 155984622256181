import { Stack, useTheme } from "@mui/material";
import { iconChartColumnSolid, iconTestActive } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { testId } from "tests/testIdStrings";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useProgress } from "hooks/useProgress";
import ProgressCard from "layout/Components/ProgressCard";

export const Progress = () => {
  const theme = useTheme();
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const { knowledgeLevelUser } = useProgress();
  // const [showDialog, setShowDialog] = useState(false);

  if (knowledgeLevelUser === undefined) return null;

  return (
    <>
      <Stack spacing={4}>
        <FormattedTypography id="common.title.progress" variant="h1" />
        <ProgressCard />

        <Stack spacing={1.5} direction={"row"}>
          <NavigationItem
            size="auto"
            text={intl.formatMessage({ id: "progress.knowledgelevel.per.topic.title" })}
            textVariant="subtitle2"
            icon={iconChartColumnSolid}
            color={theme.palette.primary.dark06}
            iconSize="medium"
            dataTestIdString={testId.listItem.theory.continue}
            href={"/progress/" + routes.progress.routes?.progresspertopic.path}
          />
          <NavigationItem
            size="auto"
            text={intl.formatMessage({ id: "progress.knowledgelevel.history" })}
            textVariant="subtitle2"
            icon={iconTestActive}
            color={theme.palette.primary.dark06}
            iconSize="medium"
            dataTestIdString={testId.listItem.theory.continue}
            href={"/progress/" + routes.progress.routes?.results.path}
          />
        </Stack>
      </Stack>

      {/* Ska denna inte användas? */}
      {/* <DialogMessage
        title={intl.formatMessage({ id: "progress.knowledgelevel" })}
        open={showDialog}
        close={() => setShowDialog(false)}
        primaryButtonText={"common.close"}
        dataTestIdString={`${testId.listItem.profile.contactInfoBox}`}
        noCancelButton
      >
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage id={"progress.dialog.knowledgelevel"} />
        </Typography>
      </DialogMessage> */}
    </>
  );
};
