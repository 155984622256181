import { useInitNavigate } from "utils/helpers/msal/useInitNavigate";
import { Navigate, useNavigate } from "react-router-dom";
import { AppIntro } from "./appIntro";
import { Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Typography } from "@mui/material";
import { Icon, iconFMEducator, iconFMStudent } from "theme/icons";
import { useSetRecoilState } from "recoil";
import { userStateAccount } from "state/userState";
import { MemberRole } from "types/user-account";

export const Start = () => {
  const initialPage = useInitNavigate();

  if (!initialPage) return <AppIntro text="Startar appen..." />;

  return <Navigate to={initialPage} />;
};

export const SelectRole = () => {
  const setUserAccount = useSetRecoilState(userStateAccount);
  const navigate = useNavigate();

  const handleRoleSelect = async (role: MemberRole) => {
    setUserAccount((user) => (user ? { ...user, roles: [role] } : undefined));

    return navigate("/");
  };

  return (
    <Paper sx={{ width: 320, maxWidth: "100%", ml: "auto", mr: "auto" }}>
      <MenuList disablePadding>
        <MenuItem sx={{ "&:hover": { backgroundColor: "inherit" }, cursor: "default" }}>
          <Typography variant="subtitle2" textTransform="uppercase">
            Välj din roll
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleRoleSelect(MemberRole.Educator)}>
          <ListItemText>Instruktör</ListItemText>
          <ListItemIcon>
            <Icon name={iconFMEducator} color="info" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => handleRoleSelect(MemberRole.Student)}>
          <ListItemText>Förare</ListItemText>
          <ListItemIcon>
            <Icon name={iconFMStudent} color="info" />
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    </Paper>
  );
};
