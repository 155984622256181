import Typography from "@mui/material/Typography";
import { LearningObjectiveType } from "types/educationCard";
import ItemContainer from "./ItemContainer";
import { Box } from "@mui/material";
import { EducationCardType } from "hooks/useEducationCardSettings";

type LearningObjectiveProps = {
  type?: LearningObjectiveType;
  status?: string;
  tempProp?: EducationCardType; // Temp for demo. Refactor when done
};
const LearningObjectiveHeader = ({ type, status, tempProp }: LearningObjectiveProps) => {
  let title = "";

  switch (type) {
    case "practical":
      title = "Lektioner";
      break;
    case "theoretical":
      title = "Självstudier";
      break;
    default:
      title = "Datum för genomförande";
      break;
  }

  return (
    <ItemContainer>
      <Typography variant="caption" textTransform="uppercase">
        {title}
      </Typography>
      <Box mr={tempProp === "user" ? 6 : 10}>
        {type === "theoretical" && (
          <Typography variant="caption" textTransform="uppercase" mr={2}>
            {status ?? "Status"}
          </Typography>
        )}

        {tempProp !== "user" && (
          <Typography variant="caption" textTransform="uppercase">
            {type === "test" ? "Resultat" : "Klar"}
          </Typography>
        )}
      </Box>
    </ItemContainer>
  );
};

export default LearningObjectiveHeader;
