import { atom, selectorFamily, selector } from "recoil";
import { UserEducation } from "types/education";
import { get as httpGet } from "services/httpService";
import { API_URL_LES } from "utils/constants";
import { instanceOfRequestError, storageEffect } from "./stateHelper";
import { RequestError } from "types/request";
import { ReferenceKey } from "types/cds";
import { ConnectedEducator } from "types/educator";
//import * as appStateService from "services/appStateService";

export const educationState = atom<UserEducation | undefined>({
  key: "educationState",
  default: undefined,
  effects: [
    storageEffect("local", "educationState"),

    // TODO: Maybe add this when we have decided how to handle this
    // ({ onSet, setSelf, getLoadable }) => {
    //   const stateKey: appStateService.StateKey = {
    //     stateId: appStateService.educationStateId,
    //   };

    //   appStateService.getState<appStateService.EducationState>(stateKey).then(async (state) => {
    //     if (!state) return;

    //     const education = await getLoadable(userEducationState({ identifier: state[0].identifier })).toPromise();
    //     if (!education) return;

    //     if (education) setSelf(education);
    //   });

    //   onSet(async (education) => {
    //     if (!education) return;

    //     const state: appStateService.EducationState = {
    //       identifier: education.identifier,
    //     };
    //     console.debug("Remember educationId:", education, "and state is", state);

    //     appStateService.saveState(stateKey, state);
    //   });
    // },
  ],
});

export const educationStateReferenceKey = selector<ReferenceKey | undefined>({
  key: "educationStateReferenceKey",
  get: ({ get }) => {
    const education = get(educationState);

    if (!education) return undefined;
    if (!education.contentId) {
      console.warn("ContentId not found on current education");
      return undefined;
    }

    const referenceKey: ReferenceKey = { contentId: education.contentId, versionId: education.versionId };
    return referenceKey;
  },
});

export const userEducationState = selectorFamily<UserEducation | undefined, { identifier: string | undefined }>({
  key: "userEducationState",
  get:
    ({ identifier }) =>
    async ({ get }) => {
      const educations = get(userEducationsState);
      return educations?.find((x) => x.educationId === identifier);
    },
});

export const educationStateEducationAdded = atom<number>({
  key: "educationStateEducationAdded",
  default: 0,
});
export const educationStateFinalTestUnlocked = atom<number>({
  key: "educationStateFinalTestUnlocked",
  default: 0,
});

// User educations
export const userEducationsState = selector<UserEducation[] | undefined>({
  key: "userEducationsState",
  get: async ({ get }) => {
    // Add dependencies to trigger update of other state
    // get(educationStateEducationAdded);
    // get(educationStateFinalTestUnlocked);

    const availableEducations: UserEducation[] | RequestError = await httpGet(`${API_URL_LES}materials/me`);

    // TODO Handle if we get an error
    if (instanceOfRequestError(availableEducations)) {
      console.log("error when fetching educations");
      return undefined;
    }

    const educations = availableEducations ? availableEducations.map<UserEducation>((education) => education) : [];

    // TODO: Put CurrentEducationIdentifier directly here, instead of useEffect in App.tsx
    return educations;
  },
});

export const educationStateInfoShared = atom<number>({
  key: "educationStateInfoShared",
  default: 0,
});

export const connectedEducatorsSelector = selector<ConnectedEducator[] | undefined>({
  key: "connectedEducatorsSelector",
  get: async ({ get }) => {
    get(educationStateInfoShared); // Add dependency to trigger update

    const connectedEducatorData = null; // await httpGet(`${API_URL_BO_EDUCATOR}connected-educators`);
    if (!connectedEducatorData) return undefined;
    return connectedEducatorData;
  },
});
