import { Control } from "react-hook-form";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputFirstNameProps {
  control: Control<any, any> | undefined;
  disabled?: boolean;
  namePrefix?: string;
}

export const FormInputFirstName = ({ control, disabled, namePrefix }: IFormInputFirstNameProps) => {
  const intl = useAppIntl();

  return (
    <FormInput
      name={namePrefix ? namePrefix + ".firstName" : "firstName"}
      label={intl.formatMessage({ id: "profile.label.first-name" }) + " *"}
      dataTestIdString={`${testId.input.profile}-firstname`}
      control={control}
      disabled={disabled}
      required
    />
  );
};
