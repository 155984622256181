import { WelcomeMessage } from "utils/helpers/WelcomeMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import EducationList from "./EducationHandler";

export const Education = () => {
  return (
    <>
      {/* <Box display={"flex"} justifyContent={"space-between"} alignItems="start">
        <WelcomeMessage />
        <Box mt={-1.5}>
          <MoreDropdownMenu />
        </Box>
      </Box> */}

      <WelcomeMessage />
      <FormattedTypography id="common.title.my-educations" variant="h2" mb={5} />
      <EducationList />

      {/* <Box mt={2} flexGrow={1} display="flex" justifyContent={"flex-end"} flexDirection="column" pb={13}>
        <ValueCode />
      </Box> */}
    </>
  );
};
