import React from "react";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { Result } from "layout/test/final/testResult";

export const FinalTestResult: React.FC = () => {
  const navigate = useNavigate();
  const { id: testId } = useParams();

  if (!testId) return null;

  return (
    <>
      <Result testId={testId} />
      <Button variant="contained" fullWidth onClick={() => navigate(-1)}>
        <FormattedMessage id="common.close" />
      </Button>
    </>
  );
};
