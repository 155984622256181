import { Box, Typography, Stack, useTheme } from "@mui/material";
import { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import { Icon, iconFile } from "theme/icons";
import { styleFlex, styleFlexAlignItemsCenter } from "theme/styles";
import { AgreementViewer } from "utils/helpers/AgreementViewer";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { IntlMessageKeys, useAppIntl } from "services/useAppIntl";
import { AgreementTypes } from "types/agreements";
import useMediaQueries from "hooks/useMediaQueries";

interface IFormInputContactProps {
  checkboxComponent?: ReactElement;
}

export const FormInputSecrecy: React.FC<IFormInputContactProps> = ({ checkboxComponent }) => {
  const theme = useTheme();
  const intl = useAppIntl();
  const [showUserAgreement, setShowUserAgreement] = useState(false);
  const [dialogTitle, setDialogTitle] = useState<IntlMessageKeys>("profile.link.agreement");
  const { isMobile } = useMediaQueries();

  return (
    <>
      <Stack spacing={4} mb={6}>
        <Typography variant="subtitle1">
          <FormattedMessage id="common.secrecy" />
        </Typography>

        <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "space-between" }}>
          <Box sx={[styleFlex, styleFlexAlignItemsCenter, { color: theme.palette.info.main }]}>
            <Icon name={iconFile} />
            <Typography variant="button" sx={{ margin: "0 0 0 0.5rem", height: "1rem" }}>
              <Link
                style={{ color: theme.palette.info.main, textDecoration: "none", fontWeight: "700" }}
                to="#"
                state={{ agreementType: AgreementTypes.userAgreement }}
                data-testid={testId.listItem.profile.userTerms}
                onClick={() => {
                  setDialogTitle("profile.link.user-agreement");
                  setShowUserAgreement(true);
                }}
              >
                <FormattedMessage id="profile.link.user-agreement" />
              </Link>
            </Typography>
          </Box>
          <Box
            sx={[
              styleFlex,
              styleFlexAlignItemsCenter,
              { color: theme.palette.info.main, marginRight: "50px", marginTop: isMobile ? 2 : 0 },
            ]}
          >
            <Icon name={iconFile} />
            <Typography variant="button" sx={{ margin: "0 0 0 0.5rem", height: "1rem" }}>
              <Link
                style={{ color: theme.palette.info.main, textDecoration: "none", fontWeight: "700" }}
                to="#"
                state={{ agreementType: AgreementTypes.gdprAgreement }}
                data-testid={testId.listItem.profile.gdprTerms}
                onClick={() => {
                  setDialogTitle("profile.link.gdpr-agreement");
                  setShowUserAgreement(true);
                }}
              >
                <FormattedMessage id="profile.link.gdpr-agreement" />
              </Link>
            </Typography>
          </Box>
        </Box>

        <DialogMessage
          title={intl.formatMessage({ id: dialogTitle })}
          open={showUserAgreement}
          close={() => setShowUserAgreement(false)}
          primaryButtonText={"common.close"}
          dataTestIdString={testId.common.text.profile}
          noCancelButton
          sx={!isMobile ? { ".MuiDialog-container .MuiDialog-paper": { bottom: "unset" } } : null}
        >
          <AgreementViewer />
        </DialogMessage>
        <Box paddingLeft={0}>{checkboxComponent}</Box>
      </Stack>
    </>
  );
};
