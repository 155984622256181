import { TypographyProps, Typography } from "@mui/material";

// Logos
import logoFM from "static/logo-FM.png";
import logoFlex from "static/logo-Flex.png";

export const AppTitle = ({ variant }: { variant?: TypographyProps["variant"] }) => {
  let title = "Optima " + window.env.APP_THEME;

  // Varför i h.....e är detta omvänt?
  if (window.env.APP_THEME === "FM") title = window.env.APP_THEME + " Optima";

  return <Typography variant={variant ?? "h4"}>{title}</Typography>;
};

export const getAppLogo = () => {
  const logos = {
    FM: logoFM,
    Flex: logoFlex,
  };

  return logos[window.env.APP_THEME as keyof typeof logos];
};
