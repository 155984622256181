import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Hook for media queries
 */
const useMediaQueries = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return { isMobile, isTablet, isDesktop };
};

export default useMediaQueries;
