import { PropsWithChildren } from "react";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { testId } from "tests/testIdStrings";

type Props = PropsWithChildren & {
  title: string;
  open: boolean;
  close: () => void;
};

const ApproveDialog = (props: Props) => {
  const { children, open, close } = props;

  // const { control } = useForm<StudentsFormValues>({
  //   defaultValues: { students: students },
  // });
  // const { fields } = useFieldArray({
  //   control,
  //   name: "students",
  // });

  const handleClose = () => {
    close();
  };

  return (
    <DialogMessage
      title={props.title}
      open={open}
      close={handleClose}
      primaryButtonText="common.close"
      primaryButtonAction={handleClose}
      dataTestIdString={testId.common.close}
      noCancelButton
    >
      {children}
    </DialogMessage>
  );
};

export default ApproveDialog;
