import { useContinueWhereIWas } from "hooks/useContinueWhereIWas";
import { useTheme } from "@mui/material";
import { testId } from "tests/testIdStrings";
import { iconArrowRotateRight } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";

const ContinueTheory = () => {
  const intl = useAppIntl();
  const theme = useTheme();
  const { activity, path, notStarted } = useContinueWhereIWas();
  const url = window.location.href;
  const isOnTheoryPage = url.includes("theory");

  if (activity && path) console.log("Continue on:", activity, path, notStarted);

  return (
    <NavigationItem
      text={intl.formatMessage({ id: notStarted ? "theory.start" : "theory.resume" })}
      subText={notStarted ? intl.formatMessage({ id: "theory.start.subtext" }) : activity && activity.title}
      subTextVariant="caption"
      icon={iconArrowRotateRight}
      color={isOnTheoryPage ? theme.palette.info.main : undefined}
      href={path}
      dataTestIdString={testId.listItem.home.theoryContinue}
      disabled={!activity || !path}
    />
  );
};

export default ContinueTheory;
