import { useTopNavigation } from "layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Sets up a back navigation function to override the "normal" history back in Header.
 *
 * @param to - The path to navigate to when the back navigation function is called.
 *
 * This hook uses the `useTopNavigation` and `useNavigate` hooks to set a top-level
 * navigation function that navigates to the specified path. When the component using
 * this hook is unmounted, the top-level navigation function is cleared.
 *
 * @example
 * ```typescript
 * const MyComponent = () => {
 *   useBackNavigation('/home');
 *   return <div>My Component</div>;
 * };
 * ```
 */
const useBackNavigation = (to: string) => {
  const { setTopNavigate } = useTopNavigation();
  const navigate = useNavigate();

  useEffect(() => {
    setTopNavigate({ function: () => navigate(to) });

    return () => {
      setTopNavigate({ function: undefined });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useBackNavigation;
