import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { SwipeableList, Type } from "react-swipeable-list";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { BookmarkState } from "services/appStateService";
import { Icon, iconSearch } from "theme/icons";
import { useTopNavigation } from "layout";
import SearchField from "layout/Components/SearchField";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { DeleteBookmarkDialog } from "./deleteBookmarkDialog";
import { SwipeableBookmarksListItem } from "./swipeableBookmarksListItem";
import "react-swipeable-list/dist/styles.css";
import useMediaQueries from "hooks/useMediaQueries";

type Props = {
  list: BookmarkState[];
};
export const BookmarksList = ({ list }: Props) => {
  const intl = useIntl();
  const { isMobile } = useMediaQueries();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedBookmark, setSelectedBookmark] = useState<BookmarkState | null>(null);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { setTopNavigate } = useTopNavigation();

  useEffect(() => setTopNavigate({ function: () => navigate("/") }), []);

  const filteredList = list.filter(
    (b) =>
      b.text.toLowerCase().includes(search.toLowerCase()) ||
      b.title.toLowerCase().includes(search.toLowerCase()) ||
      b.date.toLowerCase().includes(search.toLowerCase())
  );

  const handleDeleteDialog = (bookmark: BookmarkState) => {
    setSelectedBookmark(bookmark);
    setShowDeleteDialog(true);
  };

  return (
    <>
      {selectedBookmark && (
        <DeleteBookmarkDialog
          open={showDeleteDialog}
          close={() => setShowDeleteDialog(false)}
          selectedBookmark={selectedBookmark}
        />
      )}
      <Stack spacing={4}>
        <FormattedTypography variant="h1" id="more.title.bookmarksandnotes" />
        <SearchField
          placeholder={intl.formatMessage({ id: "bookmarks.search.in.bookmarks" })}
          endIcon={<Icon name={iconSearch} color="primary" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Stack>

      <Box mt={4} sx={{ marginLeft: isMobile ? -2 : -3, marginRight: isMobile ? -2 : -3 }}>
        <SwipeableList style={{ borderTop: "1px solid", borderColor: "#0000001f" }} fullSwipe={false} type={Type.IOS}>
          {filteredList
            ?.map((item, index) => (
              <SwipeableBookmarksListItem key={index} item={item} handleDeleteDialog={() => handleDeleteDialog(item)} />
            ))
            .reverse()}
        </SwipeableList>
      </Box>
    </>
  );
};
