import Typography from "@mui/material/Typography";
import React from "react";
import FormattedMessage from "./FormattedMessage";
import { testId } from "tests/testIdStrings";
import { useRecoilValue } from "recoil";
import { userStateAccount } from "state/userState";

export const WelcomeMessage: React.FC = () => {
  const user = useRecoilValue(userStateAccount);

  const GetHour = () => {
    const date = new Date();
    const hour = date.getHours();
    switch (true) {
      case (hour >= 0 && hour < 5) || (hour >= 12 && hour < 18):
        return "greeting.day";
      case hour >= 5 && hour < 12:
        return "greeting.morning";
      case hour >= 18 && hour <= 23:
        return "greeting.evening";
    }
  };

  return (
    <Typography variant="h1" mb={6} data-testid={testId.common.text.welcomeMessage}>
      <FormattedMessage
        id={GetHour()}
        description="welcome message with name prop"
        defaultMessage="God kväll {value}"
        values={{
          value: user?.firstName ?? "- Namn saknas -",
        }}
      />
    </Typography>
  );
};
