import { atom, selector } from "recoil";
import { BookmarkState, StateKey, bookmarkStateId, getState } from "services/appStateService";

export const bookmarkStateBookmarkAdded = atom<number>({
  key: "bookmarkStateBookmarkAdded",
  default: 0,
});

export const bookmarksStateSelector = selector<BookmarkState[] | undefined>({
  key: "bookmarksStateSelector",
  get: async ({ get }) => {
    get(bookmarkStateBookmarkAdded); // D

    const stateKey: StateKey = {
      typeId: bookmarkStateId,
    };
    const bookmarks = await getState<BookmarkState>(stateKey);
    if (!bookmarks) return [];

    console.log("bookmarks from state", bookmarks);

    return bookmarks;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: "most-recent",
  },
});
