import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { iconTrashcan } from "theme/icons";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { StateKey, bookmarkStateId, deleteState } from "services/appStateService";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { educationState } from "state/educationState";
import { bookmarkStateBookmarkAdded } from "state/bookmarkState";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { educationContentTypeState } from "state/learningStructureState";
import { scrollToElement } from "utils/helpers/ScrollHandler";
import { useAppIntl } from "services/useAppIntl";

type Props = {
  selectedBookmark: any;
  open: boolean;
  close: () => void;
};

export const DeleteBookmarkDialog = ({ selectedBookmark, open, close }: Props) => {
  const navigate = useNavigate();
  const intl = useAppIntl();
  const education = useRecoilValue(educationState);
  const classificationId = useRecoilValue(educationContentTypeState).get(selectedBookmark.type);
  const added = useSetRecoilState(bookmarkStateBookmarkAdded);
  const refreshBookmarks = () => added((n: number) => n + 1);

  const handleDelete = async () => {
    const key: StateKey = {
      typeId: bookmarkStateId,
      auxId1: education?.educationId,
      auxId2: selectedBookmark.key.contentId,
      auxId3: classificationId,
    };
    await deleteState(key);
    refreshBookmarks();
    setTimeout(() => {
      close();

      const url = window.location.href;
      if (url.includes("more/bookmarks")) {
        navigate("/more/bookmarks");
      } else {
        // We need to scroll to the bookmarked activity (again) since the refreshBookmarks() causes a re-render
        const selector = `[id='${selectedBookmark.key.contentId}']`;
        scrollToElement({ selector, offsetTop: -100 });
      }
    }, 250);
  };

  return (
    <DialogMessage
      open={open}
      close={close}
      title={intl.formatMessage({ id: "bookmarks.remove.note.header" })}
      primaryButtonText="bookmarks.remove.note"
      primaryButtonIcon={iconTrashcan}
      primaryButtonColor="error"
      primaryButtonAction={() => handleDelete()}
    >
      <Stack spacing={3}>
        <FormattedTypography
          variant="body1"
          id="bookmarks.remove.text"
          values={{ bookmark: `'${selectedBookmark?.title}'` }}
        />
      </Stack>
    </DialogMessage>
  );
};
