import { PropsWithChildren, ReactNode, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Box, Link } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Icon, iconArrowRightRegular } from "theme/icons";
import FormSwitch from "utils/helpers/FormSwitch";
import ListHeader from "layout/Components/ListHeader";
import { ContentLoader } from "utils/helpers/LoadingIndicator";
import { educationCardPropsState } from "state/educationCardState";
import { studentsSelector } from "state/educatorState";
import { LearningEventStudent } from "types/learningEvent";
import Status from "../Status";
import { LoadingStatus } from "types/request";
import { StudentsProps } from ".";
import UnlockTestSwitch from "./UnlockTest";

type ListProps = {
  edit: boolean;
  item: StudentsProps["item"];
  onApprove: StudentsProps["onApprove"];
  onApproveAll: StudentsProps["onApproveAll"];
};

function List({ edit, item, onApprove, onApproveAll }: ListProps) {
  const [status, setStatus] = useState<LoadingStatus>("none");
  const state = useRecoilValue(educationCardPropsState);
  const { groupId } = useParams();
  const groupStudents = useRecoilValue(studentsSelector(groupId));

  const students = groupStudents.map((student) => {
    const itemStudent = item.studentStatus?.get(student.id);
    return {
      ...student,
      status: itemStudent?.status || "NotStarted",
      approved: itemStudent?.approved || false,
      // finalTestUnlocked: itemStudent?.finalTestUnlocked || false, Ska denna vara här?
    };
  }) as LearningEventStudent[];

  async function handleApprove(checked: boolean, student: LearningEventStudent) {
    if (!state) throw new Error("No education card state found");

    console.log("approving student", checked, student, item.title);
    setStatus("pending");

    // Approve all chapters
    if (item.theoryItems) {
      if (onApproveAll) await onApproveAll(checked, student.id);
    } else {
      // Approve single chapter
      if (onApprove) await onApprove(item, "theoretical", checked, student.id);
    }

    setStatus("none");
  }

  return (
    <Box mx={-2} my={-3}>
      <ContentLoader isLoading={status === "pending"} loadingText="Sparar..." />
      <TableContainer sx={{ pb: 1.5 }}>
        <Table size="small" aria-label="Table of students">
          <TableHead>
            <TableRow>
              <TableCell>
                <ListHeader>Förare</ListHeader>
              </TableCell>
              <TableCell width="100">
                <ListHeader>{"finalTestUnlocked" in item ? "Provresultat" : "Status"}</ListHeader>
              </TableCell>
              <TableCell width="100" sx={{ pl: 3 }}>
                <ListHeader>Klar</ListHeader>
              </TableCell>

              {/* Test only  */}
              {"finalTestUnlocked" in item && (
                <TableCell width="100" sx={{ pl: 3 }}>
                  <ListHeader>Lås upp</ListHeader>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((row) => (
              <ListRow
                key={row.id}
                student={row}
                edit={edit}
                unlock={item.hasOwnProperty("finalTestUnlocked") ? <UnlockTestSwitch userIds={[row.id]} /> : null}
              >
                <FormSwitch checked={row.approved} noLabel onChange={(checked) => handleApprove(checked, row)} />
              </ListRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default List;

const ListRow = ({
  student,
  edit,
  children: approveSwitch,
  unlock,
}: { student: LearningEventStudent; edit: ListProps["edit"]; unlock?: ReactNode } & PropsWithChildren) => {
  const name = `${student.extensions?.firstName} ${student.extensions?.lastName}`;

  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          <EducationCardLink studentId={student.id}>
            <span>{name}</span>
          </EducationCardLink>
        </TableCell>
        <TableCell>
          <Status status={student.status} />
        </TableCell>
        <TableCell>{edit ? approveSwitch : "Nej"}</TableCell>
        {unlock && <TableCell>{unlock}</TableCell>}
      </TableRow>
    </>
  );
};

// TODO: Ska den öppnas i en dialog?? Går nog inte i nuläget då vi bara kan ha ett utbildningskort öppet i taget
const EducationCardLink = ({ children, studentId }: { studentId: string } & PropsWithChildren) => {
  // const [open, setOpen] = useState(false);

  return (
    <>
      <Link
        display="flex"
        alignItems="center"
        component={RouterLink}
        to={`students/${studentId}`}
        sx={{ textDecoration: "none" }}
      >
        {children}
        <Icon name={iconArrowRightRegular} color="info" sx={{ ml: 1 }} />
      </Link>

      {/* {open && (
        <StudentEducationcardDialog
          title="Elevens utbildningskort"
          open={true}
          close={() => setOpen(false)}
          studentId={studentId}
        ></StudentEducationcardDialog>
      )} */}
    </>
  );
};
