import React from "react";
import { Button, Stack } from "@mui/material";
import { testId } from "tests/testIdStrings";
import FormattedMessage from "../FormattedMessage";

interface IQuestionNavigation {
  previousId?: any;
  nextId?: any;
  navigate: (id: any) => void;
}

export const QuestionNavigation: React.FC<IQuestionNavigation> = ({ previousId, nextId, navigate }) => {
  const navigateToQuestion = (id?: any) => {
    if (!id) throw new Error("No question id provided");

    navigate(id);
  };

  return (
    <Stack direction="row" spacing={2} display="flex" justifyContent="space-between">
      <Button
        variant="contained"
        disabled={!previousId}
        fullWidth
        sx={{ px: 2 }}
        onClick={() => navigateToQuestion(previousId)}
        data-testid={testId.listItem.question.previous}
      >
        <FormattedMessage id="question.previous" />
      </Button>

      {/* <QuestionOverviewButton /> */}

      <Button
        variant="contained"
        disabled={!nextId}
        fullWidth
        sx={{ px: 2 }}
        onClick={() => navigateToQuestion(nextId)}
        data-testid={testId.listItem.question.next}
      >
        <FormattedMessage id="question.next" />
      </Button>
    </Stack>
  );
};
