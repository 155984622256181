import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon, iconPlus } from "theme/icons";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

const StudentAdd = () => {
  const navigate = useNavigate();

  const handleAdd = () => {
    navigate("add");
  };

  return (
    <Button
      variant="outlined"
      size="small"
      color="info"
      startIcon={<Icon name={iconPlus} fontSize="24px !important" />}
      onClick={handleAdd}
    >
      <FormattedTypography id="common.button.add" variant="button" />
    </Button>
  );
};

export default StudentAdd;
