import { Stack, Card, CardContent, Link, useTheme } from "@mui/material";
import { useAppIntl } from "services/useAppIntl";
import { LinearProgressIndicator } from "utils/helpers/LinearProgressIndicator";
import { Icon, iconArrowRightRegular } from "theme/icons";
import { Link as RouterLink } from "react-router-dom";
import { EducationCard, EducationCardProgress } from "types/educationCard";
import { useEducationCardSettings } from "hooks/useEducationCardSettings";
import { useEffect, useState } from "react";
import { getEducationCard } from "services/educationCardService";
import { LoadingIndicator } from "utils/helpers/LoadingIndicator";

const ProgressCard = () => {
  const theme = useTheme();
  const settings = useEducationCardSettings({ type: "user" });
  const [progress, setProgress] = useState<EducationCardProgress | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      if (!settings) return;
      setIsLoading(true);

      const educationCard: EducationCard = await getEducationCard(
        settings.eventId,
        settings.referenceKey,
        settings.userIds,
        settings.identifier
      );
      setProgress(educationCard.progress);
      setIsLoading(false);
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <Card>
      <CardContent>
        <Link
          component={RouterLink}
          to="/progress/educationcard"
          variant="subtitle1"
          display="flex"
          alignItems="center"
          color={theme.palette.text.primary}
          mb={2}
        >
          Mitt utbildningskort
          <Icon name={iconArrowRightRegular} color="info" fontSize="24px !important" sx={{ ml: "0.5rem" }} />
        </Link>

        {isLoading && (
          <LoadingIndicator size={18}>
            <>Hämtar din progression...</>
          </LoadingIndicator>
        )}
        {progress && <Progress progress={progress} isUser />}
      </CardContent>
    </Card>
  );
};

export default ProgressCard;

type ProgressProps = {
  progress: EducationCardProgress;
  isUser?: boolean;
};
export const Progress = ({ progress, isUser }: ProgressProps) => {
  const intl = useAppIntl();

  return (
    <Stack spacing={3}>
      {/* <LinearProgressIndicator
        value={32}
        progressTitle={intl.formatMessage({ id: "progress.knowledgelevel.total" })}
        progressText
        height="10px"
        color="success"
      /> */}

      <LinearProgressIndicator
        value={progress.theoretical}
        progressTitle={intl.formatMessage({ id: "common.title.theory" })}
        progressText
        color="success"
      />

      {/* Not to be used for logged in user */}
      {/* {!isUser && (
        <LinearProgressIndicator
          value={progress.theoreticalByInstructor}
          progressTitle={intl.formatMessage({ id: "common.title.self-study" })}
          progressText
          color="success"
        />
      )}
      <LinearProgressIndicator
        value={progress.practical}
        progressTitle={intl.formatMessage({ id: "common.title.practical" })}
        progressText
        color="success"
      /> */}
    </Stack>
  );
};
