import { ReactNode, Suspense, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import {
  ItemQuestion,
  LearningObject,
  LRSProvider,
  LRSProviderState,
  LRSRecord,
  Question,
  ReferenceKey,
} from "@strmediaochitab/optima-component-library";
import { useAppContext } from "context/AppContext";
import { useParams, useNavigate } from "react-router-dom";
import { QuestionNavigation } from "utils/helpers/theory/QuestionNavigation";
import useRoutePath from "hooks/useRoutePath";
import { buttonContainerBottomWrapper } from "theme/styles";
import { ResultOverview } from "services/reportingService";
import { useRecoilValue } from "recoil";
import { studyHelpKeysState, studyHelpState, testResultState } from "state/progresState";
import { DialogMenu } from "utils/helpers/DialogMessage";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { LoadingIndicator } from "utils/helpers/LoadingIndicator";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { userStateAccount } from "state/userState";

const initialState: LRSRecord = new Map<string, LRSProviderState>();

// TEMP function until we have a proper mapping in state
function mapLrsState(state: ResultOverview, userId: string): LRSRecord {
  if (!state.referenceKey) throw new Error("referenceKey is undefined");

  // Result may be undefined if the question is not answered
  const responseArray = state.result?.response
    ? JSON.parse(state.result.response).map((value: any) => value.toString())
    : [];
  const response = JSON.stringify(responseArray);

  // TODO: Fix mapping of lrsState
  const lrsState: any = {
    ...state,
    id: state.referenceKey.versionId,
    user: { id: userId },
    result: {
      ...state.result,
      response,
      completion: true,
    },
  };

  return new Map<string, LRSProviderState>().set(state.referenceKey.versionId, lrsState);
}

const Dialog = () => {
  const navigate = useNavigate();
  const intl = useAppIntl();

  return (
    <DialogMenu
      title={intl.formatMessage({ id: "progress.studyhelp" })}
      open={true}
      divider
      close={() => navigate("../..", { relative: "path" })}
      dataTestIdString={testId.common.close}
    >
      <Box p={2} pb={11} minHeight={"84vh"}>
        <Page />
      </Box>
    </DialogMenu>
  );
};
export default Dialog;

const Page = () => {
  const { id, questionId } = useParams();
  const account = useRecoilValue(userStateAccount);

  if (!id || !questionId) throw new Error("id or questionId is undefined");
  if (!account) throw new Error("User not found");

  const [lrsState, setLrsState] = useState<LRSRecord>(initialState);
  const studyHelpKeys = useRecoilValue(studyHelpKeysState({ userId: account.actorId, testId: id }));
  const testResult = useRecoilValue(testResultState(id));
  const questionResultOverview = testResult?.results.find((result) => result.question!.id.contentId === questionId);

  if (!testResult) throw new Error("Test result not found");
  if (!questionResultOverview || !questionResultOverview.question) throw new Error("Question not found");
  if (!studyHelpKeys) throw new Error("Study help keys not found");

  const questionKeys = useMemo(
    () =>
      questionResultOverview
        .question!.learningObjectives.flatMap((lo: string) => studyHelpKeys.get(lo) ?? null)
        .filter((x) => x != null) as ReferenceKey[],
    [questionResultOverview.question, studyHelpKeys]
  );

  useEffect(() => {
    const state = mapLrsState(questionResultOverview, account.actorId);
    setLrsState(state);
  }, [questionResultOverview, account.actorId]);

  return (
    <Stack spacing={4}>
      <Suspense
        fallback={
          <Box sx={{ position: "absolute", top: "40%", left: 0, right: 0 }}>
            <LoadingIndicator>
              <FormattedMessage id="common.title.loading.question.get" />
            </LoadingIndicator>
          </Box>
        }
      >
        <QuestionProvider lrsState={lrsState} question={questionResultOverview.question} />
        <StudyHelpProvider questionKeys={questionKeys} />
      </Suspense>
      <NavigationButtons results={testResult.results} />
    </Stack>
  );
};

const QuestionProvider = ({
  lrsState,
  question,
  children,
}: {
  lrsState: LRSRecord;
  question: ItemQuestion;
  children?: ReactNode;
}) => {
  const account = useRecoilValue(userStateAccount);
  if (!account) throw new Error("Account not found");
  const { activeLanguage } = useAppContext();

  return (
    <LRSProvider
      user={account.actorId}
      language={activeLanguage}
      callbackSetQuestion={() => {}}
      state={{ questions: lrsState }}
    >
      <Question question={question} renderButton={false} />
      {children}
    </LRSProvider>
  );
};

const StudyHelpProvider = ({ questionKeys }: { questionKeys: ReferenceKey[] }) => {
  const account = useRecoilValue(userStateAccount);
  if (!account) throw new Error("Account not found");

  const { activeLanguage } = useAppContext();
  const lrsState = initialState;
  const activities = useRecoilValue(studyHelpState(questionKeys));

  if (!activities || !activities.length) return null;

  return (
    <LRSProvider
      user={account.actorId}
      language={activeLanguage}
      callbackSetQuestion={() => {}}
      state={{ questions: lrsState }}
    >
      {activities.map((learningObject: any) => (
        <Box key={learningObject.contentId} className="optima-learning-object" data-type="learning-object">
          <LearningObject data={learningObject} headingLevel={1} onAction={() => {}} />
        </Box>
      ))}
    </LRSProvider>
  );
};

const NavigationButtons = ({ results }: { results: ResultOverview[] }) => {
  const currentPath = useRoutePath();
  const navigate = useNavigate();
  const { id, questionId } = useParams();

  const navigateToQuestion = (qId: string) => {
    navigate(currentPath.replace(":id", id!).replace(":questionId", qId!));
  };

  const currentIndex = results.findIndex((result) => result.question!.id.contentId === questionId);
  const previousQuestion = currentIndex > 0 ? results[currentIndex - 1].question!.id.contentId : null;
  const nextQuestion = currentIndex < results.length - 1 ? results[currentIndex + 1].question!.id.contentId : null;

  return (
    <Box sx={buttonContainerBottomWrapper}>
      <QuestionNavigation nextId={nextQuestion} previousId={previousQuestion} navigate={navigateToQuestion} />
    </Box>
  );
};
