import { Box, Grid, Stack } from "@mui/material";
import { iconSolidGlobe, iconExternalLink, iconCommentExclamation } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

const illustration = process.env.PUBLIC_URL + "/img/illustrations/helpdesk-man.svg";

export const Support = () => {
  const intl = useAppIntl();

  return (
    <>
      <Stack spacing={3} mb={3}>
        <FormattedTypography variant="h1" id="more.title.support" />
        <FormattedTypography variant="body1" id="support.help.questions" />
        <FormattedTypography variant="body1" id="support.help.questions2" />
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={300}
          // sx={{ backgroundColor: "#EAF2FF" }}
        >
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2} marginBottom={2}>
            <img width={"auto"} height={"230px"} src={illustration} alt="Illustration" />
          </Box>
        </Box>
      </Stack>

      <Stack spacing={2}>
        <FormattedTypography variant="h2" id="support.contact.us" />
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <NavigationItem
                size="large"
                text={intl.formatMessage({ id: "more.title.support" })}
                subText={intl.formatMessage({ id: "support.qa" })}
                subTextVariant="caption"
                icon={iconSolidGlobe}
                dataTestIdString={testId.listItem.theory.continue}
                navigationIcon={iconExternalLink}
                // href={SUPPORT_LINK}
                hrefTarget="_blank"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NavigationItem
                size="large"
                text={intl.formatMessage({ id: "support.feedback" })}
                subText={intl.formatMessage({ id: "support.feedback.subtext" })}
                subTextVariant="caption"
                icon={iconCommentExclamation}
                dataTestIdString={testId.listItem.theory.continue}
                navigationIcon={iconExternalLink}
                // href={FEEDBACK_LINK}
                hrefTarget="_blank"
              />
            </Grid>
            {/* <NavigationItem
            size="large"
            text={intl.formatMessage({ id: "support.mail.us" })}
            icon={iconSolidEnvelope}
            dataTestIdString={testId.listItem.theory.continue}
            // TODO: email address should be in a config file
            onClick={() => (window.location.href = "mailto:support@korkort.nu")}
            navigationIcon={iconExternalLink}
            subText={SUPPORT_MAIL}
            subTextVariant="caption"
            width={navItemWidth}
            height={navItemHeight}
          /> */}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};
