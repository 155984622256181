// Api:s
import { appConfig } from "appConfig";

export const SUPPORT_LINK = "https://support.korkort.nu";
export const FEEDBACK_LINK = "https://feedback.korkort.nu";
export const SUPPORT_MAIL = "support@korkort.nu";

export const API_URL_CDS_CONSUMER = appConfig.cdsApiUrl + "/api/v1/Consumer/";
export const API_URL_CDS_DATA = appConfig.cdsApiUrl + "/api/v1/Data/";
export const API_URL_CDS = appConfig.cdsApiUrl + "/api/v1/";
export const API_URL_CDS_COMPOSITE = appConfig.cdsApiUrl + "/api/v1/Composite/";
export const API_URL_CDS_PROTOTYPE = appConfig.cdsApiUrl + "/api/v1/_Prototype/";
export const API_URL_LRS_XAPI = appConfig.lrsApiUrl + "/";
export const API_URL_CD_RESPONSE_PATTERN = appConfig.cdsApiUrl + "/api/v1/Response/";
export const API_URL_ASS_STATE = appConfig.assApiUrl + "/v1/State/" + appConfig.authClientId + "/";
export const API_URL_FFS = appConfig.ffsApiUrl + "/v1/FeatureFlags/" + appConfig.appId;
export const API_URL_LES = appConfig.lesApiUrl + "/learning-events/";
export const API_URL_CS = appConfig.csApiUrl + "/customers/";
export const API_URL_BO_SPEECH = appConfig.ffsApiUrl + "/v1/MicrosoftCognitiveServicesSpeech/";

// TODO: Handle these?
const boUrl = "vilken-url?";
export const API_URL_BO_AGREEMENT = boUrl + "/v1/Agreement/";
