import { Status } from "types/educationCard";
import Chip from "@mui/material/Chip";
import { Icon, iconBan, iconCircleCheck, iconCircleXmarkSolid, iconHourglass } from "theme/icons";
import { useTheme, alpha } from "@mui/material";

type EducationStatusProps = {
  status: Status;
  label?: boolean;
};

const EducationStatus = ({ status, label }: EducationStatusProps) => {
  const theme = useTheme();
  const styleIconAdjust = !label ? { ml: "-8px !important", mr: "-8px !important" } : undefined;
  const styleIconSize = "16px !important";

  if (status === "Ongoing")
    return (
      <Chip
        size="small"
        label={label && "Pågående"}
        icon={
          <Icon
            name={iconHourglass}
            fontSize={styleIconSize}
            htmlColor={theme.palette.warning.dark}
            sx={styleIconAdjust}
          />
        }
        sx={{
          backgroundColor: alpha(theme.palette.warning.main, 0.2),
          borderColor: alpha(theme.palette.warning.main, 0.2),
        }}
      />
    );

  if (status === "Success")
    return (
      <Chip
        size="small"
        label={label && "Godkänd"}
        icon={
          <Icon
            name={iconCircleCheck}
            fontSize={styleIconSize}
            htmlColor={theme.palette.success.dark}
            sx={styleIconAdjust}
          />
        }
        sx={{
          backgroundColor: alpha(theme.palette.success.main, 0.2),
          borderColor: alpha(theme.palette.success.main, 0.2),
        }}
      />
    );

  if (status === "Fail")
    return (
      <Chip
        size="small"
        label={label && "Icke godkänd"}
        icon={
          <Icon
            name={iconCircleXmarkSolid}
            fontSize={styleIconSize}
            htmlColor={theme.palette.error.dark}
            sx={styleIconAdjust}
          />
        }
        sx={{
          backgroundColor: alpha(theme.palette.error.main, 0.2),
          borderColor: alpha(theme.palette.error.main, 0.2),
        }}
      />
    );

  return (
    <Chip
      color="default"
      size="small"
      icon={
        <Icon name={iconBan} fontSize={styleIconSize} htmlColor={theme.palette.text.primary} sx={styleIconAdjust} />
      }
    />
  );
};

export default EducationStatus;
