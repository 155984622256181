import { useEffect, useMemo, useState } from "react";
import { useFetch } from "hooks/useFetch";
import SearchField from "layout/Components/SearchField";
import { Icon, iconSearch } from "theme/icons";
import { useAppIntl } from "services/useAppIntl";
import { isSwedishSocialSecurityNumber } from "services/validationService";
import { debounce } from "lodash";
import { API_URL_CS } from "utils/constants";
import { useRecoilValue } from "recoil";
import { userStateAccount } from "state/userState";
import { CustomerMember } from "types/customerMember";
import { LearningEventActor } from "types/learningEvent";
import { MemberRole } from "types/user-account";

function customerMemberToActor(customerMember: CustomerMember): LearningEventActor {
  return {
    id: customerMember.actorId,
    role: MemberRole.Student,
    extensions: {
      firstName: customerMember.firstName,
      lastName: customerMember.lastName,
      email: customerMember.email ?? "",
      phoneNumber: customerMember.phoneNumber ?? "",
      identityNumber: customerMember.identityNumber,
    },
  };
}

type SearchStudentProps = {
  setActor: (user: LearningEventActor | null | undefined) => void;
  setIdentityNumber: (identityNumber: string | null) => void;
};

const StudentSearch = ({ setActor, setIdentityNumber }: SearchStudentProps) => {
  const intl = useAppIntl();
  const [search, setSearch] = useState("");
  const [error, setError] = useState<string | null>(null);
  const userAccount = useRecoilValue(userStateAccount);
  const handleSearchDebounce = useMemo(() => debounce(searchUser, 500), []); // Memoize a delayed function
  const { json: customerMember, get } = useFetch<CustomerMember[]>();

  // Set user when fetched
  useEffect(() => {
    if (customerMember && customerMember.length > 0) return setActor(customerMemberToActor(customerMember[0]));
    if (customerMember && customerMember.length === 0) return setActor(null);
    setActor(undefined);
  }, [customerMember, setActor]);

  function searchUser(value: string) {
    if (!userAccount?.customerId) throw new Error("No customer id set on user account");
    if (value.length !== 11) {
      setIdentityNumber(null);
      setActor(undefined);
      return;
    }

    if (!isSwedishSocialSecurityNumber(value)) {
      setError("Felaktigt personnummer");
      setIdentityNumber(null);
      return;
    }

    setError(null);
    setIdentityNumber(value);

    get(`${API_URL_CS}${userAccount?.customerId}/members?identityNumber=${value}`);
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value;

    setSearch(searchString);
    handleSearchDebounce(searchString);
  };

  useEffect(() => {
    return handleSearchDebounce.cancel();
  }, [handleSearchDebounce]);

  return (
    <SearchField
      label={intl.formatMessage({ id: "profile.label.social-security-number" }, { values: { required: "*" } })}
      endIcon={<Icon name={iconSearch} color="primary" />}
      value={search}
      onChange={handleSearch}
      error={error !== null}
      helperText={error}
    />
  );
};

export default StudentSearch;
