import { useState, useEffect, useCallback } from "react";
import { loadingState } from "state/recoilAtoms";
import { useRecoilState } from "recoil";
import { get as httpGet, post as httpPostPatch, put as httpPut, remove as httpDelete } from "services/httpService";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { RequestError, RequestMethod } from "types/request";

export interface UseFetchResponse<T> {
  json: T | undefined;
  isLoading: boolean | null;
  isLoadingLocal: boolean | null;
  error: RequestError | null;
  get: (url: string, resetJson?: boolean) => void;
  post: (url: string, data: any, resetJson?: boolean) => void;
  put: (url: string, data: any, resetJson?: boolean) => void;
  patch: (url: string, data: any, resetJson?: boolean) => void;
  remove: (url: string, resetJson?: boolean) => void;
}
interface RequestState {
  url?: string;
  type?: RequestMethod;
  data?: any;
  refetch?: boolean;
}

/**
 * Custom fetch hook. Use it to fetch data on mount, or to get/post/put data on action.
 * @param fetchUrl request url. Use it if you want to fetch data on mount
 * @param localLoader if true, will use local loader to be used in buttons or similar, instead of global page loader
 * @returns {object} an object containing {json, isLoading, error, get(), post()}
 */
export const useFetch = <T>(fetchUrl?: string, localLoader?: boolean): UseFetchResponse<T> => {
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean | null>(null);
  const [error, setError] = useState<RequestError | null>(null);
  const [forceRequest, setForceRequest] = useState<boolean | null>(null);
  const [json, setJson] = useState<T | undefined>(undefined);
  const [request, setRequest] = useState<RequestState>({ url: fetchUrl, type: "GET" });
  const { inProgress } = useMsal();

  useEffect(() => {
    const makeRequest = async () => {
      if (!request.url) return;
      if (forceRequest === false) return;
      if (inProgress !== InteractionStatus.None) return;

      localLoader ? setIsLoadingLocal(true) : setIsLoading(true);

      let promise: Promise<any>;

      switch (request.type) {
        case "PUT":
          promise = httpPut(request.url, request.data);
          break;

        case "POST":
          promise = httpPostPatch(request.url, request.data);
          break;

        case "PATCH":
          promise = httpPostPatch(request.url, request.data, request.type);
          break;
        case "DELETE":
          promise = httpDelete(request.url);
          break;

        default:
          promise = httpGet(request.url);
          break;
      }

      promise
        .then((response) => {
          if (response && response.errors) {
            return Promise.reject(response);
          }
          setError(null);

          return setJson(response);
        })
        .catch((error) => {
          setJson(undefined);
          setError(error);
        })
        .finally(() => {
          if (forceRequest) setForceRequest(false);
          setIsLoading(false);
          setIsLoadingLocal(false);
        });
    };

    makeRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRequest, request, inProgress]);

  const get = useCallback((url: string, resetJson: boolean = false) => {
    resetJson && setJson(undefined);
    setRequest({ url: url, type: "GET" });
    setForceRequest(true);
  }, []);

  const post = useCallback((url: string, data: any, resetJson: boolean = false) => {
    resetJson && setJson(undefined);
    setRequest({ url: url, type: "POST", data: data });
  }, []);

  const put = useCallback((url: string, data: any, resetJson: boolean = false) => {
    resetJson && setJson(undefined);
    setRequest({ url: url, type: "PUT", data: data });
  }, []);

  const patch = useCallback((url: string, data: any, resetJson: boolean = false) => {
    resetJson && setJson(undefined);
    setRequest({ url: url, type: "PATCH", data: data });
  }, []);
  // cannot use name "delete"
  const remove = useCallback((url: string, resetJson: boolean = false) => {
    resetJson && setJson(undefined);
    setRequest({ url: url, type: "DELETE" });
  }, []);

  return { json, isLoading, error, get, post, put, patch, remove, isLoadingLocal };
};
