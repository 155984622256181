import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon, iconArrowRightRegular } from "theme/icons";
import { LearningEventMaterial, LearningEventStudent } from "types/learningEvent";
import { LinearProgressWithLabel } from "utils/helpers/LinearProgressIndicator";
import { ListItemButton } from "utils/helpers/ListItemButton";

type StudentEducationProps = {
  education: LearningEventMaterial;
  student: LearningEventStudent;
};

const StudentEducation = ({ education, student }: StudentEducationProps) => {
  const navigate = useNavigate();

  return (
    <ListItemButton
      primaryText={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            "& > *": { flexGrow: 1 },
            paddingLeft: 4,
            minHeight: 32,
          }}
        >
          <Typography variant="captionImage" sx={{ fontWeight: 600 }}>
            {education.name}
          </Typography>

          {/* TODO: Vilken progress ska visas? */}
          {/* <LinearProgressWithLabel value={0} color="success" /> */}
        </Stack>
      }
      endIcon={<Icon name={iconArrowRightRegular} color="info" />}
      onClick={() => navigate(`${student.id}/education/${education.id}/`)}
    />
  );
};

export default StudentEducation;
