import { useRecoilCallback, useRecoilValue } from "recoil";
import { educationCardIdsState, educationCardItemSelector } from "state/educationCardState";
import { EducationCardActivity } from "types/educationCard";

export const useEducationCardList = (key: string) => {
  const listIds = useRecoilValue(educationCardIdsState);
  const listIdsByKey = listIds.filter((id) => id.startsWith(key));

  const setList = useRecoilCallback(
    ({ set }) =>
      (list: EducationCardActivity[]) => {
        list.forEach((l) => {
          set(educationCardItemSelector(l.id), l);
        });
      },
    []
  );

  return {
    listIds: listIdsByKey,
    setList,
  };
};

export const useEducationCardListItem = (id: string) => {
  const item = useRecoilValue(educationCardItemSelector(id));

  const upsertListItem = useRecoilCallback(
    ({ set }) =>
      (item: EducationCardActivity) => {
        set(educationCardItemSelector(item.id), item);
      },
    []
  );

  return {
    item,
    upsertListItem,
  };
};
