import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { LearningEventMaterial } from "types/learningEvent";
import { API_URL_LES } from "utils/constants";
import { useFetch } from "./useFetch";
import { MemberRole } from "types/user-account";
import { useAppIntl } from "services/useAppIntl";
import { useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { educationState } from "state/educationState";
import { educationCardPropsState } from "state/educationCardState";
import { actorsSelector } from "state/educatorState";
import { userStateAccount } from "state/userState";

export type EducationCardType = "education" | "student" | "user";
type EducationCardProps = { type: EducationCardType; id?: string; educationId?: string; studentId?: string };

/**
 * Custom hook for fetching education card data based on the provided type.
 *
 * @param type - Type of the education card.
 * @param id - ID of the group (eventId).
 * @param educationId - ID of the education.
 * @param studentId - ID of the student.
 * @returns An object containing the reference key, name, and user IDs associated with the education card.
 */
export const useEducationCardSettings = ({ type, id: eventId, educationId, studentId }: EducationCardProps) => {
  const user = useRecoilValue(userStateAccount);
  const intl = useAppIntl();
  const { json: education, get: getEducation } = useFetch<LearningEventMaterial>();
  const actors = useRecoilValue(actorsSelector(eventId));
  const userEducation = useRecoilValue(educationState);
  const [educationCardProps, setEducationCardProps] = useRecoilState(educationCardPropsState);

  useEffect(() => {
    console.log("useEducationCard useEffect", eventId, educationId, studentId);

    if (eventId && educationId) getEducation(`${API_URL_LES}${eventId}/materials/${educationId}`);
  }, [eventId, educationId, studentId, getEducation]);

  useEffect(() => {
    let referenceKey: ReferenceKey;
    let userIds: string[];
    let name = "";
    let identifier = "";

    switch (type) {
      // Educationcard for an education. Filter all students from the actors
      case "education":
        if (!eventId) break;
        if (!education) break;
        if (!actors) break;

        referenceKey = { contentId: education.contentId, versionId: education.versionId };
        userIds = actors.filter((a) => a.role === MemberRole.Student).map((student) => student.id);
        name = education.name;
        identifier = `${eventId}-${educationId}-${studentId}`;
        setEducationCardProps({ type, eventId, referenceKey, userIds, name, identifier });

        break;
      // Educationcard for a student (on an education)
      case "student":
        if (!eventId) break;
        if (!education) break;
        if (!actors) break;
        if (!studentId) throw new Error("studentId is required");

        referenceKey = { contentId: education.contentId, versionId: education.versionId };
        userIds = [studentId];
        name = actors?.find((a) => a.id === studentId)?.extensions?.firstName || "no name";
        identifier = `${eventId}-${educationId}-${studentId}`;
        setEducationCardProps({ type, eventId, referenceKey, userIds, name, identifier });

        break;
      // Educationcard for the logged in user
      default:
        if (!user) throw new Error("user is required");
        if (!userEducation) throw new Error("user education is required");

        referenceKey = { contentId: userEducation.contentId!, versionId: userEducation.versionId! };
        userIds = [user.actorId];
        name = intl.formatMessage({ id: "common.title.education-card" });
        identifier = `${eventId}-${educationId}-${studentId}`;
        setEducationCardProps({ type, eventId: userEducation.educationId, referenceKey, userIds, name, identifier });

        break;
    }
  }, [eventId, education, actors, type, user, userEducation, intl, studentId, educationId, setEducationCardProps]);

  return educationCardProps;
};
