import { Control } from "react-hook-form";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputPasswordProps {
  control: Control<any, any> | undefined;
  namePrefix?: string;
  required?: boolean;
}

export const FormInputPassword = ({ control, namePrefix, required = true }: IFormInputPasswordProps) => {
  const intl = useAppIntl();
  let label = intl.formatMessage({ id: "profile.label.password" });

  if (required) label += " *";

  return (
    <FormInput
      name={namePrefix ? namePrefix + ".password" : "password"}
      // type="password"
      label={label}
      helperText={required ? intl.formatMessage({ id: "profile.label.password.helper-text" }) : ""}
      dataTestIdString={`${testId.input.profile}-password`}
      control={control}
      required
    />
  );
};
