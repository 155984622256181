import { FC } from "react";
import { Box } from "@mui/material";
import useRoles from "hooks/useRoles";
import { MainNavigation } from "./Components/MainNavigation";

export const HeaderDesktopNavigation: FC = () => {
  const { isEducator } = useRoles();

  if (isEducator) return null;

  return (
    <Box
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        width: "40%",
        ".MuiBottomNavigation-root": {
          width: "100%",
        },
      }}
    >
      <MainNavigation />
    </Box>
  );
};
