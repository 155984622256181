import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getLrsState } from "services/lrsService";
import { educationStateReferenceKey } from "state/educationState";
import { educationContentTypeState } from "state/learningStructureState";
import { useAssessment } from "./useAssessment";
import { useTestSettings } from "./useTestSettings";
import { userStateAccount } from "state/userState";

/**
 * Hook for getting final test status, e.g. if the user is allowed to take final test
 */
export const useFinalTestStatus = () => {
  const referenceKey = useRecoilValue(educationStateReferenceKey);
  if (!referenceKey) throw new Error("No education state reference key found");

  const account = useRecoilValue(userStateAccount);
  if (!account) throw new Error("No account found");

  const [finalTestUnlocked, setFinalTestUnlocked] = useState<boolean>();
  const { finalTest } = useTestSettings();
  const stateId = useRecoilValue(educationContentTypeState).get(finalTest.questionType)!;
  const { assessment } = useAssessment({
    type: finalTest.questionType,
    key: referenceKey,
  });

  useEffect(() => {
    if (!assessment) return;

    const xapiKey = {
      userId: account.actorId,
      contentId: referenceKey.contentId,
      versionId: referenceKey.versionId!,
      stateId: stateId,
    };

    getLrsState(xapiKey).then((lrsState) => {
      let assessmentState = lrsState.get(assessment.referenceKey.versionId);

      // Should be set to false if not found or if locked.
      if (assessmentState) setFinalTestUnlocked(assessmentState.finalTestLocked === false);
      else setFinalTestUnlocked(false);
    });
  }, [assessment, account.actorId, referenceKey, stateId]);

  return { finalTestUnlocked };
};
