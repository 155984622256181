import React from "react";
import { SxProps, TextField } from "@mui/material";
import { Control, useController, Validate } from "react-hook-form";

interface IFormInputProps {
  name: string;
  label?: string;
  defaultValue?: string | number;
  size?: "small" | "medium";
  type?: "password" | "number" | "search" | "text" | "email" | "hidden";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
  dataTestIdString?: string;
  control?: Control<any, any>;
  validate?: Validate<any, any> | Record<string, Validate<any, any>> | undefined;
  sx?: SxProps;
  multiline?: boolean;
}

/**
 * Custom form input.
 * @param label
 * @param name
 * @param defaultValue [optional?] 
 * @param size [optional?] "small" | "medium"
 * @param type [optional?] type of the input. defaults to "text" if not supplied. possible values: "password" | "number" | "search" | "text" | "email" | "hidden"
 * @param onChange [optional?] onChange event
 * @param required: [optional?];
 * @param helperText: [optional?] help text beneath the input
 * @param disabled: [optional?]
 * @param error: [optional?] error state of the input. should NOT be used when using the control prop (then the error is handled by the useController hook)
 * @param dataTestIdString [optional?] unique data-id for test 
 * @param control [optional?] use this if you want the input to be controlled by the useForm hook
 * @param validate [optional?] validation function for useForm hook
 * @param sx [optional?] custom styling

* @returns a controlled input
 */
export const FormInput: React.FC<IFormInputProps> = (props) => {
  if (props.control) return <FormInputControlled {...props} />;

  if (props.type === "hidden") return <input type={props.type} value={props.defaultValue} />;

  return (
    <TextField
      error={!!props.error}
      defaultValue={props.defaultValue}
      type={props.type}
      size={props.size}
      label={props.label}
      disabled={props.disabled}
      helperText={props.error && props.helperText}
      data-testid={props.dataTestIdString}
      sx={{ borderRadius: 1, ...props.sx }}
      multiline={props.multiline}
      rows={props.multiline ? 4 : 1}
      variant={props.multiline ? "filled" : "outlined"}
      fullWidth
    />
  );
};

const FormInputControlled: React.FC<IFormInputProps> = (props) => {
  const { name, control, type, defaultValue } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue: defaultValue ? defaultValue : "",
  });

  if (type === "hidden") return <input type={type} {...field} />;

  return (
    <TextField
      {...field}
      error={error !== undefined}
      type={props.type}
      size={props.size}
      label={props.label}
      disabled={props.disabled}
      helperText={error && props.helperText}
      data-testid={props.dataTestIdString}
      sx={{ borderRadius: 1, ...props.sx }}
      multiline={props.multiline}
      rows={props.multiline ? 4 : 1}
      variant={props.multiline ? "filled" : "outlined"}
      fullWidth
    />
  );
};
