import React from "react";
import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material";

interface ILinearProgressIndicatorProps {
  value: number;
  color?: LinearProgressProps["color"];
  height?: string;
  progressText?: boolean;
  progressTitle?: string;
}

export const LinearProgressIndicator: React.FC<ILinearProgressIndicatorProps> = ({
  value,
  color,
  height,
  progressText,
  progressTitle,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        {progressTitle && (
          <Typography variant="caption" mb={0.5}>
            {progressTitle}
          </Typography>
        )}
        {progressText && (
          <Typography variant="caption" mb={0.5}>
            {`${value}%`}
          </Typography>
        )}
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        color={color}
        sx={{ height: height ? height : undefined, flexGrow: 1 }}
      />
    </Box>
  );
};

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="captionImage" sx={{ fontWeight: 600 }}>{`${Math.round(props.value)}%`}</Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
};
