import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { learningStructureState } from "state/learningStructureState";
import { getTestReport } from "services/lrsService";
import { ItemTestResult, ItemTypeKnowledge, ItemTestResultItem } from "services/reportingService";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { userStateAccount } from "state/userState";
import { wait } from "utils/waitPromise";

interface IUseTestResult {
  /** Use this to get the results for a specific acticity */
  referenceKey?: ReferenceKey;
  /** Use this to get the result for a specific test */
  testId?: string;
  /** Use this to get the result for a specific user */
  actorId?: string;
  /** Use this to retry the request (since the result may not be generated instantly) */
  retry?: boolean;
}

/**
 * Custom hook to fetch and manage test results based on a reference key and test ID.
 *
 * @param {string} [referenceKey] - The reference key to identify the test results.
 * @param {string} [testId] - The ID of the specific test to filter results.
 * @param {string} [actorId] - The ID of the specific user to filter results.
 * @param {boolean} [retry] - Use this to retry the request (since the result may not be generated instantly).
 *
 * @returns {ItemTestResultItem[] | undefined} testResults - The list of test results.
 * @returns {ItemTestResultItem | undefined} testResult - The specific test result filtered by testId.
 *
 * @example
 * const { testResults, testResult } = useTestResult({ referenceKey: 'someKey', testId: 'someTestId', retry: true });
 */
export const useTestResult = ({ referenceKey, testId, actorId, retry }: Partial<IUseTestResult> = {}) => {
  const account = useRecoilValue(userStateAccount);
  const [testResults, setTestResults] = useState<undefined | ItemTestResultItem[]>();
  const [testResult, setTestResult] = useState<undefined | ItemTestResultItem>();
  const learningStructure = useRecoilValue(learningStructureState);
  const [retryAttempt, setRetryAttempt] = useState(retry ? 1 : undefined);

  useEffect(() => {
    if (!account || !learningStructure) return;
    let key: any = referenceKey;

    // Get education root if no referenceKey
    if (!referenceKey) key = learningStructure?.find((x) => x.parentId === 0)?.activityKey;

    const getResults = async () => {
      const testReport = await getTestReport(actorId ?? account.actorId, key);
      const testResults = testReport.items.filter(
        (item: any) => item.type === ItemTypeKnowledge.TestResults
      ) as ItemTestResult[];

      const results = testResults.length ? testResults[0].items : [];

      // Filter result by testId
      if (testId) {
        const result = results.filter((result) => result.statementRef === testId)[0];

        // If result not found, retry
        if (!result && retryAttempt !== undefined && retryAttempt <= 5) {
          console.log("Retrying test result fetch attempt", retryAttempt);
          await wait(1000);
          setRetryAttempt((attempt) => (attempt ? attempt + 1 : 1));
          return;
        }

        setTestResult(result);
        setRetryAttempt(undefined);
      }

      setTestResults(results);
    };

    getResults();
  }, [account, learningStructure, referenceKey, testId, actorId, retryAttempt]);

  return { testResults, testResult };
};
