import { testId } from "tests/testIdStrings";
import { iconArrowCircleRight } from "theme/icons";
import { UserEducation } from "types/education";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useNavigate } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { Box, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEducation } from "hooks/useEducation";
import { useRecoilValue } from "recoil";
import { userEducationsState } from "state/educationState";
import { InfoBoxNoEducation } from "./infoBoxNoEducation";

const EducationList = () => {
  const userEducations = useRecoilValue(userEducationsState);

  if (userEducations === undefined) return null;

  if (userEducations.length === 0) return <InfoBoxNoEducation />;

  return (
    <Stack spacing={1.5}>
      {userEducations.map((education) => (
        <EducationItem key={education.educationId} education={education} />
      ))}
    </Stack>
  );
};

export default EducationList;

const EducationItem = ({ education }: { education: UserEducation }) => {
  const isSmallDevice = useMediaQuery("(max-width:375px)");
  // const intl = useAppIntl();
  const navigate = useNavigate();
  const routes = useRouteConfiguration();
  const { setEducation } = useEducation();

  const setCurrentActiveEducation = (education: UserEducation) => {
    setEducation(education);
    navigate(`${routes.home.path}`);
  };

  const handleOnClick = () => {
    return setCurrentActiveEducation(education);
  };

  const getTitleText = () => {
    return isSmallDevice && education.name.length > 9 ? education.name.substring(0, 10) + "..." : education.name;
  };

  return (
    <Box display={"flex"} flexDirection={"column"} key={education.educationId}>
      <NavigationItem
        key={education.educationId}
        size="large"
        text={getTitleText()}
        // subText={isActive ? getSubText() : ""}
        // icon={getEducationIcon(education.name)}
        // navigationText={getNavigationText()}
        navigationIcon={iconArrowCircleRight}
        onClick={handleOnClick}
        iconSize="medium"
        dataTestIdString={`${testId.listItem.education.educationNavigationItem}-${education.educationId
          .match(/([^-]+$)/g)
          ?.toString()}`}
      />
    </Box>
  );
};
