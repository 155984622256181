import { atom, selectorFamily } from "recoil";
import {
  LearningEvent,
  LearningEventActor,
  LearningEventEducator,
  LearningEventMaterial,
  LearningEventStudent,
} from "types/learningEvent";
import { API_URL_LES } from "utils/constants";
import { get as httpGet } from "services/httpService";
import { storageEffect } from "./stateHelper";

export const actorsStateUpdated = atom<number>({
  key: "actorsStateUpdated",
  default: 0,
});

export const groupUnit = atom<string | undefined>({
  key: "groupUnit",
  default: undefined,
  effects: [storageEffect("session", "groupUnit")],
});

export const actorsSelector = selectorFamily<LearningEventActor[], string | undefined>({
  key: "actorsSelector",
  get:
    (eventId) =>
    async ({ get }) => {
      if (!eventId) return [];

      // Workaround to trigger the selector to run when the actors list is updated
      get(actorsStateUpdated);

      const actors = (await httpGet(`${API_URL_LES}${eventId}/actors`)) as LearningEventActor[] | undefined;
      if (!actors) return [];

      console.log("actors from state", actors);

      return actors;
    },
});

export const studentsSelector = selectorFamily<LearningEventStudent[], string | undefined>({
  key: "studentsSelector",
  get:
    (eventId) =>
    ({ get }) => {
      if (!eventId) return [];

      const actors = get(actorsSelector(eventId));
      return actors.filter((a) => a.role === "Student") as LearningEventStudent[];
    },
});

export const studentSelector = selectorFamily<LearningEventStudent | undefined, { eventId: string; actorId: string }>({
  key: "studentSelector",
  get:
    (params) =>
    ({ get }) => {
      if (!params.eventId) return undefined;

      const actors = get(studentsSelector(params.eventId));
      return actors.find((a) => a.id === params.actorId);
    },
});

export const educatorsSelector = selectorFamily<LearningEventEducator[], string | undefined>({
  key: "educatorsSelector",
  get:
    (eventId) =>
    ({ get }) => {
      if (!eventId) return [];

      const actors = get(actorsSelector(eventId));
      const educators = actors.filter((a) => a.role === "Educator") as LearningEventEducator[];
      const sorted = educators.sort((a, b) => Number(a.extensions.isResponsible) - Number(b.extensions.isResponsible));

      return sorted;
    },
});

export const educationsSelector = selectorFamily<LearningEventMaterial[] | undefined, string | undefined>({
  key: "educationsSelector",
  get:
    (eventId) =>
    async ({ get }) => {
      if (!eventId) return undefined;

      const materials = (await httpGet(`${API_URL_LES}${eventId}/materials`)) as LearningEventMaterial[] | undefined;
      if (!materials) return [];

      console.log("materials from state", materials);

      return materials;
    },
});

export const groupSelector = selectorFamily<LearningEvent | undefined, string | undefined>({
  key: "groupSelector",
  get:
    (eventId) =>
    async ({ get }) => {
      if (!eventId) return undefined;

      const group = (await httpGet(`${API_URL_LES}${eventId}`)) as LearningEvent | undefined;

      console.log("materials from state", group);

      return group;
    },
});
