import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BasicLayout } from "layout";

type AppIntroProps = {
  text?: string;
};
export const AppIntro = ({ text }: AppIntroProps) => {
  return (
    <BasicLayout paddingBottom={0} paddingLeft={0} paddingRight={0} paddingTop={0}>
      <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" px="2rem" textAlign="center">
        <img
          src={process.env.PUBLIC_URL + "/img/logoFM.png"}
          alt="Försvarsmakten logo"
          style={{ width: "172px", height: "70px", marginLeft: "auto", marginRight: "auto" }}
        />
        <Typography variant="h1" fontSize="24px" color="#FFFFFF">
          {text}
        </Typography>
      </Box>
    </BasicLayout>
  );
};
