import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Grid, Box, Container, useTheme, Typography } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { useGlobalSnackbarMessage } from "hooks/useSnackbarMessage";
import { FormInputEmail } from "layout/Components/Inputs/formInputEmail";
import { FormInputFirstName } from "layout/Components/Inputs/formInputFirstName";
import { FormInputLastName } from "layout/Components/Inputs/formInputLastName";
import { FormInputPhoneNumber } from "layout/Components/Inputs/formInputPhoneNumber";
import { useForm } from "react-hook-form";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useAppIntl } from "services/useAppIntl";
import { loadingStateText } from "state/recoilAtoms";
import { LearningEventActor } from "types/learningEvent";
import { API_URL_CS, API_URL_LES } from "utils/constants";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { post as httpPost } from "services/httpService";
import { validationSchemaActorCreate } from "services/validationSchemaUser";
import { validationSchemaActorUpdate } from "services/validationSchemaUser";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputUserName } from "layout/Components/Inputs/formInputUserName";
import { FormInputPassword } from "layout/Components/Inputs/formInputPassword";
import { CustomerMember, CustomerMemberAdd, CustomerMemberUpdate } from "types/customerMember";
import { userStateAccount } from "state/userState";
import { actorsStateUpdated, groupUnit } from "state/educatorState";
import { MemberRole } from "types/user-account";

type StudentFormProps = {
  student: LearningEventActor;
  learningEventId: string;
  type: "create" | "edit" | "add";
};

async function createCustomerMember(data: LearningEventActor, customerId: string, unitId: string) {
  const newMember: CustomerMemberAdd = {
    userName: data.extensions!.userName,
    password: data.extensions!.password,
    firstName: data.extensions!.firstName,
    lastName: data.extensions!.lastName,
    email: data.extensions!.email,
    phoneNumber: data.extensions!.phoneNumber,
    identityNumber: data.extensions!.identityNumber,
    association: { role: MemberRole.Student, associatedToId: unitId },
  };

  const member: CustomerMember = await httpPost(`${API_URL_CS}${customerId}/members`, newMember);
  return member;
}

function memberToActor(data: CustomerMember) {
  const actor: LearningEventActor = {
    id: data.actorId,
    role: MemberRole.Student,
    extensions: {
      firstName: data.firstName,
      lastName: data.lastName,
      identityNumber: data.identityNumber,
    },
  };

  if (data.email) actor.extensions!.email = data.email;
  if (data.phoneNumber) actor.extensions!.phoneNumber = data.phoneNumber;

  return actor;
}

function actorToCustomerMember(data: LearningEventActor) {
  const member: CustomerMemberUpdate = {
    firstName: data.extensions!.firstName,
    lastName: data.extensions!.lastName,
    email: data.extensions!.email,
    phoneNumber: data.extensions!.phoneNumber,
    identityNumber: data.extensions!.identityNumber, // service demands this
  };
  return member;
}

const StudentForm = (props: StudentFormProps) => {
  const theme = useTheme();
  const intl = useAppIntl();
  const navigate = useNavigate();
  const { setSnackbarValues } = useGlobalSnackbarMessage();
  const setLoadingText = useSetRecoilState(loadingStateText);
  const userAccount = useRecoilValue(userStateAccount);
  const unitId = useRecoilValue(groupUnit);
  const updateActorsState = useSetRecoilState(actorsStateUpdated);
  const { control, handleSubmit, formState } = useForm<LearningEventActor>({
    mode: "onChange",
    defaultValues: props.student,
    resolver: yupResolver(props.type === "create" ? validationSchemaActorCreate : validationSchemaActorUpdate),
  });
  const { put, post, error, json } = useFetch<LearningEventActor>();
  const formNamePrefix = "extensions";

  console.log("StudentForm", formState.isValid, formState.defaultValues, formState.errors);

  // After form submit
  useEffect(() => {
    if (error) setSnackbarValues({ message: error.title, type: "error" });

    if (json) {
      // Add timeout to make sure the BE is updated before navigating
      setTimeout(() => {
        updateActorsState((n: number) => n + 1);

        console.log("förare json", json);

        setSnackbarValues({
          message: props.type === "edit" ? "Föraren är uppdaterad" : "Föraren har lagts till",
          type: "success",
        });
        navigate("..", { relative: "path" });
      }, 500);
    }

    return () => {
      setLoadingText(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, json]);

  const onSubmit = async (data: LearningEventActor) => {
    setLoadingText(intl.formatMessage({ id: "common.saving" }));

    if (props.type === "edit") {
      console.log("updating customer member", data);

      const member = actorToCustomerMember(data);
      await put(`${API_URL_CS}${userAccount!.customerId}/members/${data.id}`, member);
    }

    if (props.type === "add") {
      const actor = data;

      console.log("adding actor to learning event", actor);
      post(`${API_URL_LES}${props.learningEventId}/actors`, [actor]);
    }

    if (props.type === "create") {
      const member = await createCustomerMember(data, userAccount!.customerId, unitId!);
      const actor = memberToActor(member);

      console.log("adding actor", actor, "to learning event", props.learningEventId);

      post(`${API_URL_LES}${props.learningEventId}/actors`, [actor]);
    }
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
  };

  if (!unitId) {
    // Unit id is somehow missing, redirect to start page to let it be set again
    setSnackbarValues({ message: "Enhetsid saknas, vänligen börja om.", type: "error" });
    navigate("/");
    return null;
  }

  return (
    <form>
      <FormattedTypography id="profile.label.personal-data" variant="subtitle1" mb={2} />
      <Grid container rowSpacing={4} columnSpacing={2}>
        <Grid item xs={6}>
          <FormInputFirstName control={control} disabled={props.type === "add"} namePrefix={formNamePrefix} />
        </Grid>
        <Grid item xs={6}>
          <FormInputLastName control={control} disabled={props.type === "add"} namePrefix={formNamePrefix} />
        </Grid>
      </Grid>

      <FormattedTypography id="profile.label.contact.information" variant="subtitle1" mb={2} mt={5} />
      <Grid container rowSpacing={4} columnSpacing={2}>
        <Grid item xs={6}>
          <FormInputPhoneNumber control={control} disabled={props.type === "add"} namePrefix={formNamePrefix} />
        </Grid>
        <Grid item xs={6}>
          <FormInputEmail control={control} disabled={props.type === "add"} namePrefix={formNamePrefix} />
        </Grid>
      </Grid>

      {props.type === "create" && (
        <>
          <Typography variant="subtitle1" mb={2} mt={5}>
            Kontouppgifter
          </Typography>
          <Grid container rowSpacing={6} columnSpacing={2}>
            <Grid item sm={6}>
              <FormInputUserName control={control} namePrefix={formNamePrefix} />
            </Grid>
            <Grid item sm={6}>
              <FormInputPassword control={control} namePrefix={formNamePrefix} />
            </Grid>
          </Grid>
        </>
      )}

      {/* Footer */}
      <Box sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
        <Divider sx={{ m: 0, mt: 5 }} />
        <Container
          maxWidth="md"
          sx={{ textAlign: "end", px: "24px", py: "12px", backgroundColor: theme.palette.background.default }}
        >
          <Button variant="text" color="info" onClick={() => navigate("..", { relative: "path" })}>
            <FormattedTypography variant="button" id="common.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave} disabled={!formState.isValid}>
            <FormattedTypography variant="button" id="common.save" />
          </Button>
        </Container>
      </Box>
    </form>
  );
};

export default StudentForm;
