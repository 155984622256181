import { atom, selector, selectorFamily } from "recoil";
import { UserAccount, UserAvatar } from "types/user";
import { storageEffect } from "./stateHelper";

export const userStateAccount = atom<UserAccount | undefined>({
  key: "userAccount",
  default: undefined,
  effects: [storageEffect("session", "userAccount")],
});

export const userStateAvatarById = selectorFamily<UserAvatar | undefined, string>({
  key: "userAvatarById",
  get:
    (id: string) =>
    ({ get }) => {
      const list = get(userStateAvatarList);
      return list.find((avatar) => avatar.id === id);
    },
});

/**
 * This state should be when user is logged in and reset password
 */
export const passwordChangedSuccess = atom<boolean>({
  key: "passwordChangedSuccess",
  default: undefined,
  effects: [storageEffect("local", "passwordChanged")],
});

export const userStateAvatarList = selector<UserAvatar[]>({
  key: "userAvatarList",
  get: ({ get }) => {
    //TODO: Api anrop för att hämta ut listan

    const avatars = generateAvatars();

    return avatars;
  },
});

const generateAvatars = () => {
  const array: UserAvatar[] = [];
  const imgPath = "/img/avatars/";

  for (let index = 1; index <= 40; index++) {
    const avatar: UserAvatar = {
      id: index.toString(),
      src: process.env.PUBLIC_URL + imgPath + `Avatar-${index}.jpg`,
    };
    array.push(avatar);
  }

  return array;
};
