import React, { ReactElement, ReactNode } from "react";
import {
  ListItemButton as MUIListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItemButtonProps,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataTestType } from "tests/test.setup";

interface IFormListItemButtonProps extends DataTestType, ListItemButtonProps {
  to?: string;
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  endText?: ReactElement;
  height?: number | string;
}

export const ListItemButton: React.FC<IFormListItemButtonProps> = (props) => {
  let listItemProps;

  if (props.to) {
    listItemProps = {
      component: Link,
      to: props.to,
    };
  } else {
    listItemProps = {
      onClick: props.onClick,
    };
  }

  // We need to make sure that we don't generate invalid DOM nesting (since Typography renders a <p> tag)
  let primaryText = props.primaryText;
  if (
    typeof props.primaryText === "string" ||
    typeof props.primaryText === "number" ||
    typeof props.primaryText === "boolean"
  ) {
    primaryText = (
      <Typography pb={0} noWrap>
        {props.primaryText}
      </Typography>
    );
  }

  return (
    <MUIListItemButton
      {...listItemProps}
      selected={props.selected}
      divider
      sx={{ minHeight: props.height ?? "75px" }}
      data-testid={props.dataTestIdString}
    >
      {props.startIcon && <ListItemIcon sx={{ minWidth: "unset", mr: 2 }}>{props.startIcon}</ListItemIcon>}
      <ListItemText primary={primaryText} secondary={props.secondaryText && props.secondaryText} />
      {props.endText && props.endText}
      {props.endIcon && <ListItemIcon sx={{ minWidth: "unset", ml: 2 }}>{props.endIcon}</ListItemIcon>}
    </MUIListItemButton>
  );
};
