import React, { useEffect } from "react";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { testId } from "tests/testIdStrings";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { iconLockOpen } from "theme/icons";
import { Stack } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { useSetRecoilState } from "recoil";
import { educationStateFinalTestUnlocked } from "state/educationState";
import { useAppIntl } from "services/useAppIntl";

type Props = {
  open: boolean;
  close: () => void;
};

export const FinalTestDialog: React.FC<Props> = ({ open, close }) => {
  const intl = useAppIntl();
  // const educationIdentifier = useRecoilValue(educationState)?.educationId;
  const finalTestUnlocked = useSetRecoilState(educationStateFinalTestUnlocked);
  const refreshEducations = () => finalTestUnlocked((n: number) => n + 1);

  const { json } = useFetch();

  useEffect(() => {
    if (!json) return;

    refreshEducations();
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [json]);

  const handleUnlock = () => {
    // patch(API_URL_BO_EDUCATION + "final-test-is-unlocked", {
    //   finalTestIsUnlocked: true,
    //   educationIdentifier: educationIdentifier!,
    // });
  };

  return (
    <DialogMessage
      title={intl.formatMessage({ id: "test.final.dialog.title" })}
      open={open}
      close={close}
      primaryButtonText="test.final.dialog.button"
      primaryButtonColor="error"
      primaryButtonIcon={iconLockOpen}
      primaryButtonAction={handleUnlock}
      dataTestIdString={testId.listItem.test.common.unlock}
    >
      <Stack spacing={2}>
        <FormattedTypography id="test.final.dialog.body-1" />
        <FormattedTypography id="test.final.dialog.body-2" />
        <FormattedTypography id="test.final.dialog.body-3" />
      </Stack>
    </DialogMessage>
  );
};
