import { useState } from "react";
import { Icon, iconAward } from "theme/icons";
import { Box, Stack, Typography } from "@mui/material";
import Status from "./Status";
import ItemContainer from "./ItemContainer";
import Comment, { AddComment } from "./Comment/Comment";
import FormSwitch from "utils/helpers/FormSwitch";
import useRoles from "hooks/useRoles";
import { LoadingStatus } from "types/request";
import { useRecoilValue } from "recoil";
import { educationCardPropsState } from "state/educationCardState";
import { EducationCardActivityTest } from "types/educationCard";
import AccordionEducationCard from "layout/Components/AccordionEducationCard";
import Students from "./students";
import { delay } from "services/educationCardService";
import { useTestResult } from "hooks/useTestResult";
import { ItemConfigurationTypeKnowledge } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import LearningObjectiveHeader from "./LearningObjectiveHeader";
import { useTestSettings } from "hooks/useTestSettings";
import UnlockTestSwitch from "./students/UnlockTest";

const ItemTestTheoretical = ({ item, userIds }: { item: EducationCardActivityTest; userIds: string[] }) => {
  // const { item, upsertListItem } = useEducationCardListItem(id);
  const [status, setStatus] = useState<LoadingStatus>("none");
  const { isEducator } = useRoles();
  const state = useRecoilValue(educationCardPropsState);

  if (!state) throw new Error("No education card state found");

  // Approve all learningObjectives in this item
  async function handleApproveAll(checked: boolean) {
    if (!state) throw new Error("No education card state found");

    console.log("Approving TODO LOGIC", item.title);
    setStatus("pending");

    // collect all children
    // let theoreticalRefKeys = item.theoryItems?.map((i) => i.referenceKey) || [];
    // let updatedItem = await setApproved(
    //   state.eventId,
    //   item.rootKey,
    //   item.parentKey,
    //   theoreticalRefKeys,
    //   state.userIds,
    //   "theoretical",
    //   checked,
    //   item.learningObjectives,
    //   state.identifier
    // );

    // let practicalRefKeys = item.practiceItems?.map((i) => i.referenceKey) || [];
    // updatedItem = await setApproved(
    //   state.eventId,
    //   item.rootKey,
    //   item.parentKey,
    //   practicalRefKeys,
    //   state.userIds,
    //   "practical",
    //   checked,
    //   item.learningObjectives,
    //   state.identifier
    // );

    // TODO: handle errors...

    // upsertListItem(updatedItem);

    setStatus("none");
    console.log(item.title, "is", checked ? "approved" : "not approved");
  }

  return (
    <AccordionEducationCard
      id={item.id}
      status={status}
      summary={
        <ItemContainer>
          <Stack spacing={1} direction="row" alignItems="center">
            <Icon name={iconAward} color="info" />
            <Typography variant="subtitle2">{item.title}</Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            {isEducator ? (
              <>
                <Status status={item.status} />
                <FormSwitch label="Godkänd" checked={item.approved} onChange={handleApproveAll} />
                <UnlockTestSwitch label="Lås upp" userIds={userIds} />
                {state.type !== "education" && <Comment hasComment={false} noButton />}
              </>
            ) : (
              <Status status={item.status} />
            )}
            {state.type === "education" && <Students item={item} />}
          </Stack>
        </ItemContainer>
      }
      details={state.type !== "education" && <GetTestResults userId={state.userIds[0]} item={item} />}
    />
  );
};

export default ItemTestTheoretical;

const GetTestResults = ({ userId, item }: { userId: string; item: EducationCardActivityTest }) => {
  const { testResults } = useTestResult({ actorId: userId });
  const { getLocalDate } = useDateService();
  const { finalTest } = useTestSettings();

  // TODO: Vad returnera?
  if (testResults === undefined) return null;

  const finalTests = testResults.filter(
    (result) => result.configuration.name === ItemConfigurationTypeKnowledge.FinalA
  );

  console.log("finalTests", finalTests);

  return (
    <Stack spacing={2}>
      <LearningObjectiveHeader type={"test"} tempProp="student" />

      {finalTests.map((result, i) => {
        const { value, finished } = result;
        const status = parseInt(value) >= finalTest.ratio! ? "Success" : "Fail";

        return (
          <ItemContainer key={result.statementRef}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Typography variant="subtitle2" fontWeight={600}>
                {getLocalDate(finished)}
              </Typography>
            </Box>
            <Stack spacing={1} direction="row" alignItems="center">
              <Status status={status} />

              <AddComment comments={item.comments} title={item.title} />
            </Stack>
          </ItemContainer>
        );
      })}
    </Stack>
  );
};
