import { SyntheticEvent, useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { Icon, iconCircleInfo, iconUserGroup } from "theme/icons";
import { Link, useLocation, useParams } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import FormattedMessage from "utils/helpers/FormattedMessage";

export enum EducatorTab {
  information = "Information",
  students = "Förare",
}

export const HeaderNavigationTabs = () => {
  const location = useLocation();
  const { groupId } = useParams();
  const [activeTab, setActiveTab] = useState<EducatorTab>(EducatorTab.information); /* Todo: temporary context*/
  const routes = useRouteConfiguration();

  const handleChange = (event: SyntheticEvent, newValue: EducatorTab) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const tab = location.pathname.includes("students") ? EducatorTab.students : EducatorTab.information;
    setActiveTab(tab);
  }, [location]);

  return (
    <Tabs
      value={activeTab}
      onChange={handleChange}
      TabIndicatorProps={{ sx: { backgroundColor: "white", top: "0" } }}
      textColor="inherit"
      centered
    >
      <Tab
        component={Link}
        to={routes.educator.routes!.group.path.replace(":groupId", groupId!)}
        icon={<Icon name={iconCircleInfo} />}
        iconPosition="start"
        value={EducatorTab.information}
        // data-testid={testId.menu.tab.information}
        label={<FormattedMessage id="common.title.educator.information" />}
      />
      <Tab
        component={Link}
        to={routes.educator.routes!.students.path.replace(":groupId", groupId!)}
        icon={<Icon name={iconUserGroup} />}
        iconPosition="start"
        value={EducatorTab.students}
        // data-testid={testId.menu.tab.answer}
        label={<FormattedMessage id="common.title.educator.persons" />}
      />
    </Tabs>
  );
};
