import useLocalStorage from "hooks/useLocalStorage";
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { LanguageState, StateKey, getState, languageStateId, saveState } from "services/appStateService";
import { SupportedLanguages } from "services/localizationService";
import { ThemeName } from "./ThemeContext";

// TODO: Where to put this?
declare global {
  interface Window {
    env: {
      APP_THEME: ThemeName;
    };
  }
}
const appTheme = window.env.APP_THEME;

type AppContextProps = {
  activeLanguage: SupportedLanguages;
  setActiveLanguage: (lang: SupportedLanguages) => void;
  soundSupport: boolean;
  setSoundSupport: (soundSupport: boolean) => void;
  themeName: ThemeName;
  setThemeName: (theme: ThemeName) => void;
};

export const AppContext = createContext<AppContextProps | undefined>(undefined);

/**
 * Custom hook that provides access to the AppContext.
 * @returns The AppContext value
 * @throws {TypeError} If used outside of an AppContextProvider
 */
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) throw new TypeError("useAppContext must be used within a AppContextProvider");
  return context;
};

/**
 * Provides the application context to its children components.
 *
 * @param children - The child components to be wrapped by the context provider.
 */
export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [activeLanguage, setActiveLanguage] = useState<SupportedLanguages>("sv");
  const [lsSoundSupport, setLsSoundSupport] = useLocalStorage("soundSupport", false);
  const [soundSupport, setSoundSupport] = useState(lsSoundSupport);
  const [themeName, setThemeName] = useState(appTheme);

  if (process.env.NODE_ENV === "development") console.log("Active language is", activeLanguage);

  useEffect(() => {
    const key: StateKey = {
      typeId: languageStateId,
    };

    getState<LanguageState>(key).then((state) => {
      if (state && state.length) {
        if (process.env.NODE_ENV === "development") console.log("Set lang from state service to", state[0].lang);
        setActiveLanguage(state[0].lang);
      }
    });
  }, []);

  const appContextProps = useMemo(() => {
    function setSoundSupportLs(soundSupport: boolean) {
      setLsSoundSupport(soundSupport);
      setSoundSupport(soundSupport);
    }

    function setLanguage(lang: SupportedLanguages) {
      const state = { lang };
      const key: StateKey = {
        typeId: languageStateId,
      };

      console.log("Setting language to", lang);

      setActiveLanguage(lang);
      saveState(key, state);
    }

    return {
      activeLanguage,
      soundSupport,
      themeName,
      setActiveLanguage: setLanguage,
      setSoundSupport: setSoundSupportLs,
      setThemeName,
    };
  }, [activeLanguage, soundSupport, setLsSoundSupport, themeName]);

  return <AppContext.Provider value={appContextProps}>{children}</AppContext.Provider>;
};
