import React from "react";
import { Box, ListItemButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ItemTestResultItem } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import { useAppIntl } from "services/useAppIntl";
import { styleTabContent, testTypeName } from "./allTestResults";
import { Link, useNavigate } from "react-router-dom";
import { Icon, iconArrowCircleRight, iconArrowRightRegular } from "theme/icons";

type Props = {
  testResults: ItemTestResultItem[];
};

export const StudyTest: React.FC<Props> = ({ testResults }) => {
  const intl = useAppIntl();
  const navigate = useNavigate();
  const { getDateWithLocalTime: getDateWithTime, getDuration } = useDateService();

  const openTest = (id: string) => {
    navigate("" + id);
  };

  return (
    <>
      {testResults
        .map((row, i) => {
          const { duration, finished, max, title, value, statementRef } = row;
          const testDuration = duration
            ? getDuration(duration)
            : intl.formatMessage({ id: "test.create.custom.dropdown.duration" });

          return (
            <ListItemButton key={i} sx={styleTabContent} component={Link} to={statementRef}>
              <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                <Typography width={65} variant="body2" fontWeight={600}>
                  {value}/{max}
                </Typography>
                <Typography variant="body2" display={"flex"} textAlign={"left"} pr={2}>
                  {testTypeName(title)}
                </Typography>
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <Box display={"flex"} flexDirection={"column"} textAlign={"right"} mr={2}>
                  <Typography variant="body2">{testDuration}</Typography>
                  <Typography variant="caption">{getDateWithTime(finished)}</Typography>
                </Box>
                <Icon name={iconArrowRightRegular} color="info" />
              </Box>
            </ListItemButton>
          );
        })
        .reverse()}
    </>
  );
};
