import { Stack } from "@mui/material";
import { EducationCardActivity, LearningObjectiveType } from "types/educationCard";
import Header from "./LearningObjectiveHeader";
import { PropsWithChildren } from "react";
import { EducationCardType } from "hooks/useEducationCardSettings";

const LearningObjectives = ({
  items,
  type,
  status,
  children,
  tempProp,
}: {
  items?: EducationCardActivity[];
  type?: LearningObjectiveType;
  status?: string;
  tempProp?: EducationCardType; // Temp for demo. Refactor when done
} & PropsWithChildren) => {
  if (!items || !items.length) return null;

  return (
    <Stack spacing={tempProp === "user" ? 1.5 : 0}>
      <Header type={type} status={status} tempProp={tempProp} />
      {children}
    </Stack>
  );
};

export default LearningObjectives;
