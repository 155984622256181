import React from "react";
import { Box, Typography, Drawer, List, ListSubheader, alpha, IconButton, Stack } from "@mui/material";
import { useRecoilValue, useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import { Icon, iconChevronDown, iconChevronUp, iconXmark } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { TheorySections } from "../../../layout/theory/sections";
import { theoryChapterState, theoryAreaState, theoryStateChildrenByParentId } from "state/theoryState";
import useChapterActivities from "hooks/useChapterActivities";

type Props = {
  showSideMenu: boolean;
  setShowSideMenu: (bool: boolean) => void;
};

export const SideMenu: React.FC<Props> = ({ showSideMenu, setShowSideMenu }: Props) => {
  const navigate = useNavigate();
  const area = useRecoilValue(theoryAreaState);
  const chapters = useRecoilValue(theoryStateChildrenByParentId(area ? area.id : -1));
  const [chapterState, setChapterState] = useRecoilState(theoryChapterState);
  const { setChapterActivities } = useChapterActivities();

  const handleSelectedItem = (chapter: any) => {
    setChapterState(chapter);
    // För learning state
    setChapterActivities(chapter);
    const url = window.location.href;
    const paths = url.split("/");
    const currentTab = paths[paths.length - 1];
    const path = `theory/${area?.id}/${chapter.id}/${currentTab}`;
    navigate(path);
  };

  return (
    <Drawer
      open={showSideMenu}
      onClose={() => setShowSideMenu(!showSideMenu)}
      anchor="right"
      PaperProps={{ sx: { width: "40%" } }}
    >
      <List disablePadding component={Box}>
        <ListSubheader component={Box} disableGutters>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" color={alpha("#000000", 0.87)} pl={2}>
              Innehåll
            </Typography>
            <IconButton onClick={() => setShowSideMenu(!showSideMenu)} color="info">
              <Icon name={iconXmark} size="large" />
            </IconButton>
          </Stack>
        </ListSubheader>
        {chapters.map((chapter, index) => {
          return (
            showSideMenu && (
              <NavigationItem
                borderRadius={"0px"}
                key={chapter.id}
                text={chapter.title}
                textVariant="subtitle2"
                icon={[iconChevronDown, iconChevronUp]}
                iconSize="medium"
                onClick={() => handleSelectedItem(chapter)}
                // progressValue={75}
                dataTestIdString={`${testId.listItem.theory.chapters}-${index + 1}`}
                showChildren={chapter.id === chapterState?.id ? true : undefined}
              >
                <TheorySections chapterId={chapter.id} selectFirst={chapter.id === chapterState?.id} />
              </NavigationItem>
            )
          );
        })}
      </List>
    </Drawer>
  );
};
