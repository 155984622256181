import { useState } from "react";
import { useRecoilValue } from "recoil";
import { Stack, Typography } from "@mui/material";
import Status from "./Status";
import { AddComment } from "./Comment/Comment";
import LearningObjectives from "./LearningObjectives";
import ItemContainer from "./ItemContainer";
import FormSwitch from "utils/helpers/FormSwitch";
import useRoles from "hooks/useRoles";
import { LoadingStatus } from "types/request";
import { setApproved } from "services/educationCardService";
import { educationCardPropsState } from "state/educationCardState";
import { useEducationCardListItem } from "hooks/useEducationCard";
import LearningObjective from "./LearningObjective";
import { EducationCardActivity, EducationCardProgress } from "types/educationCard";
import Students from "./students";
// import ButtonPdf from "./Pdf";
import AccordionEducationCard from "layout/Components/AccordionEducationCard";

type ItemProps = { id: string; setProgress: (progress: EducationCardProgress) => void };

const Item = ({ id, setProgress }: ItemProps) => {
  const { item, upsertListItem } = useEducationCardListItem(id);
  const [status, setStatus] = useState<LoadingStatus>("none");
  const { isEducator } = useRoles();
  const state = useRecoilValue(educationCardPropsState);

  if (!state) throw new Error("No education card state found");

  // TODO: PDF link should be dynamic and set on item
  // const itemPdf = process.env.PUBLIC_URL + "/pdf/Forarbevisprotokoll.pdf";

  console.log("Item state", state);

  // const theoryComments = item.theoryItems?.some((i) => i.comments !== undefined && i.comments.length > 0);
  // const practiceComments = item.practiceItems?.some((i) => i.comments !== undefined && i.comments.length > 0);

  // TODO: Better handling of comments
  // const hasComments = theoryComments || practiceComments || false;

  // Approve all learningObjectives in this item
  async function handleApproveAll(checked: boolean, userId?: string) {
    if (!state) throw new Error("No education card state found");

    console.log("Approving", item.title);
    setStatus("pending");

    // collect all children
    let theoreticalRefKeys = item.theoryItems?.map((i) => i.referenceKey) || [];
    let educationCard = await setApproved(
      state.eventId,
      item.rootKey,
      item.parentKey,
      theoreticalRefKeys,
      userId ? [userId] : state.userIds, // if userId is set, only approve for that user
      state.userIds,
      "theoretical",
      checked,
      item.learningObjectives,
      state.identifier
    );
    let updatedItem = educationCard.activities[0];

    let practicalRefKeys = item.practiceItems?.map((i) => i.referenceKey) || [];
    educationCard = await setApproved(
      state.eventId,
      item.rootKey,
      item.parentKey,
      practicalRefKeys,
      userId ? [userId] : state.userIds, // if userId is set, only approve for that user
      state.userIds,
      "practical",
      checked,
      item.learningObjectives,
      state.identifier
    );
    updatedItem = educationCard.activities[0];

    // TODO: handle errors...

    upsertListItem(updatedItem);
    setProgress(educationCard.progress);

    setStatus("none");
    console.log(item.title, "is", checked ? "approved" : "not approved");
  }

  // Approve a single learningObjective
  const handleApproveItem = async (
    item: EducationCardActivity,
    type: "theoretical" | "practical",
    checked: boolean,
    userId?: string
  ) => {
    if (!state) throw new Error("No education card state found");

    console.log("Approving", item.title);
    setStatus("pending");

    const educationCard = await setApproved(
      state.eventId,
      item.rootKey,
      item.parentKey,
      [item.referenceKey],
      userId ? [userId] : state.userIds, // if userId is set, only approve for that user
      state.userIds,
      type,
      checked,
      item.learningObjectives,
      state.identifier
    );
    const updatedItem = educationCard.activities[0];

    // TODO: handle errors...

    console.log("Updated item", updatedItem);

    upsertListItem(updatedItem);
    setProgress(educationCard.progress);

    setStatus("none");
    console.log(item.title, "is", checked ? "approved" : "not approved");
  };

  return (
    <AccordionEducationCard
      id={item.id}
      status={status}
      summary={
        <ItemContainer>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="subtitle2">{item.title}</Typography>
            {/* {isEducator && <ButtonPdf pdf={itemPdf} />} */}
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Status status={item.status} />
            {isEducator && <FormSwitch checked={item.approved} onChange={handleApproveAll} />}

            {state.type === "education" && (
              <Students item={item} onApprove={handleApproveItem} onApproveAll={handleApproveAll} />
            )}
            {state.type !== "user" && <AddComment comments={item.comments} title={item.title} />}
          </Stack>
        </ItemContainer>
      }
      details={
        <Stack direction={"column"} spacing={4}>
          <LearningObjectives type="theoretical" items={item.theoryItems} tempProp={state.type}>
            {item.theoryItems?.map((subItem) => (
              <LearningObjective
                key={subItem.id}
                item={subItem}
                type={"theoretical"}
                onApprove={handleApproveItem}
                onApproveAll={handleApproveAll}
              />
            ))}
          </LearningObjectives>
          <LearningObjectives type="practical" items={item.practiceItems} tempProp={state.type}>
            {item.practiceItems?.map((subItem) => (
              <LearningObjective
                key={subItem.id}
                item={subItem}
                type={"practical"}
                onApprove={handleApproveItem}
                onApproveAll={handleApproveAll}
              />
            ))}
          </LearningObjectives>
        </Stack>
      }
    />
  );
};

export default Item;
