import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Stack } from "@mui/material";
import { Title } from "utils/helpers/TitleDivider";
import Educations from "./Educations";
import Educators from "./Educators";
import { groupSelector } from "state/educatorState";
import { headerCrumbState } from "state/recoilAtoms";
import { useAppIntl } from "services/useAppIntl";
import useBackNavigation from "hooks/useBackNavigation";

// Set the breadcrumb for the EducatorHeader
export const useSetCrumb = () => {
  const { groupId } = useParams();
  const intl = useAppIntl();
  const group = useRecoilValue(groupSelector(groupId));
  const setCrumb = useSetRecoilState(headerCrumbState);

  useEffect(() => {
    setCrumb({ title: group?.name ?? "", topTitle: intl.formatMessage({ id: "common.title.educator" }) });
  }, [group?.name, intl, setCrumb]);
};

const Group = () => {
  const { groupId } = useParams();
  const group = useRecoilValue(groupSelector(groupId));
  useBackNavigation("/");
  useSetCrumb();

  return (
    <>
      <Title title={group?.name} variant="h1" />

      <Stack spacing={3}>
        <Educators />
        <Educations />
      </Stack>
    </>
  );
};

export default Group;
