import React, { ReactElement } from "react";
import { Backdrop, Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import { loadingState, loadingStateText } from "state/recoilAtoms";
import { useRecoilValue } from "recoil";
import FormattedMessage from "./FormattedMessage";

export const LoadingIndicator: React.FC<{
  size?: number;
  color?: CircularProgressProps["color"];
  children?: ReactElement;
}> = ({ children, size = 80, color = "primary" }) => {
  return (
    <Box textAlign="center">
      <CircularProgress size={size} color="success" />
      {children && (
        <Typography marginTop={2} component="div" variant="subtitle1" color="primary.light04">
          {children}
        </Typography>
      )}
    </Box>
  );
};

export const PageLoader: React.FC = () => {
  let isLoading = useRecoilValue(loadingState);
  let loadingText = useRecoilValue(loadingStateText);

  if (isLoading)
    return (
      <Backdrop sx={{ backgroundColor: "rgba(255,255,255,0.35)", zIndex: 2 }} open>
        <LoadingIndicator>
          {loadingText ? <Typography>{loadingText}</Typography> : <FormattedMessage id="common.title.loading" />}
        </LoadingIndicator>
      </Backdrop>
    );

  return null;
};

/**
 * Renders a loader overlay that displays a loading indicator when `isLoading` is true. NOTE! It needs a parent element with a relative position, since it will be positioned absolutely.
 * @param isLoading - A boolean value indicating whether the content is currently loading.
 * @param loadingText - An optional string to display as the loading text.
 * @returns The content loader component.
 */
export const ContentLoader = ({ isLoading, loadingText }: { isLoading: boolean; loadingText?: string }) => {
  if (isLoading)
    return (
      <Backdrop
        sx={{
          backgroundColor: "rgba(255,255,255,0.35)",
          zIndex: 2000,
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
        open
      >
        <LoadingIndicator>
          {loadingText ? <Typography>{loadingText}</Typography> : <FormattedMessage id="common.title.loading" />}
        </LoadingIndicator>
      </Backdrop>
    );

  return null;
};

export const SuspenseLoader: React.FC = () => {
  return (
    <Backdrop sx={{ backgroundColor: "rgba(255,255,255,0.35)", zIndex: 2 }} open>
      <LoadingIndicator>
        <FormattedMessage id="common.title.loading" />
      </LoadingIndicator>
    </Backdrop>
  );
};
