import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { EducationCardType } from "hooks/useEducationCardSettings";
import { DefaultValue, atom, atomFamily, selectorFamily } from "recoil";
import { EducationCardActivity } from "types/educationCard";

type EducationCardProps = {
  identifier: string;
  type: EducationCardType;
  eventId: string;
  referenceKey: ReferenceKey;
  userIds: string[];
  name: string;
};

export const educationCardPropsState = atom<EducationCardProps | undefined>({
  key: "educationCardState",
  default: undefined,
});

export const educationCardIdsState = atom<string[]>({
  key: "educationCardIdsState",
  default: [],
});

export const educationCardItemState = atomFamily<EducationCardActivity, string>({
  key: "educationCardItemState",
  default: (id: string) => ({
    id,
    referenceKey: { contentId: "", versionId: "" },
    parentKey: { contentId: "", versionId: "" },
    rootKey: { contentId: "", versionId: "" },
    title: "",
    status: "None",
    approved: false,
    theoryItems: undefined,
    practiceItems: undefined,
    comments: undefined,
    learningObjectives: undefined,
  }),
});

export const educationCardItemSelector = selectorFamily<EducationCardActivity, string>({
  key: "educationCardItemSelector",
  get:
    (id) =>
    ({ get }) =>
      get(educationCardItemState(id)),
  set:
    (id) =>
    ({ get, set, reset }, newVal) => {
      if (newVal instanceof DefaultValue) {
        reset(educationCardItemState(id));
        return;
      }

      set(educationCardItemState(id), newVal);

      if (get(educationCardIdsState).find((id) => id === newVal.id)) return;

      set(educationCardIdsState, (prev) => [...prev, newVal.id]);
    },
});
