import { Stack, StackProps } from "@mui/material";

const ItemContainer = (props: { children: React.ReactNode } & StackProps) => {
  const { children, ...rest } = props;
  return (
    <Stack direction={"row"} alignItems="center" justifyContent="space-between" width="100%" {...rest}>
      {children}
    </Stack>
  );
};

export default ItemContainer;
