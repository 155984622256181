import { svSE, enUS, svSEFm } from "./languages";

export type SupportedLanguages = "sv" | "en" | "sv:2f9a2977-a68a-4a6c-be21-10b29a327887";
export const messages = {
  sv: svSE,
  en: enUS,
  "sv:2f9a2977-a68a-4a6c-be21-10b29a327887": svSEFm,
};

type Videos = {
  [key in SupportedLanguages]: {
    [key in VideoIds]: string;
  };
};

const videoUrl = "https://player.vimeo.com/video";
export type VideoIds = "getStarted" | "testHowTo";
export const videos: Videos = {
  sv: {
    getStarted: `${videoUrl}/867101607?badge=0&autopause=0&player_id=0&app_id=58479`,
    testHowTo: `${videoUrl}/913645075?badge=0&autopause=0&player_id=0&app_id=58479`,
  },
  // TODO: Add English videos when we get them
  en: {
    getStarted: `${videoUrl}/922822538?badge=0&autopause=0&player_id=0&app_id=58479`,
    testHowTo: `${videoUrl}/922825174?badge=0&autopause=0&player_id=0&app_id=58479`,
  },

  "sv:2f9a2977-a68a-4a6c-be21-10b29a327887": {
    getStarted: `${videoUrl}/867101607?badge=0&autopause=0&player_id=0&app_id=58479`,
    testHowTo: `${videoUrl}/913645075?badge=0&autopause=0&player_id=0&app_id=58479`,
  },
};

// Type guard for supported languages (bummer to have to duplicate the literal into an array)
export function isLanguageSupported(language: string): language is SupportedLanguages {
  return ["sv", "en", "sv:2f9a2977-a68a-4a6c-be21-10b29a327887"].includes(language);
}
