import { useRecoilValue } from "recoil";
import { userStateAccount } from "state/userState";
import { MemberRole } from "types/user-account";

/**
 *
 * @returns User roles and if user is educator or student
 */
const useRoles = () => {
  const userAccount = useRecoilValue(userStateAccount);

  if (!userAccount) return { roles: [], isEducator: false, isStudent: false };

  const roles = userAccount.roles;
  const isEducator = roles.includes(MemberRole.Educator);
  const isStudent = roles.includes(MemberRole.Student);

  return { roles, isEducator, isStudent };
};

export default useRoles;
