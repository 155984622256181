import React from "react";
import { Button, Stack, Box } from "@mui/material";
import { Icon, iconLogout, iconArrowRightRegular, iconMessageQuestion } from "theme/icons";
import ReactGA from "react-ga4";
import shadows from "@mui/material/styles/shadows";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useLogout } from "hooks/useLogout";

type Props = {
  desktopMenu?: boolean;
};
const analytics = (path: string) => {
  ReactGA.event({
    category: "more",
    action: path,
  });
};

export const More: React.FC<Props> = ({ desktopMenu }: Props) => {
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const { logout } = useLogout();
  const menuWidth = "430px";

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Box
      sx={
        desktopMenu
          ? {
              position: "fixed",
              top: "64px",
              marginLeft: `calc(100vw / 2 - ${menuWidth} / 2 + 24px)`,
              marginRight: "auto",
              width: menuWidth,
              borderRadius: 2,
              background: "white",
              padding: 2,
              color: "black",
              boxShadow: shadows[4],
              maxHeight: "82%",
              overflowY: "auto",
            }
          : null
      }
    >
      <Stack spacing={desktopMenu ? 2 : 4}>
        <Stack spacing={1.5}>
          {/* <NavigationItem
            text={intl.formatMessage({ id: "more.title.education" })}
            icon={iconBook}
            iconSize="medium"
            href={routes.education.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.education}
            onClick={() => analytics(routes.education.path)}
          /> */}
          {/* <NavigationItem
            text={intl.formatMessage({ id: "more.title.profile" })}
            icon={iconUser}
            iconSize="medium"
            href={routes.profile.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.profile}
            onClick={() => analytics(routes.profile.path)}
          /> */}
          {/* <NavigationItem
            text={intl.formatMessage({ id: "more.title.checklist" })}
            icon={iconCheckboxSolid}
            iconSize="medium"
            href="/more/checklist"
            navigationIcon={iconArrowRightRegular}
            /> */}
          {/* <NavigationItem
            text={intl.formatMessage({ id: "more.title.glossary" })}
            icon={iconList}
            iconSize="medium"
            href={routes.glossary.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.glossary}
          /> */}
          {/* {useBookmarks && (
            <NavigationItem
              text={intl.formatMessage({ id: "more.title.bookmarksandnotes" })}
              icon={iconBookmark}
              iconSize="medium"
              href={routes.bookmarks.path}
              navigationIcon={iconArrowRightRegular}
              dataTestIdString={testId.listItem.more.bookmarks}
            />
          )} */}
          {/* <NavigationItem
            text={intl.formatMessage({ id: "common.roadsigns" })}
            icon={iconTriangle}
            iconSize="medium"
            href={routes.roadsigns.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.roadSigns}
            onClick={() => analytics(routes.roadsigns.path)}
          /> */}
          {/* <NavigationItem
            text={intl.formatMessage({ id: "more.title.getstarted" })}
            icon={iconCircleInfoSolid}
            iconSize="medium"
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.getstarted}
            href={routes.getstarted.path}
          /> */}
          <NavigationItem
            text={intl.formatMessage({ id: "more.title.support" })}
            icon={iconMessageQuestion}
            iconSize="medium"
            href={routes.support.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.support}
            onClick={() => analytics(routes.support.path)}
          />
          {/* {useLanguage && (
            <NavigationItem
              text={intl.formatMessage({ id: "more.title.language" })}
              icon={iconSolidGlobe}
              iconSize="medium"
              href={routes.language.path}
              navigationIcon={iconArrowRightRegular}
              // dataTestIdString={testId.listItem.more.settings}
            />
          )} */}
        </Stack>

        {/* <SoundSupport /> */}

        <Button
          variant="contained"
          onClick={handleLogoutClick}
          data-testid={`${testId.listItem.listItem}-${testId.listItem.more.logout}`}
        >
          <Icon name={iconLogout} sx={{ marginRight: "0.5rem" }} />
          <FormattedMessage id="common.logout" />
        </Button>
      </Stack>
    </Box>
  );
};
