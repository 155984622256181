import React, { useEffect } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import {
  Icon,
  iconTheory,
  iconTheoryActive,
  iconHome,
  iconHomeActive,
  iconTest,
  iconTestActive,
  iconMoreCircle,
  iconMoreActive,
  iconProgress,
  iconProgressActive,
} from "theme/icons";
import { Link, useLocation } from "react-router-dom";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";

export const MainNavigation = () => {
  const location = useLocation();
  const [value, setValue] = React.useState("");
  const intl = useAppIntl();
  const routes = useRouteConfiguration();

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  return (
    <BottomNavigation
      aria-label="main navigation"
      showLabels
      value={value}
      onChange={(e, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction
        label={intl.formatMessage({ id: "common.title.home" })}
        icon={<Icon name={value === routes.home.path ? iconHomeActive : iconHome} />}
        component={Link}
        to={routes.home.path}
        value={routes.home.path}
        data-testid={testId.menu.bottomNavigation.home}
      />
      <BottomNavigationAction
        label={intl.formatMessage({ id: "common.title.theory" })}
        icon={<Icon name={value === routes.theory.path ? iconTheoryActive : iconTheory} />}
        component={Link}
        to={routes.theory.path}
        value={routes.theory.path}
        data-testid={testId.menu.bottomNavigation.theory}
      />
      <BottomNavigationAction
        label={intl.formatMessage({ id: "common.title.test" })}
        icon={<Icon name={value === routes.test.path ? iconTestActive : iconTest} />}
        component={Link}
        to={routes.test.path}
        value={routes.test.path}
        data-testid={testId.menu.bottomNavigation.test}
      />
      <BottomNavigationAction
        label={intl.formatMessage({ id: "common.title.progress" })}
        icon={<Icon name={value === routes.progress.path ? iconProgressActive : iconProgress} />}
        component={Link}
        to={routes.progress.path}
        value={routes.progress.path}
        data-testid={testId.menu.bottomNavigation.progress}
      />
      <BottomNavigationAction
        label={intl.formatMessage({ id: "common.title.more" })}
        icon={<Icon name={value === routes.more.path ? iconMoreActive : iconMoreCircle} />}
        component={Link}
        to={routes.more.path}
        value={routes.more.path}
        data-testid={testId.menu.bottomNavigation.more}
      />
    </BottomNavigation>
  );
};
