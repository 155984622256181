import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { useAppContext } from "context/AppContext";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

const SoundSupport = () => {
  const { soundSupport, setSoundSupport } = useAppContext();

  const handleChangeSoundSupport = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSoundSupport(checked);
  };

  return (
    <FormGroup sx={{ mt: 4 }}>
      <FormControlLabel
        name="soundSupportSwitch"
        control={<Switch onChange={handleChangeSoundSupport} checked={soundSupport} />}
        label={<FormattedTypography variant="body2" fontWeight={600} id="common.title.sound-support" />}
        sx={{ mr: "auto" }}
      />
    </FormGroup>
  );
};
export default SoundSupport;
