import ItemContainer from "layout/educationcard/ItemContainer";
import Student from "../student/Student";
import StudentAdd from "../student/AddButton";
import ListHeader from "layout/Components/ListHeader";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { educationsSelector, studentsSelector } from "state/educatorState";
import { useSetCrumb } from ".";
import useBackNavigation from "hooks/useBackNavigation";

const GroupStudents = () => {
  const { groupId } = useParams();
  const educations = useRecoilValue(educationsSelector(groupId));
  const students = useRecoilValue(studentsSelector(groupId));
  useBackNavigation("/");
  useSetCrumb();

  if (!educations) return null;

  return (
    <>
      <StudentAdd />

      <ItemContainer mb={2} mt={4} pl={6} pr={18}>
        <ListHeader>
          <FormattedTypography id="common.label.name" variant="captionImage" fontWeight={700} />
        </ListHeader>
        <ListHeader>
          <FormattedTypography id="common.label.identity-number" variant="captionImage" fontWeight={700} />
        </ListHeader>
      </ItemContainer>

      {students?.map((student) => (
        <Student key={student.id} student={student} educations={educations} />
      ))}
    </>
  );
};

export default GroupStudents;
