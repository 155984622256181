import { useMsal } from "@azure/msal-react";
import { msalConfig } from "authConfig";

/**
 *
 * @returns Logout from application and clear local- and session storage
 */
export const useLogout = () => {
  const { instance } = useMsal();

  const logout = (reason?: "forgotPassword") => {
    sessionStorage.clear();
    instance.logoutRedirect({
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri + (reason !== "forgotPassword" ? "#logout" : ""),
    });
  };

  return { logout };
};
