import React from "react";
import { Grid } from "@mui/material";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { iconBookmark } from "theme/icons";
import { Title } from "utils/helpers/TitleDivider";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import { TheoryAreas } from "./areas";
import { testId } from "tests/testIdStrings";
import { AreasSkeleton } from "utils/helpers/LoadingSkeletons";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import ContinueTheory from "layout/Components/ContinueTheory";
import SoundSupport from "layout/Components/SoundSupport";
import NavigationItemStudyHelp from "layout/Components/NavigationItemStudyHelp";

export const Theory = () => {
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const useBookmarks = useFeatureFlag("Bookmarks");

  return (
    <>
      <Title title={intl.formatMessage({ id: "common.title.theory" })} />
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} sm={6}>
          <ContinueTheory />
        </Grid>
        <Grid item xs={12} sm={6}>
          {useBookmarks && (
            <NavigationItem
              href={routes.bookmarks.path}
              text={intl.formatMessage({ id: "more.title.bookmarksandnotes" })}
              icon={iconBookmark}
              dataTestIdString={testId.listItem.theory.bookmark}
              height={"100%"}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <NavigationItemStudyHelp />
        </Grid>
      </Grid>

      <Title title={intl.formatMessage({ id: "common.title.education.parts" })} variant="h2" sx={{ mt: 5 }} />
      <React.Suspense fallback={<AreasSkeleton />}>
        <TheoryAreas />
      </React.Suspense>

      <SoundSupport />
    </>
  );
};
