import React, { useEffect, useRef } from "react";
import { styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentCenter } from "theme/styles";
import { alpha, Box, CircularProgress, Typography, useTheme } from "@mui/material";

const styleAbsoluteCenter = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
};
interface ICircularProgressIndicatorProps {
  value: number;
  color?: string;
  size?: string;
  text?: boolean;
  thickness?: number;
}
export const CircularProgressIndicator: React.FC<ICircularProgressIndicatorProps> = (props) => {
  const theme = useTheme();
  const indicatorHeight = props.size ?? "4.375rem";

  return (
    <Box
      sx={[
        styleFlex,
        styleFlexJustifyContentCenter,
        styleFlexAlignItemsCenter,
        { position: "relative", height: indicatorHeight },
      ]}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        thickness={props.thickness ?? 4.5}
        size={indicatorHeight}
        sx={{ position: "absolute", color: props.color ? alpha(props.color, 0.3) : theme.palette.primary.light }}
      />
      <CircularProgress
        variant="determinate"
        value={props.value}
        thickness={props.thickness ?? 4.5}
        size={indicatorHeight}
        sx={{ position: "absolute", color: props.color ? props.color : theme.palette.primary.main }}
      />
      {props.text && (
        <Typography
          variant={"subtitle1"}
          component="div"
          color="text.secondary"
          sx={[styleAbsoluteCenter, styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentCenter]}
        >
          {props.value}%
        </Typography>
      )}
    </Box>
  );
};

export const CircularProgressIncremental = (props: ICircularProgressIndicatorProps) => {
  const [progress, setProgress] = React.useState(0);
  const timerRef = useRef<NodeJS.Timeout>();
  const progressRef = useRef(0);

  useEffect(() => {
    // cache the current state value
    progressRef.current = progress;
  }, [progress]);

  useEffect(() => {
    timerRef.current = setInterval(update, 35);

    return () => {
      clearInterval(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const update = () => {
    if (progressRef.current < props.value) {
      setProgress((prevProgress) => prevProgress + 1);
    } else {
      clearInterval(timerRef.current);
    }
  };

  return <CircularProgressIndicator value={progress} color={props.color} />;
};
