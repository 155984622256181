import { useAppContext } from "context/AppContext";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLanguageSupported, SupportedLanguages } from "services/localizationService";
import { userEducationsState, educationState } from "state/educationState";

/**
 * Hook for getting/setting the current selected education. If no education is set it will default to the first one.
 * @returns userEducations, currentEducationIdentifier, setCurrentEducation()
 */
export const useEducation = () => {
  const userEducations = useRecoilValue(userEducationsState);
  const [education, setEducation] = useRecoilState(educationState);
  const { setActiveLanguage } = useAppContext();

  useEffect(() => {
    if (!userEducations) return;
    if (!userEducations.length) return;

    // Re-set current (if refreshed from elsewhere) or set first as default
    const active = education?.educationId
      ? userEducations.find((e) => e.educationId === education.educationId)
      : userEducations[0];

    // Set language to a combination of education language and group term
    const language = active?.language || "sv";
    let languageTerm = active?.term ? `${language}:${active.term}` : "sv";
    if (!isLanguageSupported(languageTerm)) {
      console.warn("language not supported", languageTerm, "defaulting to sv");
      languageTerm = "sv";
    }

    setActiveLanguage(languageTerm as SupportedLanguages);
    setEducation(active);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEducations, education?.educationId]);

  return { userEducations, education, setEducation };
};
