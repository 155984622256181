import moment from "moment";
import "moment/locale/sv";
import { useAppIntl } from "../services/useAppIntl";

export const useDateService = () => {
  const intl = useAppIntl();
  moment.locale(intl.locale);

  const getDateWithLocalTime = (date: string) => {
    let localTime = moment.utc(date).local(false);
    let output = "kl. " + localTime.format("HH:mm DD MMMM yyyy");
    return output;
  };

  const getDateAsLocalDateString = (date: Date) => {
    const localDate = moment.utc(date).local(false);
    const isWithin24Hours = localDate.isBetween(moment().add(-24, "hours"), moment().add(24, "hours"));
    if (isWithin24Hours) {
      const formattedDateTime = intl.formatTime(localDate.format(), {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      return formattedDateTime;
    }
    return intl.formatDate(date, { dateStyle: "long" });
  };

  const getLocalDate = (date: Date | string, format?: string) => {
    const d = typeof date === "string" ? new Date(date) : date;

    const localDate = moment.utc(d).local(false);
    return localDate.format(format ?? "YYYY-MM-DD");
  };

  const getDuration = (milliseconds: number) => {
    const duration = moment.utc(milliseconds).format("mm:ss");

    return duration;
  };

  return {
    /**
     * Convert a Date in UTC to local time and return a string on the format "kl. HH:mm DD MMMM yyyy"
     * @param date
     * @returns formatTime or formatDate as a string
     */
    getDateWithLocalTime,
    /**
     * Convert a Date (or Date string) in UTC to string in local time
     * @param date
     * @returns formattedDate as a string
     */

    getLocalDate,
    /**
     * Converts duration in milliseconds to a string as mm:ss
     * @param milliseconds
     * @returns a string as mm:ss
     */
    getDuration,

    getDateAsLocalDateString,
  };
};
