export type User = {
  id?: string;
  userName?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  identityNumber?: string;
  address?: Address;
  setting?: Setting;
  appRoles?: AppRole[];
  communications?: Communication[];
  products?: Product[];

  customerId?: string;
  organizationUnitId?: string;
  learningEventId?: string;
};

export type Address = {
  street1?: string;
  street2?: string;
  postalCode?: string;
  city?: string;
};

export type Setting = {
  name?: string;
  type?: string;
  value?: string;
};

export type AppRole = {
  platform: Platform;
  roles?: MemberRole[];
};

export type Communication = {
  identifier?: string;
  targetIdentifier: string;
  type: CommunicationType;
  isPrimary?: boolean;
  isPreferred?: boolean;
};

export enum CommunicationType {
  None = "None", // 0
  PhoneNumber = "PhoneNumber", // 1
  Email = "Email", // 2
}

export type Product = {
  id: string;
  customerId: string;
  productId: string;
  isEnabled: boolean;
  name: string;
};

export enum Platform {
  LearningClient_SC = "LearningClient_SC",
  LearningClient_FM = "LearningClient_FM",
  CustomerAdmin = "CustomerAdmin",
}

export enum MemberRole {
  None = "",
  CustomerAdmin = "CustomerAdmin",
  UnitAdmin = "UnitAdmin",
  Educator = "Educator",
  Student = "Student",
}
