import { useState } from "react";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import { iconAward, iconLock, iconLockOpen, iconTestActive, iconShuffle, iconClock, iconSliders } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import { FinalTestDialog } from "layout/test/final/finalTestDialog";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useNavigate } from "react-router-dom";
import { styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentBetween } from "theme/styles";
import { InfoDialog } from "./infoDialog";
import { InfoFinalTestDialog } from "./infoFinalTestDialog";
import { testId } from "tests/testIdStrings";
import { useFinalTestStatus } from "hooks/useFinalTestStatus";
import SoundSupport from "layout/Components/SoundSupport";
import NavigationItemStudyHelp from "layout/Components/NavigationItemStudyHelp";

export const Test = () => {
  const intl = useAppIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const routes = useRouteConfiguration();
  const { finalTestUnlocked } = useFinalTestStatus();
  const [openFinalTestDialog, setOpenFinalTestDialog] = useState(false);
  const [openTestInfoDialog, setOpenTestInfoDialog] = useState(false);
  const [openFinalTestInfoDialog, setOpenFinalTestInfoDialog] = useState(false);
  const handleCreateFinalTest = () => {
    if (!finalTestUnlocked) return;

    navigate(routes.test.routes!.final.path);
  };
  return (
    <>
      <Stack spacing={4}>
        <Box sx={[styleFlex, styleFlexJustifyContentBetween, styleFlexAlignItemsCenter]} mt={-1}>
          <FormattedTypography id="common.title.test" variant="h1" />
        </Box>

        <Stack spacing={2}>
          <FormattedTypography id="common.title.study" variant="h2" />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <NavigationItem
                  size="auto"
                  color={theme.palette.info.main}
                  text={intl.formatMessage({ id: "test.type.study.quick" })}
                  subText={intl.formatMessage({ id: "test.type.study.quick.subtext" }, { values: { value: 10 } })}
                  subTextVariant="caption"
                  icon={iconClock}
                  iconSize="medium"
                  href={"quick/study"}
                  dataTestIdString={testId.listItem.test.type.quick}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NavigationItem
                  size="auto"
                  color={theme.palette.info.main}
                  text={intl.formatMessage({ id: "common.title.test.quick2" })}
                  subText={intl.formatMessage({ id: "test.type.study.quick2.subtext" }, { values: { value: 10 } })}
                  subTextVariant="caption"
                  icon={iconShuffle}
                  iconSize="medium"
                  href={"quick/study2"}
                  dataTestIdString={testId.listItem.test.type.quick}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <NavigationItem
                  size="auto"
                  color={theme.palette.info.main}
                  text={intl.formatMessage({ id: "test.type.roadsign.quick" })}
                  subText={intl.formatMessage({ id: "test.type.study.quick.subtext" }, { values: { value: 20 } })}
                  subTextVariant="caption"
                  icon={iconTriangle}
                  iconSize="medium"
                  href={"quick/roadsigns"}
                  dataTestIdString={testId.listItem.test.type.roadsign}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <NavigationItem
                  size="auto"
                  color={theme.palette.info.main}
                  text={intl.formatMessage({ id: "test.create.custom" })}
                  subText={intl.formatMessage({ id: "test.create.custom.subtext" })}
                  subTextVariant="caption"
                  icon={iconSliders}
                  iconSize="medium"
                  href={routes.test.routes?.custom.path}
                  dataTestIdString={testId.listItem.test.type.custom}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Box sx={[styleFlex, styleFlexJustifyContentBetween, styleFlexAlignItemsCenter]} mt={-1}>
            <FormattedTypography id="common.title.test.final" variant="h2" />
            {/* <IconButton onClick={() => setOpenFinalTestInfoDialog(true)}>
              <Icon name={iconCircleInfoSolid} htmlColor={theme.palette.info.main} />
            </IconButton> */}
          </Box>

          <NavigationItem
            disabled={!finalTestUnlocked}
            size="auto"
            color={theme.palette.info.main}
            text={intl.formatMessage({ id: "test.start.final" })}
            // subText={<FormattedTypography id="test.start.final.subtext" variant="breadcrumbs" />}
            icon={iconAward}
            iconSize="medium"
            navigationIcon={finalTestUnlocked ? iconLockOpen : iconLock}
            onClick={handleCreateFinalTest}
            dataTestIdString={testId.listItem.test.type.final}
          />
        </Stack>

        <Stack spacing={2}>
          <FormattedTypography id="common.result" variant="h2" />
          <NavigationItemStudyHelp />
          <NavigationItem
            size="auto"
            color={theme.palette.info.main}
            text={intl.formatMessage({ id: "test.history.all" })}
            icon={iconTestActive}
            iconSize="medium"
            href={routes.progress.path + "/" + routes.progress.routes?.results.path}
            dataTestIdString={testId.listItem.test.common.historyAll}
          />
        </Stack>

        <SoundSupport />
      </Stack>

      <FinalTestDialog open={openFinalTestDialog} close={() => setOpenFinalTestDialog(false)} />
      <InfoDialog open={openTestInfoDialog} close={() => setOpenTestInfoDialog(false)} />
      <InfoFinalTestDialog open={openFinalTestInfoDialog} close={() => setOpenFinalTestInfoDialog(false)} />
    </>
  );
};
