import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { ReactNode } from "react";

type Props = TextFieldProps & {
  startIcon?: ReactNode;
  endIcon?: any;
};
const SearchField = ({ ...props }: Props) => {
  const { startIcon, endIcon, ...restProps } = props;

  return (
    <TextField
      variant="outlined"
      color="primary"
      fullWidth
      InputProps={{
        startAdornment: startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : null,
        endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
      }}
      inputProps={{ maxLength: 11 }}
      {...restProps}
    />
  );
};

export default SearchField;
