import { Avatar, Button, List, Stack, Typography } from "@mui/material";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { Suspense, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { XapiKey } from "services/lrsService";
import { testId } from "tests/testIdStrings";
import { Icon, iconCircleCheck, iconCircleExclamation, iconCircleXmarkSolid } from "theme/icons";
import { styleQuestionNumber } from "theme/styles";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { ListItemButton } from "utils/helpers/ListItemButton";
import { IOverviewQuestion, OverviewQuestionStatus } from "utils/helpers/theory/QuestionOverview";
import { Overview } from "./overview";
import { useRecoilValue } from "recoil";
import { studyHelpKeysState, testResultState } from "state/progresState";
import useMediaQueries from "hooks/useMediaQueries";
import { TestResultSkeleton } from "utils/helpers/LoadingSkeletons";
import { useProgress } from "hooks/useProgress";
import { userStateAccount } from "state/userState";

export type NavigateTestState = {
  hideResult?: boolean;
  redirectTo?: string;
  startAssessment?: boolean;
  resetTest?: boolean;
  question?: {
    number: number;
    id: ReferenceKey;
  };
};

export interface IResultOverview {
  xapiKey?: XapiKey;
  isAnswer?: boolean;
  assessmentId?: string;
}

const PageSkeleton = (props: IResultOverview) => {
  return (
    <Suspense fallback={<TestResultSkeleton />}>
      <Page {...props} />
    </Suspense>
  );
};

export default PageSkeleton;

// Page uses props when called from theory Answer test (since it should be shown in that context), otherwise it uses useParams
const Page = ({ xapiKey, assessmentId }: IResultOverview) => {
  const user = useRecoilValue(userStateAccount);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { isMobile } = useMediaQueries();
  const [chapterOverview, setChapterOverview] = useState(false);
  const isAnswer = assessmentId !== undefined;

  // TODO: Hämta frågor från service istället för recoil state?
  const testResult = useRecoilValue(testResultState(assessmentId ?? id));
  const questions = testResult?.questions;
  // Preload study help keys
  useRecoilValue(studyHelpKeysState({ userId: user!.actorId, testId: assessmentId ?? id! }));

  // Trigger progress update
  useProgress();

  const handleClose = () => {
    if (isAnswer) return setChapterOverview(true);

    // TODO: Hantera detta bättre...
    const to = location.pathname.includes("test/result") ? "../.." : "..";
    navigate(to, { relative: "path" });
  };

  const handleMoreQuestions = async () => {
    const navigateState: NavigateTestState = { redirectTo: location.pathname };
    navigate("/resetTest", { state: navigateState });
  };

  const handleOpenQuestion = (number: number, id: ReferenceKey) => {
    if (isAnswer) {
      const navigateState: NavigateTestState = { question: { id, number }, hideResult: true };
      navigate(location.pathname, { state: navigateState });
      return;
    }

    navigate("question/" + id.contentId);
  };

  if (!questions) return null;
  if (chapterOverview) return <Overview xapiKey={xapiKey!} />;

  return (
    <>
      <FormattedTypography variant="h1" id={"common.result-overview"} mb={2} />

      <Typography variant="subtitle1" mb={2}>
        {questions.filter((question) => question.status === OverviewQuestionStatus.Correct).length}/{questions.length}
      </Typography>

      <List component={"nav"} sx={{ mx: -2, pb: "3rem" }}>
        {questions?.map((item, index) => (
          <QuestionButton key={index} onClick={handleOpenQuestion} {...item} />
        ))}
      </List>

      <Stack
        spacing={2}
        sx={{ position: isMobile ? "fixed" : "relative", bottom: 0, left: 0, right: 0, backgroundColor: "white", p: 2 }}
      >
        <Button
          variant="contained"
          fullWidth
          sx={{ px: 2 }}
          data-testid={`${testId.button.testPrefix}.${testId.common.close}`}
          onClick={handleClose}
        >
          <FormattedMessage id="common.close" />
        </Button>

        {isAnswer && (
          <Button
            variant="outlined"
            fullWidth
            sx={{ px: 2 }}
            data-testid={`${testId.button.testPrefix}.${testId.button.moreQuestions}`}
            onClick={handleMoreQuestions}
          >
            <FormattedMessage id="question.more-questions" />
          </Button>
        )}
      </Stack>

      {/* For test result question dialog */}
      <Outlet />
    </>
  );
};

type QuestionButtonProps = {
  number: number;
  text: string;
  id?: ReferenceKey;
  status: OverviewQuestionStatus;
  onClick: (number: number, id: ReferenceKey) => void;
};
const QuestionButton = ({ number, text, id, status, onClick }: QuestionButtonProps) => {
  let endIcon;
  if (status === OverviewQuestionStatus.None) endIcon = <Icon name={iconCircleExclamation} color={"disabled"} />;
  if (status === OverviewQuestionStatus.Correct) endIcon = <Icon name={iconCircleCheck} color={"success"} />;
  if (status === OverviewQuestionStatus.Incorrect) endIcon = <Icon name={iconCircleXmarkSolid} color={"error"} />;

  return (
    <ListItemButton
      startIcon={<Avatar sx={styleQuestionNumber}>{number}</Avatar>}
      primaryText={text}
      onClick={() => onClick(number, id!)}
      endIcon={endIcon}
      dataTestIdString={`${testId.listItem.question.item}.${number}`}
    />
  );
};

// TEMP component to easily restart a test. TODO a better solution when we get the time.
export const ResetTest = () => {
  const location = useLocation();

  if (!location.state.redirectTo) throw new Error("No redirect url to navigate to");

  const navigateState: NavigateTestState = { startAssessment: true, resetTest: true };
  return <Navigate replace to={location.state.redirectTo} state={navigateState} />;
};

export function generateOverview(overviewQuestions: any) {
  let questions: IOverviewQuestion[] = [];
  let number = 1;

  overviewQuestions.forEach((overviewQuestion: any) => {
    let status: OverviewQuestionStatus = OverviewQuestionStatus.None;
    if (overviewQuestion.result) {
      if (overviewQuestion.result.response) status = OverviewQuestionStatus.Answered;

      if (overviewQuestion.result.completion)
        status = overviewQuestion.result.success ? OverviewQuestionStatus.Correct : OverviewQuestionStatus.Incorrect;
    }

    const question: IOverviewQuestion = {
      id: overviewQuestion.referenceKey,
      text: overviewQuestion.title,
      number: number,
      status: status,
    };
    questions.push(question);
    number++;
  });

  return questions;
}
