export const fontFamily = "open-sans, sans-serif";

export const optimaColors = {
  primary: "#1982C4",
  primaryLight: "#8CC1E1",
  primaryDark: "#115B89",

  secondary: "#159E82",
  secondaryLight: "#73C5B4",
  secondaryDark: "##0E6E5B",

  error: "#D84315",
  errorLight: "#EBA18A",
  errorDark: "#B33812",

  success: "#4FAC46",
  successLight: "#A7D5A2",
  successDark: "#11715E",

  // Ej definierade i Figma...
  info: "#476EB8", // ??,
  infoLight: "#6B8BC6", // ??,
  infoDark: "#314D80", // ??,

  warning: "#FFCA3A",
  warningLight: "#FFE59C",
  warningDark: "#E0A812",
};

export const fontSize = {
  h1: "24px",
  h2: "20px",
  h3: "20px",
  h4: "18px",
  body1: "18px",
  body2: "16px",
  bodyStrong: "20px",
  input: "14px",
  subtitle1: "16px",
  subtitle2: "14px",
  caption: "12px",
  button: "16px",
  breadcrumb: "10px",
  note: "14px",
  imageText: "12px",
};
