import { ReactNode, FC } from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import { HeaderNavigation } from "utils/helpers/HeaderNavigation";
import { useRecoilValue } from "recoil";
import { headerCrumbState } from "state/recoilAtoms";
import UserInfo from "layout/Components/UserInfo";
import MainMenu from "layout/Components/MainMenu";

export interface IHeaderProps {
  children?: ReactNode;
  callback?: () => void;
  tabs?: ReactNode;
}

export const Header: FC<IHeaderProps> = (props) => {
  const { title, topTitle } = useRecoilValue(headerCrumbState);

  return (
    <AppBar position="fixed" className="AppBar-top">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* <MainMenu /> */}
        <HeaderNavigation iconSize={"large"} topTitle={topTitle} title={title} onNavigate={props.callback} />

        <Box display="flex" justifyContent="flex-end" width="250px" alignItems="center" columnGap={1.5}>
          <UserInfo />
        </Box>
      </Toolbar>
      {props.tabs}
    </AppBar>
  );
};
