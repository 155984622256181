import React from "react";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { testId } from "tests/testIdStrings";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { Stack } from "@mui/material";
import { useAppIntl } from "services/useAppIntl";

type Props = {
  open: boolean;
  close: () => void;
};

export const InfoFinalTestDialog: React.FC<Props> = (props) => {
  const intl = useAppIntl();
  const { open, close } = props;

  const handleClose = () => {
    close();
  };

  return (
    <DialogMessage
      title={intl.formatMessage({ id: "test.start.final" })}
      open={open}
      close={handleClose}
      primaryButtonText="common.close"
      noCancelButton
      dataTestIdString={testId.common.close}
    >
      <Stack spacing={2}>
        <FormattedTypography
          id={"test.final.info.text"}
          values={{
            br: (
              <>
                <br />
                <br />
              </>
            ),
          }}
        />
      </Stack>
    </DialogMessage>
  );
};
