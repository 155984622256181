import { Accordion, AccordionDetails, AccordionSummary, useTheme } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { LoadingStatus } from "types/request";
import { Icon, iconChevronDown } from "theme/icons";

type AccordionEducationCardProps = {
  id: string;
  summary: NonNullable<ReactNode>;
  details?: ReactNode;
  status: LoadingStatus;
};

/**
 * AccordionEducationCard component renders an accordion with a summary and details.
 * It supports expanding and collapsing functionality, and prevents expanding when clicking on buttons or inputs.
 *
 * @param {string} id - The unique identifier for the accordion panel.
 * @param {React.ReactNode} summary - The summary content displayed in the accordion header.
 * @param {React.ReactNode} [details] - The detailed content displayed in the accordion body. If NOT provided, the accordion will not expand and act as a button instead.
 * @param {string} status - The status of the accordion, which can disable it if set to "pending".
 *
 * @returns {JSX.Element} The rendered Accordion component.
 */
const AccordionEducationCard = ({ id, summary, details, status }: AccordionEducationCardProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const target = event.target as HTMLElement;

    // Prevent expanding when clicking on buttons or inputs
    if (target.tagName === "INPUT" || target.tagName === "BUTTON") return;
    if (target.tagName === "svg" || target.tagName === "path") {
      if (target.parentElement?.tagName === "BUTTON") return;
      if (target.parentElement?.tagName === "svg") {
        if (target.parentElement?.parentElement?.tagName === "BUTTON") return;
        if (target.parentElement?.parentElement?.parentElement?.tagName === "BUTTON") return;
      }
    }

    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      disableGutters
      disabled={status === "pending"}
      onChange={handleExpand(`panel-${id}`)}
      expanded={expanded === `panel-${id}`}
      sx={{
        ".MuiAccordionSummary-root": { flexDirection: "row-reverse" }, // Switch expand icon to the right
        ".MuiAccordionSummary-expandIconWrapper": {
          mr: 1,
          ".MuiSvgIcon-root": !details ? { display: "none" } : null, // Hide expand icon if no details provided
        },
      }}
    >
      <AccordionSummary
        expandIcon={<Icon name={iconChevronDown} color="info" />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        {summary}
      </AccordionSummary>
      {details && (
        <AccordionDetails sx={{ borderTop: `1px solid ${theme.custom.borderColor}`, paddingTop: 1.5 }}>
          {details}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default AccordionEducationCard;
