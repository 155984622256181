import { PropsWithChildren, useEffect, useState } from "react";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { testId } from "tests/testIdStrings";
import { useForm } from "react-hook-form";
import { EducationCardComment } from "types/educationCard";
import { FormInput } from "utils/helpers/FormInput";
import { FormCheckbox } from "utils/helpers/FormCheckbox";
import { addComment, delay } from "services/educationCardService";
import { useRecoilValue } from "recoil";
import { educationCardPropsState } from "state/educationCardState";
import { Icon, iconCommentShare } from "theme/icons";
import { Box } from "@mui/material";
import { LoadingStatus } from "types/request";

type Props = PropsWithChildren & {
  title: string;
  open: boolean;
  comment?: EducationCardComment;
  close: () => void;
};

export type CommentFormValues = EducationCardComment;

const CommentDialog = (props: Props) => {
  const { open, close } = props;
  const state = useRecoilValue(educationCardPropsState);
  const { control, handleSubmit, reset } = useForm<CommentFormValues>({
    defaultValues: { id: "", text: "", date: "", shared: false },
  });
  const [status, setStatus] = useState<LoadingStatus>("none");

  // Reset form when comment changes
  useEffect(() => {
    reset(props.comment);
  }, [props.comment, reset]);

  const onSubmit = async (data: CommentFormValues) => {
    if (!state) throw new Error("No education card state found");

    setStatus("pending");
    await delay(2000);
    await addComment(state.eventId, [state.referenceKey], state.userIds[0], data.text, data.shared);
    setStatus("none");
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
    close();
  };

  const handleClose = () => {
    close();
  };

  return (
    <DialogMessage
      title={props.title}
      open={open}
      close={handleClose}
      primaryButtonText="common.save"
      primaryButtonAction={handleSave}
      dataTestIdString={testId.common.close}
    >
      <form>
        <FormInput
          name="text"
          label={"Kommentar"}
          // dataTestIdString={`${testId.input.profile}-lastname`}
          control={control}
          multiline
          sx={{ backgroundColor: "#EEF2F6" }}
        />
        <Box display="flex" alignItems="center">
          <FormCheckbox name="shared" label={"Dela med förare"} labelPlacement={"end"} control={control} />
          <Icon name={iconCommentShare} size="small" color={"info"} />
        </Box>
      </form>
      {props.children}
    </DialogMessage>
  );
};

export default CommentDialog;
