import { Control } from "react-hook-form";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputPhoneNumberProps {
  control: Control<any, any> | undefined;
  disabled?: boolean;
  namePrefix?: string;
}
export const FormInputLastName: React.FC<IFormInputPhoneNumberProps> = ({ control, disabled, namePrefix }) => {
  const intl = useAppIntl();

  return (
    <FormInput
      name={namePrefix ? namePrefix + ".lastName" : "lastName"}
      label={intl.formatMessage({ id: "profile.label.last-name" }) + " *"}
      dataTestIdString={`${testId.input.profile}-lastname`}
      control={control}
      disabled={disabled}
      required
    />
  );
};
