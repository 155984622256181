import { PropsWithChildren } from "react";
import { AppBar, Box, Divider, Toolbar, useTheme } from "@mui/material";
import { useRecoilState } from "recoil";
import { InfoMessage } from "utils/helpers/InfoMessage";
import HeaderEducationMenu from "utils/helpers/dropdown/HeaderEducationMenu";
import { HeaderDesktopNavigation } from "./headerDesktopNavigation";
import { useFetchFeatureFlag } from "@strmediaochitab/optima-react-components";
import { maintenanceState } from "state/recoilAtoms";
import useMediaQueries from "hooks/useMediaQueries";
import { AppTitle } from "./Components/AppTitle";
import UserInfo from "./Components/UserInfo";
import MainMenu from "./Components/MainMenu";
import useRoles from "hooks/useRoles";

type HeaderProps = {
  hideEducations?: boolean;
};
export const Header = (props: HeaderProps) => {
  const { isDesktop } = useMediaQueries();
  const { isEducator } = useRoles();
  const [showMaintenanceMessage, setShowMaintenanceMessage] = useRecoilState(maintenanceState);
  const maintenanceMessage = useFetchFeatureFlag("MaintenanceMessage");

  return (
    <AppBar position="fixed" className="AppBar-top">
      <Toolbar>
        {/* TODO: Move to educator header? */}
        {isEducator && <MainMenu />}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          {props.hideEducations ? <AppTitle /> : <HeaderEducationMenu />}

          {isDesktop && <HeaderDesktopNavigation />}

          <Box display="flex" justifyContent="flex-end" width="250px" alignItems="center" columnGap={1.5}>
            <UserInfo />
          </Box>
        </Box>
        <Divider />
      </Toolbar>

      {maintenanceMessage && showMaintenanceMessage && (
        <HeaderInfoMessage>
          <InfoMessage
            type="info"
            message="common.app.maintenance.info"
            closeButton={() => setShowMaintenanceMessage(false)}
            showCloseButton={true}
            closeButtonColor="white"
          />
        </HeaderInfoMessage>
      )}
    </AppBar>
  );
};

const HeaderInfoMessage = ({ children }: PropsWithChildren) => {
  const { isMobile } = useMediaQueries();
  const theme = useTheme();

  return (
    <Box
      position={"fixed"}
      zIndex={10001}
      marginTop="75px"
      width={isMobile ? "100%" : theme.custom.desktopWidth420}
      marginLeft={isMobile ? 0 : `calc(100vw - ${theme.custom.desktopWidth420} - 16px)`}
      paddingLeft={2}
      paddingRight={2}
    >
      {children}
    </Box>
  );
};
