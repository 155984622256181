import { useState } from "react";
import { useAppContext } from "context/AppContext";
import { Box } from "@mui/material";
import { LoadingIndicator } from "utils/helpers/LoadingIndicator";
import { VideoIds, videos } from "services/localizationService";

type VideoProps = {
  id: VideoIds;
  height?: string | number;
  width?: string | number;
};

const Video = ({ id, width, height }: VideoProps) => {
  const { activeLanguage } = useAppContext();
  const [hasLoaded, setHasLoaded] = useState(false);

  const w = width ? width : "auto";
  const h = height ? height : "100%";
  const url = videos[activeLanguage][id];

  if (!url) {
    console.error(`No video found for id: ${id} and language: ${activeLanguage}`);
    return null;
  }

  return (
    <>
      {!hasLoaded && (
        <Box position={"absolute"}>
          <LoadingIndicator size={64} color="primary" />
        </Box>
      )}
      <iframe
        src={url}
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{ width: hasLoaded ? w : 0, height: hasLoaded ? h : 0, border: 0 }}
        title={id}
        onLoad={() => setHasLoaded(true)}
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
};

export default Video;
