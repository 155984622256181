import { useState } from "react";
import { useRecoilValue } from "recoil";
import { RenderActivities } from "utils/helpers/theory/RenderActivities";
import {
  EducationContentTypes,
  activitiesStateSelector,
  educationContentTypeState,
} from "state/learningStructureState";
import { LRSProvider, LRSProviderState, LRSRecord } from "@strmediaochitab/optima-component-library";
import { ReferenceKey } from "types/cds";
import { userStateAccount } from "state/userState";

const initialState = new Map<string, LRSProviderState>();
type LearningActivityViewerProps = {
  activityKeys: ReferenceKey[];
  contentType: EducationContentTypes;
};

const LearningActivityViewer = ({ activityKeys, contentType }: LearningActivityViewerProps) => {
  const [lrsState] = useState<LRSRecord>(initialState);
  const account = useRecoilValue(userStateAccount);

  const classificationId = useRecoilValue(educationContentTypeState).get(contentType)!;
  const activities = useRecoilValue(
    activitiesStateSelector({
      keys: activityKeys,
      classificationId: classificationId,
    })
  );

  if (!activities) return null;

  return (
    <LRSProvider user={account!.actorId} callbackSetQuestion={() => {}} state={{ questions: lrsState }}>
      <RenderActivities activities={activities} type={contentType} />
    </LRSProvider>
  );
};

export default LearningActivityViewer;
