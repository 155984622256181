import React, { useState } from "react";
import { Box, Avatar, Badge, useTheme, alpha } from "@mui/material";
import { common } from "@mui/material/colors";
import { Icon, iconCircleCheck } from "theme/icons";
import { styleFlex, styleFlexJustifyContentBetween, styleFlexRow } from "theme/styles";
import { UserAvatar } from "types/user";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { useRecoilValue } from "recoil";
import { userStateAvatarList } from "state/userState";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";

type Props = {
  avatarId: string;
  open: boolean;
  close: () => void;
  setAvatarId: (avatarId: string) => void;
};

export const ProfileImageDialog: React.FC<Props> = (props) => {
  const intl = useAppIntl();
  const { avatarId, open, close, setAvatarId } = props;
  const [selectedAvatar, setSelectedAvatar] = useState<UserAvatar | null>(null);
  const userAvatars = useRecoilValue(userStateAvatarList);

  const handleSelect = (userAvatar: UserAvatar) => {
    setSelectedAvatar(userAvatar);
  };

  const handleClose = () => {
    setSelectedAvatar(null); // reset state
    close();
  };

  const handleSave = () => {
    if (selectedAvatar) setAvatarId(selectedAvatar.id);
    close();
  };

  return (
    <DialogMessage
      title={intl.formatMessage({ id: "profile.choose.image" })}
      open={open}
      close={handleClose}
      primaryButtonText={"common.choose"}
      primaryButtonAction={() => handleSave()}
      dataTestIdString={testId.common.text.profile}
    >
      <Box sx={[styleFlex, styleFlexJustifyContentBetween, styleFlexRow, { flexWrap: "wrap", margin: "-1rem" }]}>
        {userAvatars.map((avatar) => (
          <RenderUserAvatar
            key={avatar.id}
            avatar={avatar}
            onSelect={handleSelect}
            selected={(selectedAvatar ? selectedAvatar.id : avatarId) === avatar.id}
          />
        ))}
      </Box>
    </DialogMessage>
  );
};

type AvatarProps = {
  avatar: UserAvatar;
  selected: boolean;
  onSelect: (userAvatar: UserAvatar) => void;
};
const RenderUserAvatar = ({ avatar, selected, onSelect }: AvatarProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{ padding: "0.75rem", position: "relative" }}
      data-testid={`${testId.listItem.profile.avatarImage}-${avatar.id}`}
      onClick={() => onSelect(avatar)}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={selected && <Icon name={iconCircleCheck} htmlColor={theme.palette.primary.main} />}
      >
        <Avatar
          alt="avatar"
          src={avatar.src}
          sx={{ width: "5rem", height: "5rem", border: `1px solid ${alpha(common.black, 0.12)}` }}
        />
      </Badge>
    </Box>
  );
};
