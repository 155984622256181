import { IconButton } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { useGlobalSnackbarMessage } from "hooks/useSnackbarMessage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useAppIntl } from "services/useAppIntl";
import { actorsStateUpdated } from "state/educatorState";
import { testId } from "tests/testIdStrings";
import { Icon, iconTrashcan } from "theme/icons";
import { API_URL_LES } from "utils/constants";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

type DeleteProps = { id: string; name: string };
const Delete = ({ id, name }: DeleteProps) => {
  const intl = useAppIntl();
  const { groupId } = useParams();
  const [open, setOpen] = useState(false);
  const { remove, json, isLoading, error } = useFetch();
  const updateActorsState = useSetRecoilState(actorsStateUpdated);
  const { setSnackbarValues } = useGlobalSnackbarMessage();

  useEffect(() => {
    if (error) setSnackbarValues({ message: error.title, type: "error" });

    if (json) {
      setOpen(false);
      updateActorsState((n: number) => n + 1);
      setSnackbarValues({ message: "Föraren har tagits bort från gruppen", type: "success" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, json]);

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleDelete = () => {
    remove(API_URL_LES + groupId + "/actors/" + id);
  };

  return (
    <>
      <IconButton color="error" onClick={handleConfirm}>
        <Icon name={iconTrashcan} />
      </IconButton>
      {open && (
        <DialogMessage
          title={intl.formatMessage({ id: "student.dialog.delete.title" })}
          open={true}
          close={() => setOpen(false)}
          primaryButtonText="common.delete"
          primaryButtonLoader={isLoading ? true : false}
          dataTestIdString={`${testId.button.dialogMessage}-delete`}
          primaryButtonAction={handleDelete}
        >
          <FormattedTypography
            variant="body1"
            mb={1}
            id="student.dialog.delete.body"
            values={{ name: <b>{name}</b> }}
          />
        </DialogMessage>
      )}
    </>
  );
};

export default Delete;
