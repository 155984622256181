import { useState } from "react";
import { Stack, FormControl, Button, Badge, SvgIconTypeMap, TextField, useTheme } from "@mui/material";
import { Icon, iconBookmarkSolid, iconCircleCheck } from "theme/icons";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { Activity } from "types/cds";
import { BookmarkState, StateKey, bookmarkStateId, saveState } from "services/appStateService";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { educationState } from "state/educationState";
import { bookmarkStateBookmarkAdded } from "state/bookmarkState";
import { educationContentTypeState } from "state/learningStructureState";
import { useGlobalSnackbarMessage } from "hooks/useSnackbarMessage";
import { useAppIntl } from "services/useAppIntl";
import { scrollToElement } from "utils/helpers/ScrollHandler";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

type Props = {
  activity: Activity;
  open: boolean;
  close: () => void;
  type: BookmarkState["type"];
};

export const BookmarkDialog = (props: Props) => {
  const education = useRecoilValue(educationState);
  const added = useSetRecoilState(bookmarkStateBookmarkAdded);
  const refreshBookmarks = () => added((n: number) => n + 1);
  const classificationId = useRecoilValue(educationContentTypeState).get(props.type);
  const { setSnackbarValues } = useGlobalSnackbarMessage();
  const intl = useAppIntl();
  const [formValues, setFormValues] = useState<BookmarkState>({
    key: props.activity.key,
    color: "info",
    text: "",
    title: props.activity.title,
    type: props.type,
    date: new Date().toLocaleDateString(),
  });
  const colors: SvgIconTypeMap["props"]["color"][] = ["info", "error", "warning", "primary"];

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = async () => {
    const key: StateKey = {
      typeId: bookmarkStateId,
      auxId1: education?.educationId,
      auxId2: props.activity.key.contentId,
      auxId3: classificationId,
    };

    await saveState<BookmarkState>(key, formValues);
    refreshBookmarks();
    setTimeout(() => {
      props.close();
      setSnackbarValues({ message: intl.formatMessage({ id: "bookmarks.save.message" }), type: "success" });

      // We need to scroll to the bookmarked activity (again) since the refreshBookmarks() causes a re-render
      const selector = `[id='${props.activity.key.contentId}']`;
      scrollToElement({ selector, offsetTop: -100 });
    }, 250);
  };

  const handleSelectBookmark = (color: string) => {
    const newValues = { ...formValues };
    newValues.color = color;
    setFormValues(newValues);
  };

  return (
    <DialogMessage
      open={props.open}
      close={props.close}
      title={intl.formatMessage({ id: "common.bookmark" })}
      primaryButtonText="common.save"
      primaryButtonAction={handleSave}
    >
      <Stack spacing={3}>
        <Stack spacing={2}>
          <FormattedTypography variant={"body1"} id="bookmarks.create.color" />
          <Stack spacing={1} direction={"row"}>
            {colors.map((c) => (
              <BadgeBookmark
                key={c}
                color={c}
                onSelected={() => handleSelectBookmark(c!)}
                selected={formValues.color === c}
              />
            ))}
          </Stack>
        </Stack>
        <FormControl>
          <FormattedTypography variant="subtitle1" mb={1} id="bookmarks.create.note" />
          <TextField
            name="text"
            placeholder={intl.formatMessage({ id: "bookmarks.create.note.placeholder" })}
            value={formValues.text}
            onChange={handleInputChange}
            multiline
            sx={{ ".MuiInputBase-multiline": { borderRadius: "5px" } }}
          />
        </FormControl>
      </Stack>
    </DialogMessage>
  );
};

function BadgeBookmark({
  color,
  selected,
  onSelected,
}: {
  selected?: boolean;
  color: SvgIconTypeMap["props"]["color"];
  onSelected: () => void;
}) {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={2}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={selected ? <Icon name={iconCircleCheck} color="primary" /> : null}
        sx={{ ".MuiBadge-badge": { backgroundColor: "white", padding: 0 } }}
      >
        <Button
          size="large"
          variant={"outlined"}
          color="primary"
          sx={{
            borderRadius: "50%",
            minWidth: "auto",
            maxWidth: "48px",
            borderColor: selected ? theme.palette.primary.main : "default",
            backgroundColor: selected ? theme.palette.primary.light01 : "default",
          }}
          onClick={onSelected}
        >
          <Icon name={iconBookmarkSolid} color={color} />
        </Button>
      </Badge>
    </Stack>
  );
}
