import { ReactNode, useState, MouseEvent, ReactElement, cloneElement, Children, useEffect } from "react";
import { Button, Menu, SxProps, Theme, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { styleFlexJustifyContentBetween } from "theme/styles";
import { IMenuItem, MenuItems } from "./MenuItems";
import { testId } from "tests/testIdStrings";
import { DataTestType } from "tests/test.setup";
import { useAppIntl } from "services/useAppIntl";

interface IDropdownMenu {
  id: string;
  label: ReactNode;
  children: ReactElement;
  variant?: "text" | "outlined" | "contained";
  sxButton?: SxProps<Theme>;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  noWrap?: boolean;
  onClick?: (item: IMenuItem) => void;
  onSetLabel?: (item?: ReactNode) => ReactNode | undefined;
}
const styleMenuButton = {
  backgroundColor: "white",
  ".MuiButton-startIcon": { paddingLeft: 1 },
  ".MuiButton-endIcon": { paddingRight: 1 },
};

export const DropdownMenu = ({
  id,
  label,
  variant,
  startIcon,
  endIcon,
  sx,
  children,
  fullWidth,
  noWrap,
  onClick,
  onSetLabel,
  sxButton,
}: IDropdownMenu) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [displayLabel, setDisplayLabel] = useState<ReactNode | null>(null);
  const open = Boolean(anchorEl);
  const idMenu = id + "Menu";
  const styleMenuWidth = { minWidth: anchorEl && anchorEl.offsetWidth };
  const sxForButton = sxButton ? sxButton : styleMenuButton;

  useEffect(() => {
    if (!onSetLabel) return;

    const label = onSetLabel();
    if (label) setDisplayLabel(label);
  }, [onSetLabel]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetLabel = (label: string) => {
    setDisplayLabel(label);
  };

  // Add needed props
  let childrenWithProps = Children.map(children, (child) => {
    return cloneElement(child, { onMenuClose: handleClose, onClick: onClick, onSetLabel: handleSetLabel });
  });

  return (
    <>
      <Button
        id={id}
        aria-controls={open ? idMenu : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant={variant}
        onClick={handleClick}
        startIcon={startIcon}
        endIcon={endIcon}
        fullWidth={fullWidth}
        color="inherit"
        sx={[...(Array.isArray(sxForButton) ? sxForButton : [sxForButton]), styleFlexJustifyContentBetween]}
        data-testid={testId.common.dropDown.buttonToggle}
      >
        {noWrap ? (
          <Typography variant="inherit" noWrap maxWidth="95%">
            {displayLabel ? displayLabel : label}
          </Typography>
        ) : (
          <>{displayLabel ? displayLabel : label}</>
        )}
      </Button>
      <Menu
        id={idMenu}
        data-testid={testId.common.dropDown.menuWrapper}
        MenuListProps={{
          "aria-labelledby": id,
          sx: [styleMenuWidth, ...(Array.isArray(sx) ? sx : [sx])],
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
      >
        {childrenWithProps}
      </Menu>
    </>
  );
};

interface IMenuItemsProps extends DataTestType {
  items: IMenuItem[];
  onClick?: (item: IMenuItem) => void;
  onMenuClose?: () => void;
  onSetLabel?: (label: string) => void;
}
export const DropdownMenuItems = ({ items, dataTestIdString, onClick, onMenuClose, onSetLabel }: IMenuItemsProps) => {
  const navigate = useNavigate();
  const intl = useAppIntl();

  const handleClick = (item: IMenuItem) => {
    if (item.title === intl.formatMessage({ id: "home.getstarted" })) {
      ReactGA.event({
        category: "more",
        action: "HowToUseKKBVideo",
      });
    }
    if (item.to) {
      ReactGA.event({
        category: "more",
        action: item.to,
      });
      return navigate(item.to);
    }

    if (onClick) onClick(item);
    if (onSetLabel && item.id) onSetLabel(item.title);
    if (onMenuClose) onMenuClose();
  };

  return <MenuItems items={items} dataTestIdString={dataTestIdString} onClick={handleClick} />;
};
