import React, { useEffect } from "react";
import { Theme, useMediaQuery, Stack } from "@mui/material";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { iconArrowRightRegular } from "theme/icons";
import { ExtendedTreeNode, TreeNode } from "types/cds";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  theoryState,
  theoryAreaState,
  theoryChapterState,
  theoryStateSelected,
  theoryStateAreas,
} from "state/theoryState";
import { useNavigate } from "react-router-dom";
import { learningStructureState } from "state/learningStructureState";
import { testId } from "tests/testIdStrings";
import useChapterActivities from "hooks/useChapterActivities";
import { activityProgressState } from "state/progresState";
import { ItemTitleValuePair } from "services/reportingService";

interface TheoryAreaProps {}

export const TheoryAreas: React.FC<TheoryAreaProps> = (props) => {
  // Hämtar elevens utbildningsstruktur från CDS
  const structure = useRecoilValue(learningStructureState);

  // Setter för att spara ner theoryState
  const setTheoryState = useSetRecoilState(theoryState);
  const setChapterState = useSetRecoilState(theoryChapterState);

  // Hämtar ut alla noder på första nivån från theoryState
  const areas = useRecoilValue(theoryStateAreas);

  const progress = useRecoilValue(activityProgressState);

  // Sätter hela strukturen i theoryState
  useEffect(() => {
    if (structure) setTheoryState(structure);
  }, [structure, setTheoryState, setChapterState]);

  if (!structure) return null;
  if (!structure.length) throw new Error("The education has no content");

  return (
    <Stack spacing={3}>
      {areas.map((item: TreeNode, index: number) => (
        <AreaItem key={item.id} item={item} index={index} structure={structure} progress={progress} />
      ))}
    </Stack>
  );
};

const AreaItem = ({
  item,
  index,
  structure,
  progress,
}: {
  item: TreeNode;
  index: number;
  structure: ExtendedTreeNode[];
  progress?: ItemTitleValuePair[];
}) => {
  const navigate = useNavigate();
  const { setChapterActivities } = useChapterActivities();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  // Setter för att spara ner theoryState
  const setChapterState = useSetRecoilState(theoryChapterState);
  const setAreaState = useSetRecoilState(theoryAreaState);

  // Läser/Sätter vald nod på theoryStateSelected
  const setTheorySelected = useSetRecoilState(theoryStateSelected);

  const handleSelectedItem = (item: TreeNode) => {
    let path = item.id.toString();
    setTheorySelected(item);
    setAreaState(item);

    // Navigate to read directly
    if (!isMobile) {
      const chapters = structure!.filter((activity) => activity.parentId === item.id);
      const firstChapter = chapters[0];
      setChapterState(firstChapter);
      setChapterActivities(firstChapter);

      path = item.id + "/" + firstChapter.id + "/read";
    }

    navigate(path);
  };

  // Hämtar progress för aktivity
  const areaProgress = parseInt(progress?.find((p) => p.title === item.activityKey?.versionId)?.value || "0");

  return (
    <NavigationItem
      height={56}
      key={item.id}
      text={item.title}
      textVariant="subtitle2"
      navigationIcon={iconArrowRightRegular}
      onClick={() => handleSelectedItem(item)}
      progressValue={areaProgress}
      dataTestIdString={`${testId.listItem.theory.areas}-${index + 1}`}
    />
  );
};
