import { Button, IconButton } from "@mui/material";
import PdfViewer from "layout/Components/PdfViewer";
import { useState } from "react";
import { Icon, iconFile } from "theme/icons";
import { DialogMenu } from "utils/helpers/DialogMessage";

const styleButton = {
  height: "24px",
  minWidth: "auto",
  borderRadius: "100px",
};

const ButtonPdf = ({ pdf, iconButton }: { pdf: string; iconButton?: boolean }) => {
  const [open, setOpen] = useState(false);

  const showPdf = () => {
    setOpen(true);
  };

  return (
    <>
      {iconButton ? (
        <IconButton color="info" onClick={showPdf}>
          <Icon name={iconFile} size="small" />
        </IconButton>
      ) : (
        <Button variant="outlined" size="small" sx={styleButton} onClick={showPdf}>
          <Icon name={iconFile} size="small" color="info" />
        </Button>
      )}

      {open && (
        <DialogMenu title={"PDF"} open={true} close={() => setOpen(false)}>
          <PdfViewer pdf={pdf} />
        </DialogMenu>
      )}
    </>
  );
};

export default ButtonPdf;
