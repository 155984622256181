import { useAppIntl } from "services/useAppIntl";
import { Title } from "utils/helpers/TitleDivider";
import { WelcomeMessage } from "utils/helpers/WelcomeMessage";
import Groups from "./GroupList";

const Educator = () => {
  const intl = useAppIntl();

  return (
    <>
      <WelcomeMessage />

      <Title title={intl.formatMessage({ id: "common.title.educator" })} variant="h2" />

      <Groups />
    </>
  );
};

export default Educator;
