import { Box, Button, ButtonTypeMap, useTheme } from "@mui/material";
import { Icon, iconStarFilled, iconGrid } from "theme/icons";
import { styleFlex, styleFlexJustifyContentStart, styleFlexRow } from "theme/styles";
import { DialogMenu } from "../DialogMessage";
import useToggle from "hooks/useToggle";
import { useNavigate, useLocation } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { useAppIntl } from "services/useAppIntl";

export interface IOverviewQuestion {
  id: ReferenceKey;
  text: string;
  number: number;
  status: OverviewQuestionStatus;
  star?: boolean;
}

export enum OverviewQuestionStatus {
  None,
  Answered,
  Correct,
  Incorrect,
}
export interface IQuestionOverview {
  questions: IOverviewQuestion[];
}

export const QuestionOverviewButton = () => {
  const [openMenu, setOpenMenu] = useToggle(false);
  const intl = useAppIntl();
  const theme = useTheme();

  return (
    <>
      <Button
        variant="outlined"
        sx={{ minWidth: "auto", width: "48px", mx: 2 }}
        onClick={() => setOpenMenu()}
        data-testid={testId.listItem.question.overview}
      >
        <Icon name={iconGrid} htmlColor={theme.palette.primary.main}></Icon>
      </Button>

      <DialogMenu
        open={openMenu}
        close={() => setOpenMenu()}
        title={intl.formatMessage({ id: "common.question-overview" })}
        divider
      >
        <QuestionOverview questions={[]} />
      </DialogMenu>
    </>
  );
};

export const QuestionOverview = ({ questions }: IQuestionOverview) => {
  return (
    <Box sx={[styleFlex, styleFlexJustifyContentStart, styleFlexRow, { flexWrap: "wrap", margin: 3 }]}>
      {questions.map((question) => (
        <QuestionButton key={question.number} question={question} />
      ))}
    </Box>
  );
};

interface IQuestionOverviewButton {
  question: IOverviewQuestion;
}

const QuestionButton = ({ question }: IQuestionOverviewButton) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  let backgroundColor = undefined;
  let borderColor = undefined;
  let hoverColor = undefined;
  let color: ButtonTypeMap["props"]["color"] = "inherit";

  const handleClick = (number: number, id: ReferenceKey) => {
    navigate(location.pathname, { state: { question: { id, number } } });
  };

  switch (question.status) {
    case OverviewQuestionStatus.Answered:
      // backgroundColor = optimaColors.tertiary01;
      // borderColor = optimaColors.tertiary06;
      color = "info";
      hoverColor = "white";
      break;
    case OverviewQuestionStatus.Correct:
      // backgroundColor = optimaColors.successLight;
      // borderColor = optimaColors.success;
      color = "success";
      hoverColor = "white";
      break;
    case OverviewQuestionStatus.Incorrect:
      // backgroundColor = optimaColors.errorLight;
      // borderColor = optimaColors.error;
      color = "error";
      hoverColor = "white";
      break;
    default:
      break;
  }

  return (
    <Button
      variant={question.status === OverviewQuestionStatus.None ? "outlined" : "contained"}
      color={color}
      onClick={() => handleClick(question.number, question.id)}
      sx={{
        borderRadius: "50%",
        minWidth: "auto",
        width: "48px",
        height: "48px",
        margin: 1,
        position: "relative",
        borderWidth: "1px",
        borderStyle: "solid",
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        color: "inherit",
        ":hover": {
          color: hoverColor,
        },
      }}
    >
      {question.number}
      {question.star && (
        <Icon
          name={iconStarFilled}
          htmlColor={theme.palette.info.main}
          sx={{ position: "absolute", top: -5, right: -5 }}
        />
      )}
    </Button>
  );
};
