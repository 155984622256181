import { API_URL_ASS_STATE } from "utils/constants";
import { post, get, put, remove } from "./httpService";
import { TheoryTab } from "utils/helpers/theory/TopNavigationTabs";
import { msalInstance } from "utils/helpers/msal/AppMsalProvider";
import { ReferenceKey } from "types/cds";
import { EducationContentTypes } from "state/learningStructureState";
import { SupportedLanguages } from "./localizationService";

// App state constants
export const educationStateId = "d8a40e7b-e7a3-466a-96fd-def01a308465";
export const theoryStateId = "f7eda5fb-e1d1-4fac-9aee-48d5ac3719e9";
export const bookmarkStateId = "fc83bb96-00b0-46b9-9fa5-305d3ea7589c";
export const languageStateId = "04afb703-525a-4b85-ae4b-9b28a521d619";

// App state types
export type EducationState = {
  identifier: string;
};

export type TheoryState = {
  key: ReferenceKey;
  page: TheoryTab;
};

export type BookmarkState = {
  key: ReferenceKey;
  color: string;
  text: string;
  title: string;
  type: EducationContentTypes;
  date: string;
};

export type LanguageState = {
  lang: SupportedLanguages;
};

export type StateKey = {
  typeId: typeof bookmarkStateId | typeof theoryStateId | typeof educationStateId | typeof languageStateId;
  auxId1?: string;
  auxId2?: string;
  auxId3?: string;
};

export type SaveOptions = {
  type: "add" | "update";
};

/**
 * should return /v1/State/{appId}/{userId}/{stateId}
 * */
const getUrl = (key: StateKey): string | undefined => {
  const account = msalInstance.getActiveAccount();
  if (!account) return undefined;

  // TODO: Försäkra oss om att vi har en idTokenClaim?
  let path = account.idTokenClaims!.extension_ActorId + "/" + key.typeId;

  if (key.auxId1) path += "/" + key.auxId1;
  if (key.auxId2) path += "/" + key.auxId2;
  if (key.auxId3) path += "/" + key.auxId3;

  return API_URL_ASS_STATE + path;
};

/**
 * Retrieves the state data for a given key.
 * @param key - The key to retrieve the state data for.
 * @returns A promise that resolves to an array of type T or undefined if no data is found.
 */
export const getState = async <T>(key: StateKey): Promise<T[] | undefined> => {
  const url = getUrl(key);
  if (!url) return undefined;

  const response = await get(url)
    .then((response: any[] | undefined) => {
      if (!response || !response.length) return undefined;

      return response.map((o) => JSON.parse(o.data)) as T[];
    })
    .catch((error) => {
      console.log("App state error", error);
      return undefined;
    });

  return response;
};

/**
 * Saves the state of the application to the server.
 * @param key - The key to use for storing the state.
 * @param state - The state to save.
 * @param options - Additional options for saving the state.
 * @returns A promise that resolves when the state has been saved.
 */
export const saveState = async <T>(key: StateKey, state: T, options?: SaveOptions) => {
  const url = getUrl(key);
  if (!url) return undefined;

  console.log("saving state", key, state);

  if (options) {
    if (options.type === "add") return await post(url, state);
  }

  // Default: will do add OR update on the key
  await put(url, state);
};

/**
 * Deletes the state associated with the given key.
 * @param key The key of the state to delete.
 */
export const deleteState = async (key: StateKey) => {
  const url = getUrl(key);
  if (!url) return undefined;

  console.log("deleting state", key);

  await remove(url);
};
