import React, { ReactNode } from "react";
import { Box, Stack, SxProps, Theme, alpha, useTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { LearningObject } from "@strmediaochitab/optima-component-library";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { EducationContentTypes, itemStateSelector } from "state/learningStructureState";
import { Activity } from "types/cds";
import { MaterialSkeleton } from "utils/helpers/LoadingSkeletons";
import bgTop from "static/Ellipse_white.svg";
import { LearningObjectNavigation } from "utils/helpers/theory/LearningObjectNavigation";
import {
  LearningObjecActions,
  LearningObjectBookmark,
  LearningObjectListen,
} from "utils/helpers/theory/LearningObjectActions";
import { TheoryTab } from "utils/helpers/theory/TopNavigationTabs";
import { testId } from "tests/testIdStrings";
import FormattedMessage from "../FormattedMessage";
import useMediaQueries from "hooks/useMediaQueries";

interface IRenderActivities {
  activities: Activity[] | undefined;
  navigateTo?: TheoryTab;
  bgColor?: string;
  type: EducationContentTypes;
}
export const RenderActivities: React.FC<IRenderActivities> = ({ activities, navigateTo, bgColor, type }) => {
  const theme = useTheme();
  const { isMobile } = useMediaQueries();

  if (!activities?.length) return null;
  if (activities.every((a) => a.materialReferences?.length === 0)) return null;

  return (
    <Stack
      spacing={5}
      sx={{
        backgroundColor: bgColor,
        marginLeft: isMobile ? 0 : 2,
        marginRight: isMobile ? 0 : 2,
        color: theme.palette.text.primary,
        // boxShadow: isMobile ? 0 : theme.shadows[2],
      }}
    >
      {activities.map((activity, index) => (
        <RenderActivity
          key={activity.key.versionId}
          index={index}
          activity={activity}
          navigateTo={navigateTo}
          type={type}
        />
      ))}
    </Stack>
  );
};

const RenderActivity: React.FC<{
  activity: Activity;
  navigateTo?: TheoryTab;
  type: EducationContentTypes;
  index: number;
}> = ({ activity, navigateTo, type, index }) => {
  const useBookmarks = useFeatureFlag("Bookmarks");
  const hideBookmarkInBookmarkDetail = !window.location.href.includes("more/bookmarks");

  return (
    <>
      {/* Only render if any material */}
      {activity.materialReferences?.length !== 0 && (
        <FillBox
          data-type="activity"
          sx={{ py: 3, borderTop: `1px solid ${alpha("#000000", 0.12)}` }}
          id={activity.key.contentId}
        >
          {/* TODO: Avgöra om denna header ska renderas här eller ej */}
          {/* <Typography
                variant="h2"
                data-testid={`${testId.listItem.section.sectionTitle}-${index + 1}`}
                id={activity.key.contentId}
              >
                {activity.title}
              </Typography> */}

          <RenderMaterial activity={activity} activityType={type}>
            <LearningObjecActions>
              <Stack spacing={2} direction="row">
                {navigateTo === TheoryTab.practice && (
                  <LearningObjectListen dataTestIdString={`${testId.listItem.section.listen}-${index + 1}`} />
                )}
                {useBookmarks && hideBookmarkInBookmarkDetail && (
                  <LearningObjectBookmark
                    activity={activity}
                    activityType={type}
                    dataTestIdString={`${testId.listItem.section.bookmark}-${index + 1}`}
                  />
                )}
              </Stack>
              {navigateTo && (
                <LearningObjectNavigation
                  navigateTo={navigateTo}
                  contentId={activity.key.contentId}
                  dataTestIdString={`${testId.listItem.section.goToPractice}-${index + 1}`}
                  disabled={activity.hasPracticeContent === false}
                />
              )}
            </LearningObjecActions>
          </RenderMaterial>
        </FillBox>
      )}
    </>
  );
};

const RenderMaterial: React.FC<{ activity: Activity; children?: ReactNode; activityType: EducationContentTypes }> = ({
  activity,
  children: actions,
  activityType,
}) => {
  const theme = useTheme();
  const materials = activity.materialReferences;

  const styleQuestionDivider = {
    content: '""',
    backgroundColor: theme.palette.primary.light,
    display: "block",
    height: "0.5rem",
    marginBottom: "2.5rem",
    marginTop: "1.5rem",
    marginLeft: "-1rem",
    marginRight: "-1rem",
  };

  const styleQuestion = {
    ".optima-heading": {
      // Question following a heading
      "+ .optima-question:before": {
        display: "none",
      },
    },
    ".optima-video": {
      // Question following a heading
      "+ .optima-question:before": {
        marginTop: "2.5rem",
      },
    },
    ".optima-question": {
      ":before, :after": styleQuestionDivider,
      ":last-child:after": {
        display: "none",
      },
      // Question following a question
      "+ .optima-question:before": {
        display: "none",
      },
    },
  };

  return (
    <Stack data-type="material" sx={styleQuestion}>
      <React.Suspense fallback={<MaterialSkeleton title={activity.title} />}>
        <MaterialContent materialReferences={materials} activityType={activityType}>
          {actions}
        </MaterialContent>
      </React.Suspense>
    </Stack>
  );
};

const MaterialContent: React.FC<{
  materialReferences: any;
  children?: ReactNode;
  activityType: EducationContentTypes;
}> = ({ materialReferences, children: actions, activityType }) => {
  const content = useRecoilValue(itemStateSelector({ materialReferences }));

  if (!content) return null;

  if (!content.length)
    return (
      <Box sx={{ pb: 2 }}>
        <FormattedMessage id="theory.material.activity.no-content" />
      </Box>
    );

  const learningObject = content[0];
  const type = learningObject.configuration.type;
  const noActionButtons = type === "Instruction" || type === "Introduction" || type === "Summary";

  return (
    <Stack data-type="material-content">
      {!noActionButtons && actions}

      {content.map((lo) => (
        <RenderLearningObject key={`${activityType}-${lo.id.versionId}`} learningObject={lo} />
      ))}
    </Stack>
  );
};

export const RenderLearningObject: React.FC<{ learningObject: any }> = ({ learningObject }) => {
  if (learningObject.configuration.type === "Instruction" || learningObject.configuration.type === "Introduction")
    return (
      <RenderIntroduction>
        <LearningObject data={learningObject} headingLevel={2} onAction={() => {}} />
      </RenderIntroduction>
    );

  return (
    <Box
      pt={3}
      sx={{ backgroundColor: "white", "> *:last-child:not(div)": { paddingBottom: 0, marginBottom: 0 } }}
      className="optima-learning-object"
      data-type="learning-object"
      data-debug={learningObject.debugData ? JSON.stringify(learningObject.debugData.values) : null}
    >
      <LearningObject data={learningObject} headingLevel={2} onAction={() => {}} />
    </Box>
  );
};

const RenderIntroduction: React.FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();

  const styleIntroduction = {
    backgroundImage: `url(${bgTop})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom 0 right -300px",
    pb: theme.spacing(4),
    px: theme.spacing(2),
    mx: theme.spacing(-2),
    mb: theme.spacing(-6),
    position: "relative",
    zIndex: 2,
  };

  return (
    <Box sx={styleIntroduction} data-type="material-introduction">
      {children}
    </Box>
  );
};

export const FillBox: React.FC<{ sx?: SxProps<Theme> | undefined; children: ReactNode; id?: string | undefined }> = ({
  sx,
  children,
  id,
}) => {
  const theme = useTheme();
  return (
    <Box px={theme.spacing(2)} sx={{ ...sx, backgroundColor: "#FFFFFF" }} id={id}>
      {children}
    </Box>
  );
};
