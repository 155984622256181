import { useEffect, useState } from "react";
import { useEducation } from "hooks/useEducation";
import { EducationStateEnum } from "types/education";
import { MORE_EDUCATION_PATH } from "hooks/useRouteConfiguration";
import useRoles from "hooks/useRoles";

/**
 * @returns path to navigate to when user is logged in or is newly created
 */
export const useInitNavigate = () => {
  const { userEducations } = useEducation();
  const { isEducator, isStudent } = useRoles();
  const [state, setState] = useState<string | null>(null);

  const handleInitNavigation = () => {
    // Check user roles
    if (isEducator && isStudent) {
      return setState("/select-role");
    }

    if (isEducator) {
      console.log("user is Educator");
      return setState("/");
    }

    if (userEducations) {
      const activeNavigation = userEducations.filter((x) => x.educationState === EducationStateEnum.Active);
      // One active education
      if (activeNavigation.length === 1) return setState("/");

      // There is none, 2 or more active educations
      return setState(MORE_EDUCATION_PATH);
    }
  };

  useEffect(() => {
    if (!userEducations || (!isEducator && !isStudent)) return;

    handleInitNavigation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEducations, isEducator, isStudent]);

  return state;
};
