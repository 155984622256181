import { alpha, Box, Chip, styled, useTheme } from "@mui/material";
import { DataTestType } from "tests/test.setup";
import { Icon } from "theme/icons";
import { IMenuItem, IMenuItems } from "./MenuItems";

export interface IMenuItemsChips extends IMenuItems, DataTestType {
  selectedItems: Map<string, IMenuItem>;
  onDelete?: (item: IMenuItem) => void;
}
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const MenuItemsChips = (props: IMenuItemsChips) => {
  const theme = useTheme();

  const styleSelected = {
    borderColor: theme.palette.success.dark,
    backgroundColor: alpha(theme.palette.success.main, 0.12),
  };

  const handleClick = (item: IMenuItem) => () => {
    if (props.onClick) props.onClick(item);
  };

  const handleDelete = props.onDelete
    ? (item: IMenuItem) => {
        if (props.onDelete) props.onDelete(item);
      }
    : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        listStyle: "none",
        p: 1,
        m: 0,
        maxHeight: 240,
        overflow: "auto",
      }}
      // TODO: Fel i nästling som gör att vi här får en div som child till ul (DropdownMenu) vilket inte är semantiskt korrekt.
      // component="div"
    >
      {props.items.map((item) => {
        const selected = item.id ? props.selectedItems.get(item.id) : undefined;
        const icon = item.icon ? <Icon name={item.icon} /> : undefined;

        return (
          <ListItem key={item.id}>
            <Chip
              icon={icon}
              color="default"
              sx={selected ? styleSelected : null}
              label={item.title}
              onClick={handleClick(item)}
              onDelete={handleDelete}
            />
          </ListItem>
        );
      })}
    </Box>
  );
};
