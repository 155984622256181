import { AppBar } from "@mui/material";
import useMediaQueries from "hooks/useMediaQueries";
import { MainNavigation } from "./Components/MainNavigation";

export const Footer = () => {
  const { isDesktop } = useMediaQueries();

  return !isDesktop ? (
    <AppBar position="fixed" className="AppBar-bottom">
      <MainNavigation />
    </AppBar>
  ) : null;
};
