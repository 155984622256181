import { useState, useEffect } from "react";
import { User } from "types/user";
import { useFetch } from "./useFetch";
import { RESPONSE_CODES } from "utils/constants/responseCodes";
import { IUpdateMeRequest } from "types/user";

type SaveResponse = {
  status: number;
  traceId: string;
};

const useFetchMe = () => {
  const { json, isLoading, error } = useFetch<User | SaveResponse>("missing-URL");
  const [content, setContent] = useState<User | null>(null);
  const [isSaved, setIsSaved] = useState<boolean | undefined>(undefined);

  const saveMe = (data: IUpdateMeRequest) => {
    setIsSaved(false);
    // put(API_URL_BO_ME, data);
  };

  useEffect(() => {
    if (json) {
      if ("status" in json) {
        if (json.status === RESPONSE_CODES.OK_NO_CONTENT) {
          return setIsSaved(true);
        }

        return;
      }

      setContent(json);
    }
  }, [json]);

  return { json: content, isLoading, error, isSaved, saveMe };
};

export default useFetchMe;
