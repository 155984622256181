import { DialogMenu } from "utils/helpers/DialogMessage";
import { useRecoilState, useRecoilValue } from "recoil";
import { theoryDialogState } from "state/recoilAtoms";
import LearningActivityViewer from "layout/Components/LearningActivityViewer";
import { selectedActivityKeyState } from "state/learningStructureState";

/**
 * Renders a dialog component to display a LearningActivity from Recoil state "selectedActivityKeyState"
 * @returns The LearningActivityDialog.
 */
const LearningActivityDialog = () => {
  const [open, setOpen] = useRecoilState(theoryDialogState);
  const selectedActivity = useRecoilValue(selectedActivityKeyState);

  if (!selectedActivity) return null;

  return (
    <DialogMenu title="Teori" open={open} close={() => setOpen(false)} keepMounted={false}>
      <LearningActivityViewer activityKeys={[selectedActivity]} contentType="read" />
    </DialogMenu>
  );
};

export default LearningActivityDialog;
