import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { theoryChapterState } from "state/theoryState";
import { testId } from "tests/testIdStrings";
import { buttonContainerBottom } from "theme/styles";
import { BackgroundShape } from "../BackgroundShape";
import FormattedMessage from "../FormattedMessage";
import { IntroContent } from "./IntroContent";
import { NoContent } from "./NoContent";
import { NavigateTestState } from "layout/test/testResult";
import { useAppContext } from "context/AppContext";
import { useTextToSpeech } from "hooks/useTextToSpeech";
import { TextToSpeech } from "@strmediaochitab/optima-component-library";
import SvgImage, { svgColor, ellipse3 } from "utils/helpers/SvgImage";
import useMediaQueries from "hooks/useMediaQueries";

interface IAnswerStart {
  numberOfQuestions: number;
}

export const AnswerStart = ({ numberOfQuestions }: IAnswerStart) => {
  const navigate = useNavigate();
  const location = useLocation();
  const chapter = useRecoilValue(theoryChapterState);
  const { isMobile } = useMediaQueries();
  const textToSpeech = useTextToSpeech();
  const { soundSupport } = useAppContext();

  const startTest = () => {
    const navigateState: NavigateTestState = { startAssessment: true };
    navigate(location.pathname, { state: navigateState });
  };

  if (!chapter) return null;

  return (
    <>
      <Box>
        <Typography marginTop="15px" variant="h1">
          {chapter.title}
          {soundSupport && <TextToSpeech text={chapter.title} player={textToSpeech} />}
        </Typography>

        {!numberOfQuestions ? (
          <NoContent />
        ) : (
          <IntroContent numberOfQuestions={numberOfQuestions} title={chapter.title} />
        )}

        <Box
          sx={
            isMobile
              ? buttonContainerBottom
              : { width: "100%", display: "flex", justifyContent: "center", marginTop: 5 }
          }
        >
          <Button
            variant="contained"
            disabled={!numberOfQuestions}
            sx={{ px: 2, width: isMobile ? "100%" : "300px" }}
            onClick={startTest}
            data-testid={testId.button.test}
          >
            <FormattedMessage id="test.start" />
          </Button>
        </Box>
      </Box>

      <BackgroundShape right={-450} bottom={10}>
        <SvgImage d={ellipse3.d} viewBox={ellipse3.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>
    </>
  );
};
