import { Box, Button, List, Typography } from "@mui/material";
import { Icon, iconCommentAdd, iconCommentShare, iconCommentSolid, iconNoComment } from "theme/icons";
import CommentDialog from "./CommentDialog";
import { useState } from "react";
import { EducationCardComment } from "types/educationCard";
import ListHeader from "layout/Components/ListHeader";
import { ListItemButton } from "utils/helpers/ListItemButton";

const styleButton = {
  height: "24px",
  minWidth: "auto",
  borderRadius: "100px",
};
const styleNoButton = { ...styleButton, borderColor: "transparent" };

type EducationCommentProps = {
  hasComment: boolean;
  noButton?: boolean;
};

const Comment = ({ hasComment, noButton }: EducationCommentProps) => {
  const style = noButton ? styleNoButton : styleButton;

  if (hasComment)
    return (
      <Button variant="outlined" size="small" sx={style}>
        <Icon name={iconCommentSolid} size="small" color="info" />
      </Button>
    );

  return (
    <Button variant="outlined" size="small" sx={style}>
      <Icon name={iconNoComment} size="small" color="disabled" />
    </Button>
  );
};

export default Comment;

// TODO: Remove temp comments
const tempComments = [
  { id: "1", text: "Kommentar 1", date: "2021-10-10", shared: true },
  { id: "2", text: "Kommentar 2", date: "2021-10-09", shared: false },
  { id: "3", text: "Kommentar 3", date: "2021-10-08", shared: true },
];

export const AddComment = ({ title, comments }: { title: string; comments?: EducationCardComment[] }) => {
  const [open, setOpen] = useState(false);
  const hasComments = comments && comments.length > 0;
  const [comment, setComment] = useState<EducationCardComment | undefined>();

  const handleAddComment = () => {
    setOpen(true);
  };

  return (
    <>
      <Button variant="outlined" size="small" sx={styleButton} onClick={handleAddComment}>
        <Icon
          name={hasComments ? iconCommentSolid : iconCommentAdd}
          size="small"
          color={hasComments ? "info" : "disabled"}
        />
      </Button>
      {open && (
        <CommentDialog title={`Kommentar på ${title}`} open={true} close={() => setOpen(false)} comment={comment}>
          <ListComments comments={tempComments} setComment={setComment} selectedCommentId={comment?.id} />
        </CommentDialog>
      )}
    </>
  );
};

const ListComments = ({
  comments,
  setComment,
  selectedCommentId,
}: {
  comments?: EducationCardComment[];
  setComment: (comment: EducationCardComment) => void;
  selectedCommentId?: EducationCardComment["id"];
}) => {
  if (!comments || comments.length === 0) return null;

  return (
    <Box mt={1}>
      <ListHeader>Kommentarer</ListHeader>
      <List>
        {comments?.map((comment) => (
          <ListItemButton
            key={comment.id}
            disableGutters
            primaryText={comment.text}
            endText={<Typography variant="caption">{comment.date}</Typography>}
            endIcon={comment.shared ? <Icon name={iconCommentShare} size="small" color={"info"} /> : undefined}
            height="auto"
            onClick={() => setComment(comment)}
            selected={comment.id === selectedCommentId}
          ></ListItemButton>
        ))}
      </List>
    </Box>
  );
};
